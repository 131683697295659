import { combineEpics } from 'redux-observable'

import * as AssetsEpics from 'reduxStore/epics/assets'
import * as AuthEpics from 'reduxStore/epics/auth'
import * as CashFlowEpics from 'reduxStore/epics/cashFlow'
import * as CompanyEpics from 'reduxStore/epics/company'
import * as CustomersEpics from 'reduxStore/epics/customers'
import * as EmployeesEpics from 'reduxStore/epics/employees'
import * as LogActivityEpics from 'reduxStore/epics/logActivity'
import * as MaterialsEpics from 'reduxStore/epics/materials'
import * as MasterDataEpics from 'reduxStore/epics/masterData'
import * as MitraEpics from 'reduxStore/epics/mitra'
import * as OrdersEpics from 'reduxStore/epics/orders'
import * as ProductsEpics from 'reduxStore/epics/products'
import * as ProductionsEpics from 'reduxStore/epics/productions'

// Temporary until core app is ready
import * as MasterDataCategoryEpics from 'reduxStore/epics/masterData/category'
import * as MasterDataSubCategoryEpics from 'reduxStore/epics/masterData/subCategory'
import * as MasterDataUnitEpics from 'reduxStore/epics/masterData/unit'

import api from 'utils/api'

function rootEpics(action$: any, store: any) {
  const dependencies = {
    api,
  }
  const allEpics = [
    ...Object.values(AssetsEpics),
    ...Object.values(AuthEpics),
    ...Object.values(CashFlowEpics),
    ...Object.values(CompanyEpics),
    ...Object.values(CustomersEpics),
    ...Object.values(EmployeesEpics),
    ...Object.values(LogActivityEpics),
    ...Object.values(MaterialsEpics),
    ...Object.values(MasterDataEpics),
    ...Object.values(MitraEpics),
    ...Object.values(OrdersEpics),
    ...Object.values(ProductsEpics),
    ...Object.values(ProductionsEpics),
    // Temporary until core app is ready
    ...Object.values(MasterDataCategoryEpics),
    ...Object.values(MasterDataSubCategoryEpics),
    ...Object.values(MasterDataUnitEpics),
  ]

  return combineEpics(...allEpics)(action$, store, dependencies)
}

export default rootEpics