import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'

import {
  COMPANY_GET,
} from 'config/endpoints'

import {
  COMPANY_SUMMARY,
  companyState,
} from 'reduxStore/ducks/company/index'

import {
  notificationSet,
} from 'reduxStore/ducks/notifications'

export function companyListEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(COMPANY_SUMMARY),
    mergeMap(() =>
      api({
        endpoint: COMPANY_GET,
      }).pipe(
        mergeMap((response: any) => of(
          companyState({
            isLoading: false,
            data: response.data.data,
          })
        )),
        catchError((err) => of(
          companyState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}
