import createReducer from 'utils/createReducer'
import { interfacePagination, interfaceSummary } from 'interface'
import { product } from '../products/types'

export const PRODUCTIONS_CREATE = 'PRODUCTIONS_CREATE'
export const PRODUCTIONS_LIST = 'PRODUCTIONS_LIST'
export const PRODUCTIONS_PRODUCT_LIST = 'PRODUCTIONS_PRODUCT_LIST'

export const PRODUCTIONS_STATE = 'PRODUCTIONS_STATE'

export interface interfaceAssets {
  details: product,
  data: product[],
  listProducts: any,
  pagination: interfacePagination,
  summary : interfaceSummary,
  isLoading: boolean,
  isError: boolean,
  isModalConfirm: boolean,
  errorMessage: string,
}

export const INITIAL_STATE: interfaceAssets = {
  details: {},
  data: [],
  listProducts: [],
  summary : {},
  pagination: {
    total: 0,
    page: 1,
    limit: 20,
  },
  isLoading: false,
  isError: false,
  isModalConfirm: false,
  errorMessage: '',
}

const reducer = createReducer(INITIAL_STATE, {

  [PRODUCTIONS_CREATE]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [PRODUCTIONS_LIST]: (state: any, payload: any) => ({
    ...state,
    isLoading: true,
    pagination: {
      ...state.pagination,
      page: payload?.page ? payload.page : state.pagination.page,
      limit: payload?.limit ? payload.limit : state.pagination.limit,
    }
  }),

  [PRODUCTIONS_STATE]: (state: any, payload: any) => ({
    ...state,
    ...payload,
  })
})

export const productionsCreate = (payload: any) => ({
  type: PRODUCTIONS_CREATE,
  payload,
})
export const productionsList = (payload: any | undefined = null) => ({
  type: PRODUCTIONS_LIST,
  payload,
})

export const productionsProductList = (payload: any | undefined = null) => ({
  type: PRODUCTIONS_PRODUCT_LIST,
  payload,
})
export const productionsState = (payload: any) => ({
  type: PRODUCTIONS_STATE,
  payload,
})

export default reducer