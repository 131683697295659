// CORE
import React from 'react'

// COMPONENTS
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
} from 'antd'

interface interfaceFormView {
  handleSubmit: (payload: any) => void,
  unit: string | undefined,
  isDisabledForm: boolean,
  type: string | undefined,
  isLoading?: boolean,
  handleUnit: (payload: string) => void,
}

function FormView({
  handleSubmit,
  unit,
  isDisabledForm,
  type,
  isLoading,
  handleUnit,
}: interfaceFormView) {

  return (
    <>
      <div>
        <Card
          className="header-solid"
          bordered={false}
          title={[
            <React.Fragment key={0}>
              <h3 className="mb-0">
                {type === 'new' ? 'Unit Baru' : unit}
              </h3>
            </React.Fragment>,
          ]}
        >
          <div className="mx-auto mt-24 mb-24" style={{ maxWidth: "800px" }}>
            <Form
              name="basic"
              layout="vertical"
              onFinish={handleSubmit}
              onFinishFailed={() => {}}
              fields={[
                {
                  name: ['unit'],
                  value: unit
                },
              ]}
            >
              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <Form.Item
                    label="Unit"
                    name="unit"
                    rules={[
                      { required: true, message: "Nama unit harus diisi!" },
                    ]}
                    colon={false}
                  >
                    <Input
                      placeholder="Nama unit"
                      disabled={isDisabledForm}
                      onChange={(e) => handleUnit(e.target.value)}
                    />
                  </Form.Item>
                </Col>

                {!isDisabledForm && (
                  <Col span={24} className="text-right">
                    <Form.Item>
                      <Button
                        type="primary"
                        className="px-25"
                        htmlType="submit"
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        SIMPAN
                      </Button>
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Form>
          </div>
        </Card>
      </div>
    </>
  )
}
export default FormView