import { useDispatch, useSelector } from 'react-redux'
import { assetsState, assetsRemove } from 'reduxStore/ducks/assets'

import { deleteFile } from 'utils/uploadFile';

import ConfirmationView from './confirmationView'

function ConfirmationContainer() {
  const dispatch = useDispatch()
  const state = useSelector((state: any) => state.Assets)

  const {
    details,
    isLoading,
    isModalConfirm,
  } = state

  const handleCancel = () => {
    dispatch(assetsState({
      isModalConfirm: false,
    }))
  }

  const handleConfirm = async () => {
    dispatch(assetsRemove(details.id))
    await deleteFile(details?.image)
  }

  const props = {
    details,
    isLoading,
    isModalConfirm,
    handleCancel,
    handleConfirm,
  }
  return <ConfirmationView {...props} />
}

export default ConfirmationContainer