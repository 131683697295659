import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  materialsCreate,
  materialsDetails,
  materialsEdit,
} from 'reduxStore/ducks/materials'
import AddView from './FormView'

function FormContainer() {
  const dispatch = useDispatch()
  const state = useSelector((state: any) => state.Materials)
  
  const { details } = state
  const { type, id } = useParams()

  const [price, setPrice] = useState(0)
  const [total_price, setTotalPrice] = useState(0)
  const [qty, setQty] = useState(0)
  const [name, setName] = useState<string>()
  const [unit, setUnit] = useState<string>()

  useEffect(() => {
    if (type !== 'new' && id) {
      dispatch(materialsDetails(id))
    }
  }, [dispatch, type, id])

  useEffect(() => {
    if (type !== 'new' && id) {
      setPrice(details.price)
      setTotalPrice(details.total_price)
      setQty(details.qty)
      setName(details.name)
      setUnit(details.unit)
    }
  }, [details, type, id])

  const handleSubmit = (values: any) => {
    const payload = {
      ...values,
      price: +values.price,
      total_price: +values.total_price,
      qty: +values.qty,
    }
    if (type === 'new') {
      dispatch(materialsCreate(payload))
    } else if (type === 'edit') {
      dispatch(materialsEdit(payload))
    }
  }

  const handlePrice = (value: number) => {
    setPrice(value)
    setTotalPrice(qty > 0 ? (value * qty) : 0)
  }

  const handleTotalPrice = (value: number) => {
    setTotalPrice(value)
    setPrice(qty > 0 ? (value / qty) : 0)
  }

  const handleQty = (value: number) => {
    setQty(value)

    if (price > 0) {
      setTotalPrice(value * price)
    } else {
      setPrice(total_price / value)
    }
  }

  const isDisabledForm = type === 'view'

  const props = {
    price,
    total_price,
    qty,
    name,
    isDisabledForm,
    type,
    unit,
    handleSubmit,
    handlePrice,
    handleTotalPrice,
    handleQty,
    handleName: setName,
    handleUnit: setUnit,
  }
  return <AddView {...props} />
}

export default FormContainer