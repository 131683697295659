import React from 'react'
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
} from 'antd'
import { useParams } from 'react-router-dom'
const { Option } = Select
const { TextArea } = Input

interface interfaceFormView {
  handleSubmit: (payload: any) => void,
  price: number,
  total_price: number,
  qty: number,
  name: string | undefined,
  isDisabledForm: boolean,
  type: string | undefined,
  source: string | undefined,
  description: string | undefined,
  handlePrice: (payload: number) => void,
  handleTotalPrice: (payload: number) => void,
  handleQty: (payload: number) => void,
  handleName: (payload: string) => void,
  handleSource: (payload: string) => void,
  handleDescription: (payload: string) => void,
}

function FormView({
  handleSubmit,
  price,
  total_price,
  qty,
  name,
  isDisabledForm,
  type,
  source,
  description,
  handlePrice,
  handleTotalPrice,
  handleQty,
  handleName,
  handleSource,
  handleDescription,
}: interfaceFormView) {
  let title
  let smallTitle
  let action

  const { cashflow } = useParams()
  if (cashflow === 'expend') {
    title = 'Pengeluaran'
    smallTitle = 'pengeluaran'
  }
  if (cashflow === 'income') {
    title = 'Pemasukan'
    smallTitle = 'pemasukan'
  }
  if (type === 'new') action = 'Tambah'
  if (type === 'edit') action = 'Update'
  return (
    <>
      <div>
        <Card
          className="header-solid"
          bordered={false}
          title={[
            <React.Fragment key={0}>
              <h3 className="mb-0">
                {type !== 'view' ? `${action} ${title}` : name}
              </h3>
            </React.Fragment>,
          ]}
        >
          <div className="mx-auto mt-24 mb-24" style={{ maxWidth: "800px" }}>
            <Form
              name="basic"
              layout="vertical"
              onFinish={handleSubmit}
              onFinishFailed={() => {}}
              fields={[
                {
                  name: ['name'],
                  value: name
                },
                {
                  name: ['source'],
                  value: source,
                },
                {
                  name: ['price'],
                  value: price > 0 ? price : undefined
                },
                {
                  name: ['total_price'],
                  value: total_price > 0 ? total_price : undefined
                },
                {
                  name: ['qty'],
                  value: qty > 0 ? qty : undefined
                },
                {
                  name: ['description'],
                  value: description,
                }
              ]}
            >
              <Row gutter={32}>
                <Col span={12}>
                  <Form.Item
                    label="Sumber"
                    name="source"
                    rules={[
                      { required: true, message: `Sumber ${smallTitle} harus diisi!` },
                    ]}
                    colon={false}
                  >
                    <Select
                      placeholder="Pilih Sumber"
                      onChange={(e: any) => handleSource(e)}
                    >
                      <Option value="Marketing">Marketing</Option>
                      <Option value="Mililiter">Alat Tulis Kantor (ATK)</Option>
                      <Option value="Sewa">Sewa</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Title"
                    name="name"
                    rules={[
                      { required: true, message: `Title ${smallTitle} harus diisi!` },
                    ]}
                    colon={false}
                  >
                    <Input
                      placeholder={`Title ${smallTitle}`}
                      onChange={(e) => handleName(e.target.value)}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Qty"
                    colon={false}
                    name="qty"
                    rules={[
                      { required: true, message: `Qty ${smallTitle} harus diisi!` },
                    ]}
                  >
                    <Input
                      placeholder={`Jumlah ${smallTitle}`}
                      type="number"
                      onChange={(e) => handleQty(+e.target.value)}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Harga"
                    colon={false}
                    name="price"
                    rules={[
                      { required: true, message: `Harga ${smallTitle} harus diisi!` },
                    ]}
                  >
                    <Input
                      placeholder={`Harga ${smallTitle}`}
                      type="number"
                      onChange={(e) => handlePrice(+e.target.value)}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Total Harga"
                    colon={false}
                    name="total_price"
                    rules={[
                      { required: true, message: `Total Harga ${smallTitle} harus diisi!` },
                    ]}
                  >
                    <Input
                      placeholder={`Total harga ${smallTitle}`}
                      type="number"
                      onChange={(e) => handleTotalPrice(+e.target.value)}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Deskripsi"
                    colon={false}
                    name="description"
                    rules={[
                      { required: true, message: `Total Harga ${smallTitle} harus diisi!` },
                    ]}
                  >
                    <TextArea
                      rows={4}
                      onChange={(e) => handleDescription(e.target.value)}
                      placeholder={`Deskripsi ${smallTitle}`}
                      maxLength={255}
                    />
                  </Form.Item>
                </Col>

                {!isDisabledForm && (
                  <Col span={24} className="text-right">
                    <Form.Item>
                      <Button
                        type="primary"
                        className="px-25"
                        htmlType="submit"
                      >
                        SIMPAN
                      </Button>
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Form>
          </div>
        </Card>
      </div>
    </>
  )
}
export default FormView