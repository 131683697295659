import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getToken } from 'utils/cookies'

function Authentication({
  children
}: any) {
  const navigate = useNavigate()
  const token = getToken()

  useEffect(() => {
    if (!token) {
      navigate(`login`)
    }
  }, [token, navigate])

  return (
    <>{children}</>
  )
}

export default Authentication