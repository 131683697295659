///////========================================================  AUTH
export const AUTH_LOGIN_POST = ['post', 'login']
export const AUTH_LOGOUT_DELETE = ['delete', 'logout']
export const AUTH_DETAIL_GET = ['get', 'users/me']
export const AUTH_UPDATE_PUT = ['put', 'users']

///////========================================================  ASSETS
export const ASSETS_GET = ['get', 'assets']
export const ASSETS_POST = ['post', 'assets']
export const ASSETS_PUT = ['put', 'assets']
export const ASSETS_DELETE = ['delete', 'assets']

///////========================================================  MATERIALS
export const MATERIALS_GET = ['get', 'materials']
export const MATERIALS_POST = ['post', 'materials']
export const MATERIALS_PUT = ['put', 'materials']
export const MATERIALS_DELETE = ['delete', 'materials']

///////========================================================  LOG ACTIVITY
export const LOG_ACTIVITY_GET = ['get', 'log_activity']
export const LOG_ACTIVITY_POST = ['post', 'log_activity']
export const LOG_ACTIVITY_PUT = ['put', 'log_activity']
export const LOG_ACTIVITY_DELETE = ['delete', 'log_activity']

///////========================================================  CASHFLOW
export const CASH_FLOW_GET = ['get', 'cashflow']
export const CASH_FLOW_POST = ['post', 'cashflow']
export const CASH_FLOW_PUT = ['put', 'cashflow']
export const CASH_FLOW_DELETE = ['delete', 'cashflow']

///////========================================================  CUSTOMERS
export const CUSTOMERS_GET = ['get', 'customers']
export const CUSTOMERS_POST = ['post', 'customers']
export const CUSTOMERS_PUT = ['put', 'customers']
export const CUSTOMERS_DELETE = ['delete', 'customers']

///////========================================================  EMPLOYEES
export const EMPLOYEES_GET = ['get', 'employees']
export const EMPLOYEES_POST = ['post', 'employees']
export const EMPLOYEES_PUT = ['put', 'employees']
export const EMPLOYEES_DELETE = ['delete', 'employees']

///////========================================================  MASTER DATA
export const PROVINCES_GET = ['get', 'provinces']
export const CITIES_GET = ['get', 'cities']
export const SUB_DISTRICTS_GET = ['get', 'subdistricts']
export const CATEGORIES_GET = ['get', 'categories']
export const SUB_CATEGORIES_GET = ['get', 'sub_categories']
export const UNITS_GET = ['get', 'units']

///////========================================================  PRODUCTS
export const PRODUCTS_GET = ['get', 'products']
export const PRODUCTS_POST = ['post', 'products']
export const PRODUCTS_PUT = ['put', 'products']
export const PRODUCTS_DELETE = ['delete', 'products']

///////========================================================  PRODUCTION
export const PRODUCTS_PRODUCTION_GET = ['get', 'products-production']
export const PRODUCTIONS_GET = ['get', 'productions']
export const PRODUCTIONS_POST = ['post', 'productions']

///////========================================================  ORDERS
export const ORDERS_GET = ['get', 'orders']
export const ORDERS_POST = ['post', 'orders']
export const ORDERS_PUT = ['put', 'orders']
export const ORDERS_DELETE = ['delete', 'orders']
export const ORDERS_PROCESS_POST = ['post', 'order/process']

///////========================================================  MITRA
export const MITRA_GET = ['get', 'business/mitra']
export const COMPANY_GET = ['get', 'company/summary']


///////========================================================  CATEGORIES
export const CATEGORY_GET = ['get', 'categories']
export const CATEGORY_POST = ['post', 'categories']
export const CATEGORY_PUT = ['put', 'categories']
export const CATEGORY_DELETE = ['delete', 'categories']

///////========================================================  SUB CATEGORIES
export const SUB_CATEGORY_GET = ['get', 'sub_categories']
export const SUB_CATEGORY_POST = ['post', 'sub_categories']
export const SUB_CATEGORY_PUT = ['put', 'sub_categories']
export const SUB_CATEGORY_DELETE = ['delete', 'sub_categories']

///////========================================================  UNITS
export const UNIT_GET = ['get', 'units']
export const UNIT_POST = ['post', 'units']
export const UNIT_PUT = ['put', 'units']
export const UNIT_DELETE = ['delete', 'units']