import { combineReducers } from 'redux'
import Assets from 'reduxStore/ducks/assets'
import Auth from 'reduxStore/ducks/auth'
import CashFlow from 'reduxStore/ducks/cashFlow'
import Customers from 'reduxStore/ducks/customers'
import Company from 'reduxStore/ducks/company'
import Employees from 'reduxStore/ducks/employees'
import LogActivity from 'reduxStore/ducks/logActivity'
import Materials from 'reduxStore/ducks/materials'
import MasterData from 'reduxStore/ducks/masterData'
import Mitra from 'reduxStore/ducks/mitra'
import Notifications from 'reduxStore/ducks/notifications'
import Orders from 'reduxStore/ducks/orders'
import Products from 'reduxStore/ducks/products'
import Productions from 'reduxStore/ducks/productions'
import Redirect from 'reduxStore/ducks/redirect'

  // Remove temporary if the core app is ready
import MasterDataCategory from 'reduxStore/ducks/masterData/category'
import MasterDataSubCategory from 'reduxStore/ducks/masterData/subCategory'
import MasterDataUnit from 'reduxStore/ducks/masterData/unit'

const appReducers = combineReducers({
  Assets,
  Auth,
  CashFlow,
  Customers,
  Company,
  Employees,
  LogActivity,
  Materials,
  MasterData,
  Mitra,
  Notifications,
  Orders,
  Products,
  Productions,
  Redirect,
  // Remove temporary if the core app is ready

  MasterDataCategory,
  MasterDataSubCategory,
  MasterDataUnit,
})

const rootReducers = (state: any, action: any) => {
  return appReducers(state, action)
}

export default rootReducers
