import createReducer from 'utils/createReducer'
import { interfacePagination, interfaceSummary } from 'interface'

import {
  product,
  image,
  stock,
  material,
} from './types'

export const ORDERS_CREATE = 'ORDERS_CREATE'
export const ORDERS_PROCESS = 'ORDERS_PROCESS'
export const ORDERS_EDIT = 'ORDERS_EDIT'
export const ORDERS_REMOVE = 'ORDERS_REMOVE'
export const ORDERS_DETAILS = 'ORDERS_DETAILS'
export const ORDERS_LIST = 'ORDERS_LIST'

export const ORDERS_STATE = 'ORDERS_STATE'

export interface interfaceDetail {
  product?: product,
  images?: image[],
  stock?: stock,
  materials?: material[],
}

export interface interfaceProducts {
  details: interfaceDetail,
  data: any[],
  pagination: interfacePagination,
  summary : interfaceSummary,
  isLoading: boolean,
  isError: boolean,
  isModalConfirm: boolean,
  errorMessage: string,
}

export const INITIAL_STATE: interfaceProducts = {
  details: {
    product: {
      name:'',
      sku:'',
      category_id: null,
      sub_category_id: null,
      price: null,
      is_discount: false,
      discount: 0,
      is_discount_persentage: false,
      is_supplier: false,
      hpp: 0,
      image: '',
      length: null,
      width: null,
      height: null,
      weight: null,
      description: null,
    },
    stock: {
      price: null,
      discount: 0,
      is_discount: false,
      is_discount_persentage: false,
      hpp: 0,
    },
    images: [],
    materials: [],
  },
  data: [],
  summary : {},
  pagination: {
    total: 0,
    page: 1,
    limit: 20,
  },
  isLoading: false,
  isError: false,
  isModalConfirm: false,
  errorMessage: '',
}

const reducer = createReducer(INITIAL_STATE, {

  [ORDERS_CREATE]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [ORDERS_EDIT]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [ORDERS_REMOVE]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [ORDERS_DETAILS]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [ORDERS_LIST]: (state: any, payload: any) => ({
    ...state,
    isLoading: true,
    pagination: {
      ...state.pagination,
      page: payload?.page ? payload.page : state.pagination.page,
      limit: payload?.limit ? payload.limit : state.pagination.limit,
    }
  }),

  [ORDERS_STATE]: (state: any, payload: any) => ({
    ...state,
    ...payload,
  })
})

export const ordersCreate = (payload: any) => ({
  type: ORDERS_CREATE,
  payload,
})
export const ordersProcess = (payload: any) => ({
  type: ORDERS_PROCESS,
  payload,
})
export const ordersEdit = (payload: any) => ({
  type: ORDERS_EDIT,
  payload,
})
export const ordersRemove = (payload: any) => ({
  type: ORDERS_REMOVE,
  payload,
})
export const ordersDetails = (payload: any) => ({
  type: ORDERS_DETAILS,
  payload,
})
export const ordersList = (payload: any | undefined = null) => ({
  type: ORDERS_LIST,
  payload,
})
export const ordersState = (payload: any) => ({
  type: ORDERS_STATE,
  payload,
})

export default reducer