import React from "react";
import { useLocation } from "react-router";

import NavLogo from "./navLogo";
import NavContent from "./navContent";

import menuItems from "utils/menu-items";
import { getMe } from "../../../utils/cookies";

const account = getMe()
function Navigation() {
  let location = useLocation()

  return (
    <React.Fragment>
      <NavLogo />
      <hr />
      <NavContent
        navigation={menuItems.items}
        location={location}
      />
    </React.Fragment>
  )
}

export default Navigation;
