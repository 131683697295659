import createReducer from 'utils/createReducer'

// LOGIN SUBMIT
export const NOTIFICATION_SET = 'NOTIFICATION_SET'

export interface Notificaion {
  title: string,
  message: string,
  type: 'success' | 'error' | 'info' | 'warning',
}

export const INITIAL_STATE: Notificaion = {
  title: '',
  message: '',
  type: 'success',
}

const reducer = createReducer(INITIAL_STATE, {
  [NOTIFICATION_SET]: (state: any, payload: Notificaion) => ({
    ...state,
    ...payload,
  }),
})

export const notificationSet = (payload: Notificaion) => ({
  type: NOTIFICATION_SET,
  payload,
})

export default reducer