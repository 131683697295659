import createReducer from 'utils/createReducer'
import { interfacePagination } from 'interface'

export const CATEGORY_CREATE = 'CATEGORY_CREATE'
export const CATEGORY_EDIT = 'CATEGORY_EDIT'
export const CATEGORY_REMOVE = 'CATEGORY_REMOVE'
export const CATEGORY_DETAILS = 'CATEGORY_DETAILS'
export const CATEGORY_LIST = 'CATEGORY_LIST'

export const CATEGORY_STATE = 'CATEGORY_STATE'

export interface interfaceDetail {
  id?: string,
  image?: string,
  category?: string,
  initial?: string,
}

export interface interfaceAssets {
  details: interfaceDetail,
  data: interfaceDetail[],
  pagination: interfacePagination,
  isLoading: boolean,
  isError: boolean,
  isModalConfirm: boolean,
  errorMessage: string,
}

export const INITIAL_STATE: interfaceAssets = {
  details: {},
  data: [],
  pagination: {
    total: 0,
    page: 1,
    limit: 20,
  },
  isLoading: false,
  isError: false,
  isModalConfirm: false,
  errorMessage: '',
}

const reducer = createReducer(INITIAL_STATE, {

  [CATEGORY_CREATE]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [CATEGORY_EDIT]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [CATEGORY_REMOVE]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [CATEGORY_DETAILS]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [CATEGORY_LIST]: (state: any, payload: any) => ({
    ...state,
    isLoading: true,
    pagination: {
      ...state.pagination,
      page: payload?.page ? payload.page : state.pagination.page,
      limit: payload?.limit ? payload.limit : state.pagination.limit,
    }
  }),

  [CATEGORY_STATE]: (state: any, payload: any) => ({
    ...state,
    ...payload,
  })
})

export const categoryCreate = (payload: any) => ({
  type: CATEGORY_CREATE,
  payload,
})
export const categoryEdit = (payload: any) => ({
  type: CATEGORY_EDIT,
  payload,
})
export const categoryRemove = (payload: any) => ({
  type: CATEGORY_REMOVE,
  payload,
})
export const categoryDetails = (payload: any) => ({
  type: CATEGORY_DETAILS,
  payload,
})
export const categoryList = (payload: any | undefined = null) => ({
  type: CATEGORY_LIST,
  payload,
})
export const categoryState = (payload: any) => ({
  type: CATEGORY_STATE,
  payload,
})

export default reducer