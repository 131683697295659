import { useState } from "react"
import getBase64 from 'utils/getBase64';

import UploadFileView from "./uploadFileView"

// TYPE / INTERFACE
import type { UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';

interface interfaceUploadFileContainer {
 fileList: UploadFile[],
 handleFileList: any,
 maxCount?: number,
 disabled?: boolean,
}

function UploadFileContainer({
  fileList,
  handleFileList,
  maxCount,
  ...otherProps
}: interfaceUploadFileContainer) {
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewTitle, setPreviewTitle] = useState<string>('');

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as any);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    handleFileList(newFileList);

  const newProps = {
    previewOpen,
    previewImage,
    previewTitle,
    fileList,
    maxCount,
    handlePreview,
    handleChange,
    handleCancel: () => setPreviewOpen(false),
    ...otherProps,
  }
  return <UploadFileView {...newProps} />
}

export default UploadFileContainer