import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import {
  uploadFile,
} from 'utils/uploadFile';

import {
  productionsProductList,
  productionsCreate,
} from 'reduxStore/ducks/productions'
import AddView from './FormView'

import type { UploadFile } from 'antd/es/upload/interface';

const defaultPayload = {
  product_id: '',
  success_qty: '',
  failed_qty: '',
  image: '',
  description: '',
}

function FormContainer() {
  const dispatch = useDispatch()
  const state = useSelector((state: any) => state.Productions)
  
  const { listProducts, isLoading } = state
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const [payload, setPayload] = useState(defaultPayload)

  useEffect(() => {
    dispatch(productionsProductList())
  }, [dispatch])

  const handlePayload = (data: any) => {
    setPayload({
      ...payload,
      ...data,
    })
  }

  const handleSubmit = async(values: any) => {
    let image: any = null
    if (fileList.length > 0) {
      if (fileList[0].url) {
        image = fileList[0].url
      } else {
        image = await uploadFile(fileList[0].originFileObj, values.name, 'productions')
        if (image) {
          image = image?.location
        }
      }
    }
    const payload = {
      ...values,
      image,
    }
    dispatch(productionsCreate(payload))
  }

  const handleSearchProduct = (keyword: string) => {
    dispatch(productionsProductList({ keyword }))
  }

  const props = {
    payload,
    fileList,
    isLoading,
    listProducts,
    handlePayload,
    handleSubmit,
    handleFileList: setFileList,
    handleSearchProduct,
  }
  return <AddView {...props} />
}

export default FormContainer