import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  ordersCreate,
  ordersDetails,
  ordersEdit,
  ordersState,
} from 'reduxStore/ducks/orders'

import { notificationSet } from 'reduxStore/ducks/notifications'

import {
  categoriesList,
} from 'reduxStore/ducks/masterData'
import { customersList, customersDetails } from 'reduxStore/ducks/customers'
import { productsList } from 'reduxStore/ducks/products'

import AddView from './FormView'

import type { UploadFile } from 'antd/es/upload/interface';

const defaultPayload = {
  customer_id: '',
  description: '',
  packing_price: 0,
  shipping_price: 0,
  grand_total: 0,
  order_type: 'online',
  payment_type: 'cash',
}

function FormContainer() {
  const dispatch = useDispatch()
  const state = useSelector((state: any) => state.Orders)
  const products = useSelector((state: any) => state.Products.data)
  const customers = useSelector((state: any) => state.Customers.data)
  const customerDetails = useSelector((state: any) => state.Customers.details)
  
  const { details, isLoading } = state
  const { type, id } = useParams()

  const [customerLists, setCustomerLists] = useState<any[]>([])

  const [payload, setPayload] = useState(defaultPayload)
  const [productOrders, setProductOrder] = useState<any[]>([])
  const [customer, setCustomer] = useState({})
  const [productLists, setProductLists] = useState([])

  const [fileList, setFileList] = useState<UploadFile[]>([])

  useEffect(() => {
    dispatch(categoriesList())
    dispatch(customersList())
    dispatch(productsList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCustomerLists(customers)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers])

  useEffect(() => {
    if (customerDetails && type !== 'new') {
      setCustomerLists([customerDetails])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDetails])

  useEffect(() => {
    let newProductsLists: any = []
    products.forEach((data: any) => {
      let isExist = false
      productOrders.forEach((product: any) => {
        if (data.product_id === product.product_id) {
          isExist = true
        }
      })
      if (!isExist) {
        newProductsLists.push(data)
      }
    })
    setProductLists(newProductsLists)
  }, [products, productOrders])

  useEffect(() => {
    const totalProductPrice = handleTotalProductPrice()
    const grand_total = payload.packing_price + payload.shipping_price + totalProductPrice
    setPayload({
      ...payload,
      grand_total,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productOrders])

  const handleTotalProductPrice = () => {
    let total_price = 0
    productOrders.forEach(data => {
      total_price += data.final_price * data.qty
    })
    return total_price
  }

  useEffect(() => {
    if (type !== 'new' && id) {
      dispatch(ordersDetails(id))
    }
  }, [dispatch, type, id])

  useEffect(() => {
    if (details.customer_id) {
      dispatch(customersDetails(details.customer_id))
    }
    if (type !== 'new' && id) {
      setPayload({
        ...payload,
        customer_id: details.customer_id,
        packing_price: details.packing_price,
        shipping_price: details.shipping_price,
        grand_total: details.grand_total,
        order_type: details.order_type,
        payment_type: details.payment_type,
        description: details.description,
      })

      if (details.order_product) {
        const orderProducts = details.order_product.map((data: any) => {
          return {
            name: data.name,
            unit: data.unit,
            qty: data.qty,
            product_id: data.product_id,
            is_discount: data.is_discount,
            is_discount_persentage: data.is_discount_persentage,
            discount: data.discount,
            total_price: data.total_price,
            final_price: data.final_price,
            price: data.price,
          }
        })
        setProductOrder(orderProducts)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details, type, id])

  useEffect(() => {
    const getCustomer = customers.find((data: any) => data.id === payload.customer_id)
    setCustomer(getCustomer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload.customer_id])

  const handleSubmit = async(values: any) => {
    if (productOrders.length > 0) {
      let isQtyFilled = true // Check if all of product qty is filled or not zero
      productOrders.forEach(product => {
        if (product.qty === 0) {
          isQtyFilled = false;
        }
      })
      if (isQtyFilled) {
        dispatch(ordersState({
          isLoading: true,
        }))
        const orderPayload = {
          ...payload,
          productOrders: productOrders,
        }
        
        if (type === 'new') {
          dispatch(ordersCreate(orderPayload))
        } else if (type === 'edit') {
          dispatch(ordersEdit(orderPayload))
        }
      } else {
        dispatch(
          notificationSet({
            title: 'Gagal!',
            message: 'Produk yang sudah diinput tidak boleh kosong!',
            type: 'error',
          }),
        )
      }
    } else {
      dispatch(
        notificationSet({
          title: 'Gagal!',
          message: 'Pilih produk yang dipesan terlebih dahulu!',
          type: 'error',
        }),
      )
    }
  }
  
  const isDisabledForm = type === 'view'

  const handlePayload = (data: any) => {
    const totalProductPrice = handleTotalProductPrice()
    const grand_total = (data.packing_price || payload.packing_price) + (data.shipping_price || payload.shipping_price) + totalProductPrice

    setPayload({
      ...payload,
      ...data,
      grand_total,
    })
  }

  const handleProducts = (payload: any, type: string) => {
    let newProductOrder = [...productOrders]
    let price = payload.price
    let discount = 0
    if (payload.is_discount && payload.is_discount_persentage) {
      discount = (payload.discount/100) * price
    } else if (payload.is_discount) {
      discount = payload.discount
    }
    newProductOrder.push({
      name: payload.name,
      unit: payload.unit,
      qty: 0,
      product_id: payload.id,
      price: payload.price,
      is_discount: payload.is_discount,
      is_discount_persentage: payload.is_discount_persentage,
      discount: payload.discount,
      total_price: 0,
      final_price: price - discount,
    })

    setProductOrder(newProductOrder)
  }

  const handleProductQty = (id: string, value: number) => {
    let newProductOrder = [...productOrders]
    newProductOrder = newProductOrder.map(data => {
      if (data.product_id === id || data.materials_id === id) {
        let price = data.price
        let discount = 0
        if (data.is_discount && data.is_discount_persentage) {
          discount = (data.discount/100) * price
        } else if (data.is_discount) {
          discount = data.discount
        }
        return {
          ...data,
          qty: value,
          is_discount: data.is_discount,
          is_discount_persentage: data.is_discount_persentage,
          discount: data.discount,
          total_price: value > 0 ? (data.price - discount) * value : 0,
          final_price: price - discount,
        }
      }
      return data
    })
    setProductOrder(newProductOrder)
  }

  const handleProductDelete = (id: string) => {
    let newProductOrder: any = []
    productOrders.forEach(data => {
      if (data.product_id !== id && data.materials_id !== id) {
        newProductOrder.push(data)
      }
    })
    setProductOrder(newProductOrder)
  }

  const props = {
    details,
    payload,
    isDisabledForm,
    type,
    fileList,
    isLoading,
    products: productLists,
    customer,
    customerLists,
    productOrders,
    handleSubmit,
    handlePayload,
    handleProducts,
    handleProductQty,
    handleProductDelete,
    handleFileList: setFileList,
  }
  return <AddView {...props} />
}

export default FormContainer