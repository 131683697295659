import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  interfaceDetail,
  assetsList,
  assetsState,
} from 'reduxStore/ducks/assets'
import ListView from './ListView'

function ListContainer() {
  const dispatch = useDispatch()

  const state = useSelector((state: any) => state.Assets)

  const {
    data,
    pagination,
    summary
  } = state

  useEffect(() => {
    dispatch(assetsList())
  }, [dispatch])

  const handleDeleteConfirm = (payload: interfaceDetail) => {
    dispatch(assetsState({
      isModalConfirm: true,
    }))
    handleSelected(payload)
  }

  const handleSelected = (payload: interfaceDetail) => {
    dispatch(assetsState({
      details: payload,
    }))
  }

  const handlePagination = (payload: any) => {
    dispatch(assetsList({
      page: payload.current, 
      limit: payload.pageSize, 
    }))
  }

  const props = {
    data,
    pagination,
    summary,
    handleDeleteConfirm,
    handlePagination,
  }

  return <ListView {...props} />
}

export default ListContainer