// CORE
import moment from 'moment'

// COMPONENTS
import {
  Button,
  Col,
  Divider,
  Row,
  Table,
  Typography,
} from 'antd'

import './styles.css'

import CurrencyFormat from "components/reuseable/currencyFormat/currencyFormatView"

import type { UploadFile } from 'antd/es/upload/interface';

import Logo from "assets/images/reuse/karplanter-logo.jpeg";

const { Title } = Typography

interface interfaceFormView {
  details: any,
  payload: any,
  isDisabledForm: boolean,
  type: string | undefined,
  fileList: UploadFile[],
  isLoading?: boolean,
  customer: any,
  customerLists: any[],
  products: any[],
  productOrders: any[],
}

function FormView({
  details,
  payload,
  isDisabledForm,
  type,
  fileList,
  isLoading,
  customer,
  customerLists,
  products,
  productOrders,
}: interfaceFormView) {
  console.log('details', details)

  const columns = [
    {
      title: "Product",
      dataIndex: "name",
      ellipsis: true,
    },
    {
      title: "Unit",
      render: (data: any) => {
        if (data.product_id) return 'Pcs'
        return data.unit
      }
    },
    {
      title: "Price",
      render: (data: any) => {
        return <div style={{ padding: 16}}>
          <div>
            <CurrencyFormat value={data.final_price}/>{` `}
            {data.is_discount && (
              <del style={{ color: '#8c8c8c' }}><CurrencyFormat value={data.price}/></del>
            )}
          </div>
        </div>
      }
    },
    {
      title: "Qty",
      render: (data: any) => data.qty
    },
    {
      title: "Discount",
      render: (data: any) => {
        if (data.is_discount && data.is_discount_persentage)  {
          return `${data.discount} %`
        }
        if (data.is_discount)  {
          return <CurrencyFormat value={data.discount}/>
        }
        return data.discount
      }
    },
    {
      title: "Total",
      render: (data: any) => {
        return <CurrencyFormat value={data.total_price}/>
      }
    },
  ];

  return (
    <>
      <div style={{
        maxWidth: 850,
        margin: 'auto',
      }}>
        <div className="mx-auto mt-24 mb-24 ml-24 ml-10 mr-10">
          <Row gutter={[{ xs: 24, md: 24 }, 5]}>
            <Col xs={12} sm={12} md={12}>
              <img src={Logo} alt="Karplanter Logo" style={{ width: 130 }}/>
            </Col>
            <Col xs={12} sm={12} md={12}>
              <div style={{ textAlign: 'right' }}>
                <Button
                  type="primary"
                  className="px-25 btn-print"
                  onClick={() => window.print()}
                >
                  PRINT
                </Button>
                <h2 className="mb-0">INVOICE</h2>
                <span>{details.invoice_no}</span><br/>
                <span>{moment(details.createdAt).format('DD MMMM YYYY')}</span>
                <div style={{ marginTop: 40 }}>
                  <strong>Karplanter</strong>
                  <p>Jl. Soka No.19, Kadisoko, Purwomartani, Kec. Kalasan, Kabupaten Sleman, Daerah Istimewa Yogyakarta 55571</p>
                </div>
              </div>
            </Col>
          </Row>
          {customer && (
            <Row gutter={[{ xs: 24, md: 24 }, 5]}>
            <Col>
              <span>Status Order</span><br/>
              
              {details.status === 'draft' ? (
                <Title level={3}>DRAFT</Title>
              ) : (
                <>
                  {details.payment_status === 'unpaid' ? (
                    <Title level={3} type="danger">UNPAID</Title>
                  ) : (
                    <Title level={3} type="success">PAID</Title>
                  )}
                </>
              )}
            </Col>
            <Divider />
              {/* ========== Nama Pelanggan ========== */}
              <Col xs={12} sm={12} md={12}>
                <Row>
                  <Col span={12}>
                    <strong>Customer</strong>
                  </Col>
                  <Col span={12}>
                    {customer.name}
                  </Col>
                </Row>
              </Col>
              {/* ========== Phone NuMber ========== */}
              <Col xs={12} sm={12} md={12}>
                <Row>
                  <Col span={12}>
                    <strong>Phone Number</strong>
                  </Col>
                  <Col span={12}>
                    {customer.phone}
                  </Col>
                </Row>
              </Col>
              {/* ========== Email ========== */}
              <Col xs={12} sm={12} md={12}>
                <Row>
                  <Col span={12}>
                    <strong>Email</strong>
                  </Col>
                  <Col span={12}>
                    {customer.email}
                  </Col>
                </Row>
              </Col>
              {/* ========== Social Media ========== */}
              <Col xs={12} sm={12} md={12}>
                <Row>
                  <Col span={12}>
                    <strong>Social Media</strong>
                  </Col>
                  <Col span={12}>
                    {customer.instagram}
                  </Col>
                </Row>
              </Col>
              {/* ========== Province ========== */}
              <Col xs={12} sm={12} md={12}>
                <Row>
                  <Col span={12}>
                    <strong>Province</strong>
                  </Col>
                  <Col span={12}>
                    {customer.province?.province_name}
                  </Col>
                </Row>
              </Col>
              {/* ========== District ========== */}
              <Col xs={12} sm={12} md={12}>
                <Row>
                  <Col span={12}>
                    <strong>City</strong>
                  </Col>
                  <Col span={12}>
                    {customer.city?.city_name}
                  </Col>
                </Row>
              </Col>
              {/* ========== SubDistrict ========== */}
              <Col xs={12} sm={12} md={12}>
                <Row>
                  <Col span={12}>
                    <strong>District</strong>
                  </Col>
                  <Col span={12}>
                    {customer.subdistrict?.subdistrict_name}
                  </Col>
                </Row>
              </Col>
              {/* ========== Postal Code ========== */}
              <Col xs={12} sm={12} md={12}>
                <Row>
                  <Col span={12}>
                    <strong>Postal Code</strong>
                  </Col>
                  <Col span={12}>
                    {customer.city?.postal_code}
                  </Col>
                </Row>
              </Col>
              {/* ========== Alamat lengkap ========== */}
              <Col xs={12} sm={12} md={12}>
                <Row>
                  <Col span={12}>
                    <strong>Address</strong>
                  </Col>
                  <Col span={12}>
                    {customer.address}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}

          {/* ====================== PRODUK ====================== */}

          <Divider />
          <Row gutter={[{ xs: 24, md: 24 }, 0]}>
            <Col span={12}>
            </Col>
            <Col span={24}>
              <Table
                className="mt-20"
                columns={columns}
                dataSource={productOrders}
                tableLayout="auto"
                scroll={{ x: 500 }}
                pagination={false}
                summary={() => (
                  <Table.Summary>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} />
                      <Table.Summary.Cell index={1} />
                      <Table.Summary.Cell index={2} />
                      <Table.Summary.Cell index={3} />
                      <Table.Summary.Cell index={4}><strong>Total Products</strong></Table.Summary.Cell>
                      <Table.Summary.Cell index={5}>
                        <CurrencyFormat value={payload.grand_total - payload.packing_price - payload.shipping_price}/>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={6} />
                    </Table.Summary.Row>

                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} />
                      <Table.Summary.Cell index={1} />
                      <Table.Summary.Cell index={2} />
                      <Table.Summary.Cell index={3} />
                      <Table.Summary.Cell index={4}><strong>Packing</strong></Table.Summary.Cell>
                      <Table.Summary.Cell index={5}>
                        <CurrencyFormat value={payload.packing_price}/>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={6} />
                    </Table.Summary.Row>

                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} />
                      <Table.Summary.Cell index={1} />
                      <Table.Summary.Cell index={2} />
                      <Table.Summary.Cell index={3} />
                      <Table.Summary.Cell index={4}><strong>Ongkir</strong></Table.Summary.Cell>
                      <Table.Summary.Cell index={5}>
                        <CurrencyFormat value={payload.shipping_price}/>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={6} />
                    </Table.Summary.Row>

                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} />
                      <Table.Summary.Cell index={1} />
                      <Table.Summary.Cell index={2} />
                      <Table.Summary.Cell index={3} />
                      <Table.Summary.Cell index={4}><strong style={{ color: '#16753b' }}>Grand Total</strong></Table.Summary.Cell>
                      <Table.Summary.Cell index={5}><strong style={{ color: '#16753b' }}><CurrencyFormat value={payload.grand_total}/></strong></Table.Summary.Cell>
                      <Table.Summary.Cell index={6} />
                    </Table.Summary.Row>
                  </Table.Summary>
                )}
              />
            </Col>

          </Row>
        </div>
      </div>
    </>
  )
}
export default FormView