import moment from 'moment'
import ReactS3Client from 'react-aws-s3-typescript'

const s3Config: any = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  dirName: process.env.REACT_APP_DIRECTORY,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  s3Url: process.env.REACT_APP_S3_URL,
}

export const uploadFile = async (file: any, filename: string = '', path: string = '') => {
  console.log('s3Config', s3Config)
  const s3 = new ReactS3Client(s3Config);

  try {
    const res = await s3.uploadFile(file, `${path}/${moment().format('YYYY')}/${moment().format('MM')}/${moment().format('DD')}/${filename}-${moment().format('HH:mm:ss')}`);
    return res
  } catch (exception) {
    console.log(exception);
    return {
      location: null
    }
  }
}

export const deleteFile = async (filepath: string | undefined) => {
  const s3 = new ReactS3Client(s3Config);

  if (filepath) {
    try {
      const path = filepath.replace(`${s3Config.s3Url}/`, "")
      const res = await s3.deleteFile(path);
      return res
    } catch (exception) {
      console.log(exception);
      return {
        location: null
      }
    }
  }
}

export default uploadFile