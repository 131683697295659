import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'

import {
  ORDERS_GET,
  ORDERS_DELETE,
  ORDERS_POST,
  ORDERS_PROCESS_POST,
  ORDERS_PUT,
} from 'config/endpoints'

import {
  ORDERS_CREATE,
  ORDERS_PROCESS,
  ORDERS_EDIT,
  ORDERS_REMOVE,
  ORDERS_DETAILS,
  ORDERS_LIST,
  ordersState,
  ordersList,
  ordersDetails,
} from 'reduxStore/ducks/orders/index'

import {
  notificationSet,
} from 'reduxStore/ducks/notifications'

import {
  redirect,
} from 'reduxStore/ducks/redirect'

export function ordersListEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(ORDERS_LIST),
    mergeMap(() =>
      api({
        endpoint: ORDERS_GET,
        query: {
          ...state$.value.Orders.pagination
        }
      }).pipe(
        mergeMap((response: any) => of(
          ordersState({
            isLoading: false,
            data: response.data.data,
            pagination: response.data.pagination,
            summary : response.data.summary
          })
        )),
        catchError((err) => of(
          ordersState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function ordersDetailsEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(ORDERS_DETAILS),
    mergeMap(({ payload }) =>
      api({
        endpoint: ORDERS_GET,
        params: [payload]
      }).pipe(
        mergeMap((response: any) => of(
          ordersState({
            isLoading: false,
            details: response.data,
          })
        )),
        catchError((err) => of(
          ordersState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function ordersEditsEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(ORDERS_EDIT),
    mergeMap(({ payload }) =>
      api({
        endpoint: ORDERS_PUT,
        params: [state$.value.Orders.details.id],
        body: payload,
      }).pipe(
        mergeMap((response: any) => of(
          ordersState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Berhasil',
            message: `Order berhasil diperbaharui`,
            type: 'success',
          }),
          redirect('/orders')
        )),
        catchError((err) => of(
          ordersState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function ordersCreateEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(ORDERS_CREATE),
    mergeMap(({ payload }) =>
      api({
        endpoint: ORDERS_POST,
        body: payload,
      }).pipe(
        mergeMap(() => of(
          ordersState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Berhasil',
            message: `Order berhasil dibuat`,
            type: 'success',
          }),
          // redirect('/orders')
        )),
        catchError((err) => of(
          ordersState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function ordersProcessEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(ORDERS_PROCESS),
    mergeMap(({ payload }) =>
      api({
        endpoint: ORDERS_PROCESS_POST,
        params: [state$.value.Orders.details.id],
        body: payload,
      }).pipe(
        mergeMap(() => of(
          ordersState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Berhasil',
            message: `Status order berhasil di perbaharui`,
            type: 'success',
          }),
          ordersDetails(state$.value.Orders.details.id)
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function ordersDeleteEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(ORDERS_REMOVE),
    mergeMap(({ payload }) =>
      {
      return api({
        endpoint: ORDERS_DELETE,
        params: [payload],
      }).pipe(
        mergeMap(() => of(
          ordersList(),
          ordersState({
            isLoading: false,
            isModalConfirm: false,
            details: {},
          }),
          notificationSet({
            title: 'Berhasil',
            message: 'Produk berhasil dihapus',
            type: 'success',
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        ))
      )
      }
    )
  )
}