import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"

import { redirect } from 'reduxStore/ducks/redirect'


function RedirectReduxContainer({
  children
}: any) {
  const { branchId } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const redirectTo = useSelector((state: any) => state.Redirect.to)

  useEffect(() => {
    if (redirectTo !== null && redirectTo !== 'null'){
      dispatch(redirect('null'))
      navigate(`/${branchId}${redirectTo}`)
    }
  },[navigate, redirectTo, branchId, dispatch])

  return <>{children}</>;
}

export default RedirectReduxContainer