import React, { Component } from "react";
import { Menu } from "antd";

import NavGroup from "./NavGroup";
import { NavLink } from "react-router-dom";
import NavIcon from "./NavIcon";
import NavBadge from "./NavBadge";
import menuItems from "../../../../utils/menu-items";

import { getBranchId } from "utils/cookies"

class NavContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openKeys: [],
      rootKeys: [],
      windowWidth: window.innerWidth,
    };
  }

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);

    let rootKeys = menuItems.items.map((item) => {
      if (item.children) {
        return [
          ...item.children.map((child) => {
            return child.id;
          }),
        ];
      }
      return false;
    });
    rootKeys = [].concat.apply([], rootKeys);
    if (this.props.location) {
      const keys = this.props.location.pathname.split("/");
      if (keys[keys.length - 1] !== "dashboard") {
        keys.pop();
      }
      keys.splice(0, 1);
      this.setState({ openKeys: keys });
    }
    this.setState({ rootKeys });
  }
  onOpenChange = (keys) => {
    const { openKeys } = this.state;
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    if (this.state.rootKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys: keys });
    } else {
      this.setState({ openKeys: latestOpenKey ? [latestOpenKey] : [] });
    }
  };
  navItems = this.props.navigation.map((item, index) => {
    switch (item.type) {
      case "group":
        return <NavGroup group={item} key={index} />;
      default:
        return false;
    }
  });

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.openKeys.length !== this.state.openKeys.length ||
      this.state.windowWidth > 992
    ) {
      // if (this.state.openKeys.length === 0 && this.state.windowWidth > 992) {
      //   let rootKeys = menuItems.items.map((item) => {
      //     if (item.children) {
      //       return [
      //         ...item.children.map((child) => {
      //           return child.id;
      //         }),
      //       ];
      //     }
      //     return false;
      //   });
      //   rootKeys = [].concat.apply([], rootKeys);
      //   if (this.props.location) {
      //     const keys = this.props.location.pathname.split("/");

      //     if (keys[keys.length - 1] !== "dashboard") {
      //       keys.pop();
      //     }
      //     keys.splice(0, 1);
      //     this.setState({ openKeys: keys });
      //   }
      //   this.setState({ rootKeys });
      // }
    }

    if (
      prevState.windowWidth !== this.state.windowWidth &&
      this.state.windowWidth <= 992
    ) {
      if (this.state.openKeys.length) {
        this.setState({ openKeys: [] });
      }
    }
  }

  renderItem = (navigation) => {
    if (!navigation) return;
    return navigation.map((item, index) => {
      switch (item.type) {
        case "group":
          return (
            <>
              {item.isShow && (
                <React.Fragment key={index}>
                  <Menu.Item
                    className={`menu-item-header ${
                      item.className ? item.className : ""
                    }`}
                    key={item.id || index}
                  >
                    {item.title}
                  </Menu.Item>
                  {this.renderItem(item.children)}
                </React.Fragment>
              )}
            </>
          );
        case "collapse":
          return (
            <>
              {item.isShow && (
                <Menu.SubMenu
                  key={item.id || index}
                  icon={
                    item.icon
                      ? item.icon(
                          this.state.openKeys.includes(item.id),
                          window.sessionStorage.getItem("color_theam"),
                        )
                      : ""
                  }
                  title={item.title}
                >
                  <Menu.ItemGroup key={item.id + "1"}>
                    {this.renderItem(item.children)}
                  </Menu.ItemGroup>
                </Menu.SubMenu>
              )}
            </>
          );
        case "item":
          if (item.target) {
            return (
              <Menu.Item key={item.id} className="catlog">
                <a
                  href={`/${getBranchId()}${item.url}`}
                  className="nav-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <NavIcon items={item} />
                  <span className="label"> {item.title}</span>
                  <NavBadge items={item} />
                </a>
              </Menu.Item>
            );
          }
          return (
            <Menu.Item key={item.id}>
              <NavLink to={`/${getBranchId()}${item.url}`} className="nav-link" exact={true}>
                <NavIcon items={item} />
                <span className="label">{item.title}</span>
                <NavBadge items={item} />
              </NavLink>
            </Menu.Item>
          );
        default:
          return false;
      }
    });
  };
  render() {
    const mainContent = (
      <div className="navbar-content datta-scroll">
        <Menu
          theme="light"
          mode="inline"
          openKeys={this.state.openKeys}
          // defaultSelectedKeys={this.state.openKeys}
          onOpenChange={this.onOpenChange}
        >
          {/* {this.navItems} */}
          {this.renderItem(this.props.navigation)}
        </Menu>
      </div>
    );

    return <div>{mainContent}</div>;
  }
}
export default NavContent;
