
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'

import {
  Card,
  Col,
  Row,
  Typography,
  Button,
  Timeline,
} from "antd";
import {
  DollarOutlined,
  // ToTopOutlined,
  MenuUnfoldOutlined,
  // RightOutlined,
} from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";

import Echart from "components/chart/Echart";
import LineChart from "components/chart/LineChart";
import CurrencyFormat from "components/reuseable/currencyFormat/currencyFormatView"
import CardTitle from "components/reuseable/cardTitle"

import { companySummary } from "reduxStore/ducks/company"
import { authDetail } from "reduxStore/ducks/auth";
import { getMe } from "utils/cookies";

function LoginView() {
  const dispatch = useDispatch()
  const { Title, Text } = Typography;

  const company = useSelector((state: any) => state.Company)

  const [reverse, setReverse] = useState(false);

  useEffect(() => {
    dispatch(companySummary())
    dispatch(authDetail())
    getMe()
  }, [dispatch])

  const timelineList = [
    {
      title: "$2,400 - Redesign store",
      time: "09 JUN 7:20 PM",
      color: "green",
    },
    {
      title: "New order #3654323",
      time: "08 JUN 12:20 PM",
      color: "green",
    },
    {
      title: "Company server payments",
      time: "04 JUN 3:10 PM",
    },
    {
      title: "New card added for order #4826321",
      time: "02 JUN 2:45 PM",
    },
    {
      title: "Unlock folders for development",
      time: "18 MAY 1:30 PM",
    },
    {
      title: "New order #46282344",
      time: "14 MAY 3:30 PM",
      color: "gray",
    },
  ];

  const count = [
    {
      title: "Modal",
      price: <CurrencyFormat style={{fontSize : 30, color :"Black"}} value={company.data.capital}/>,
      icon: <DollarOutlined
      style={{
        fontSize: 25,
        color: "white",
      }}
    />,
    },
    {
      title: "Saldo",
      price: <CurrencyFormat style={{fontSize : 30,color :"Black"}} value={company.data.balance}/>,
      icon: <DollarOutlined
      style={{
        fontSize: 25,
        color: "white",
      }}
    />,
    },
    {
      title: "Total Pemasukan",
      price: <CurrencyFormat style={{fontSize : 30, color :"green"}} value={company.data.total_income}/>,
      icon: <DollarOutlined
      style={{
        fontSize: 25,
        color: "white",
      }}
    />,
    },
    {
      title: "Total Pengeluaran",
      price: <CurrencyFormat style={{fontSize : 30,color :"#b70606"}} value={company.data.total_expend}/>,
      icon: <DollarOutlined
      style={{
        fontSize: 25,
        color: "white",
      }}
    />,
    }
  ];

  return (
    <>
      <div className="layout-content">
        {getMe()?.access?.summary || getMe()?.isOwner && (
          <CardTitle count={count} />
        )}

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <Echart />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <LineChart />
            </Card>
          </Col>
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">
            <Card bordered={false} className="criclebox cardbody h-full">
              {/* <div className="project-ant">
                <div>
                  <Title level={5}>Projects</Title>
                  <Paragraph className="lastweek">
                    done this month<span className="blue">40%</span>
                  </Paragraph>
                </div>
                <div className="ant-filtertabs">
                  <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                    <Radio.Group onChange={onChange} defaultValue="a">
                      <Radio.Button value="a">ALL</Radio.Button>
                      <Radio.Button value="b">ONLINE</Radio.Button>
                      <Radio.Button value="c">STORES</Radio.Button>
                    </Radio.Group>
                  </div>
                </div>
              </div>
              <div className="ant-list-box table-responsive">
                <table className="width-100">
                  <thead>
                    <tr>
                      <th>COMPANIES</th>
                      <th>MEMBERS</th>
                      <th>BUDGET</th>
                      <th>COMPLETION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((d, index) => (
                      <tr key={index}>
                        <td>
                          <h6>
                            <img
                              src={d.img}
                              alt=""
                              className="avatar-sm mr-10"
                            />{" "}
                            {d.Title}
                          </h6>
                        </td>
                        <td>{d.member}</td>
                        <td>
                          <span className="text-xs font-weight-bold">
                            {d.bud}{" "}
                          </span>
                        </td>
                        <td>
                          <div className="percent-progress">{d.progress}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="uploadfile shadow-none">
                <Upload {...uploadProps}>
                  <Button
                    type="dashed"
                    className="ant-full-box"
                    icon={<ToTopOutlined />}
                  >
                    <span className="click">Click to Upload</span>
                  </Button>
                </Upload>
              </div> */}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div className="timeline-box">
                <Title level={5}>Orders History</Title>
                <Paragraph className="lastweek" style={{ marginBottom: 24 }}>
                  this month <span className="bnb2">20%</span>
                </Paragraph>

                <Timeline
                  pending="Recording..."
                  className="timelinelist"
                  reverse={reverse}
                >
                  {timelineList.map((t, index) => (
                    <Timeline.Item color={t.color} key={index}>
                      <Title level={5}>{t.title}</Title>
                      <Text>{t.time}</Text>
                    </Timeline.Item>
                  ))}
                </Timeline>
                <Button
                  type="primary"
                  className="width-100"
                  onClick={() => setReverse(!reverse)}
                >
                  {<MenuUnfoldOutlined />} REVERSE
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default LoginView