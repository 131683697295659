import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'

import {
  PROVINCES_GET,
  CITIES_GET,
  // SUB_DISTRICTS_GET,
  CATEGORIES_GET,
  // SUB_CATEGORIES_GET,
  // UNITS_GET,
} from 'config/endpoints'

import {
  PROVINCES_LIST,
  CITIES_LIST,
  SUB_DISTRICTS_LIST,
  CATEGORIES_LIST,
  SUB_CATEGORIES_LIST,
  // UNITS_LIST,
  masterDataState,
} from 'reduxStore/ducks/masterData'

import {
  notificationSet,
} from 'reduxStore/ducks/notifications'

export function provincesListEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(PROVINCES_LIST),
    mergeMap(() =>
      api({
        endpoint: PROVINCES_GET,
      }).pipe(
        mergeMap((response: any) => of(
          masterDataState({
            provinces: response.data,
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function citiesListEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(CITIES_LIST),
    mergeMap(({ payload }) =>
      api({
        endpoint: PROVINCES_GET,
        params: [payload],
      }).pipe(
        mergeMap((response: any) => of(
          masterDataState({
            cities: response.data.cities,
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function subDistrictsListEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(SUB_DISTRICTS_LIST),
    mergeMap(({ payload }) =>
      api({
        endpoint: CITIES_GET,
        params: [payload],
      }).pipe(
        mergeMap((response: any) => of(
          masterDataState({
            subDistricts: response.data.subdistricts,
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}
export function categoriesListEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(CATEGORIES_LIST),
    mergeMap(() =>
      api({
        endpoint: CATEGORIES_GET,
      }).pipe(
        mergeMap((response: any) => of(
          masterDataState({
            categories: response.data.data,
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function subCategoriesListEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(SUB_CATEGORIES_LIST),
    mergeMap(({ payload }) =>
      api({
        endpoint: CATEGORIES_GET,
        params: [payload],
      }).pipe(
        mergeMap((response: any) => of(
          masterDataState({
            subCategories: response.data.sub_categories,
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}