import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'

import {
  PRODUCTIONS_GET,
  PRODUCTIONS_POST,
  PRODUCTS_PRODUCTION_GET,
} from 'config/endpoints'

import {
  PRODUCTIONS_CREATE,
  PRODUCTIONS_LIST,
  PRODUCTIONS_PRODUCT_LIST,
  productionsState,
} from 'reduxStore/ducks/productions/index'

import {
  notificationSet,
} from 'reduxStore/ducks/notifications'

import {
  redirect,
} from 'reduxStore/ducks/redirect'

export function productionsProductListEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(PRODUCTIONS_PRODUCT_LIST),
    mergeMap(({ payload }) =>
      api({
        endpoint: PRODUCTS_PRODUCTION_GET,
        query: {
          ...state$.value.Productions.pagination,
          ...payload,
        }
      }).pipe(
        mergeMap((response: any) => of(
          productionsState({
            isLoading: false,
            listProducts: response.data.data,
            pagination: response.data.pagination,
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}
export function productionsListEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(PRODUCTIONS_LIST),
    mergeMap(() =>
      api({
        endpoint: PRODUCTIONS_GET,
        query: {
          ...state$.value.Productions.pagination
        }
      }).pipe(
        mergeMap((response: any) => of(
          productionsState({
            isLoading: false,
            data: response.data.data,
            pagination: response.data.pagination,
            summary : response.data.summary
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function productionsCreateEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(PRODUCTIONS_CREATE),
    mergeMap(({ payload }) =>
      api({
        endpoint: PRODUCTIONS_POST,
        params: [payload.product_id],
        body: {
          ...payload,
          product_id: undefined,
        },
      }).pipe(
        mergeMap(() => of(
          productionsState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Berhasil',
            message: 'Produksi berhasil ditambahkan',
            type: 'success',
          }),
          redirect('/productions')
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}
