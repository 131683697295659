// CORE
import React from 'react'
import moment from 'moment'

// COMPONENTS
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Table,
} from 'antd'

import { DeleteFilled } from '@ant-design/icons'

import { interfaceMasterData } from 'reduxStore/ducks/masterData'

import UploadFileCard from 'components/reuseable/uploadFile';
import type { UploadFile } from 'antd/es/upload/interface';

interface interfaceFormView {
  handleSubmit: (payload: any) => void,
  payloadProduct: any,
  isDisabledForm: boolean,
  type: string | undefined,
  fileList: UploadFile[],
  isLoading?: boolean,
  masterData: interfaceMasterData,
  materialLists: any[],
  products: any[],
  productMaterials: any[],
  initialSKU: string,
  handlePayloadProduct: (payload: any) => void,
  handleMaterials: (payload: any, type: string) => void,
  handleMaterialQty: (index: string, value: number) => void,
  handleMaterialDelete: (payload: string) => void,
  handleFileList: (payload: UploadFile[]) => void,
  handleSearchProduct: (payload: string) => void,
  handleSearchMaterials: (payload: string) => void,
}

function FormView({
  handleSubmit,
  payloadProduct,
  isDisabledForm,
  type,
  fileList,
  isLoading,
  masterData,
  materialLists,
  products,
  productMaterials,
  initialSKU,
  handlePayloadProduct,
  handleMaterials,
  handleMaterialQty,
  handleMaterialDelete,
  handleFileList,
  handleSearchProduct,
  handleSearchMaterials,
}: interfaceFormView) {

  const columns = [
    {
      title: "Komposisi",
      dataIndex: "name",
      ellipsis: true,
    },
    {
      title: "unit",
      render: (data: any) => {
        if (data.product_id) return 'Pcs'
        return data.unit
      }
    },
    {
      title: "qty",
      render: (data: any) => (
        <Input
          placeholder="Jumlah Bahan"
          type="number"
          onChange={(e) => handleMaterialQty(data.product_id || data.materials_id, +e.target.value)}
          disabled={isDisabledForm}
          value={data.qty}
        />
      )
    },
    {
      title: "Action",
      width: '50px',
      render: (data: any) => {
        return (
          <>
            <Button
              type="link"
              danger
              onClick={() => handleMaterialDelete(data.product_id || data.materials_id)}
              icon={<DeleteFilled />}
              size="large"
            >
              Delete
            </Button>
          </>
        )
      },
    },
  ];
  
  const defaultSKU = moment().format('YYYYMMDDhhssmm')

  return (
    <>
      <div>
        <Card
          className="header-solid"
          bordered={false}
          title={[
            <React.Fragment key={0}>
              <h3 className="mb-0">
                {type === 'new' ? 'Tambah Produk' : payloadProduct.name}
              </h3>
            </React.Fragment>,
          ]}
        >
          <div className="mx-auto mt-24 mb-24" style={{ maxWidth: "800px" }}>
            <Form
              name="basic"
              layout="vertical"
              onFinish={handleSubmit}
              onFinishFailed={() => {}}
              fields={[
                {
                  name: ['name'],
                  value: payloadProduct.name
                },
                {
                  name: ['category_id'],
                  value: payloadProduct.category_id
                },
                {
                  name: ['sub_category_id'],
                  value: payloadProduct.sub_category_id
                },
                {
                  name: ['price'],
                  value: payloadProduct.price
                },
                {
                  name: ['mitra_price'],
                  value: payloadProduct.mitra_price
                },
                {
                  name: ['sku'],
                  value: payloadProduct.sku || defaultSKU
                },
                {
                  name: ['length'],
                  value: payloadProduct.length
                },
                {
                  name: ['width'],
                  value: payloadProduct.width
                },
                {
                  name: ['weight'],
                  value: payloadProduct.weight
                },
                {
                  name: ['discount'],
                  value: payloadProduct.discount
                },
                {
                  name: ['height'],
                  value: payloadProduct.height
                },
                {
                  name: ['is_discount'],
                  value: payloadProduct.is_discount
                },
                {
                  name: ['is_discount_persentage'],
                  value: payloadProduct.is_discount_persentage
                },
                {
                  name: ['is_supplier'],
                  value: payloadProduct.is_supplier
                },
                {
                  name: ['hpp'],
                  value: payloadProduct.hpp
                },
                {
                  name: ['description'],
                  value: payloadProduct.description
                },
              ]}
            >
              <Row gutter={32}>
                <Col span={12}>
                  <Form.Item
                    label="Nama Produk"
                    name="name"
                    rules={[
                      { required: true, message: "Nama produk harus diisi!" },
                    ]}
                    colon={false}
                  >
                    <Input
                      placeholder="Nama produk"
                      disabled={isDisabledForm}
                      onChange={(e) => handlePayloadProduct({ name: e.target.value })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Harga Jual"
                    colon={false}
                    name="price"
                    rules={[
                      { required: true, message: "Harga jual harus diisi!" },
                    ]}
                  >
                    <Input
                      placeholder="Harga Jual"
                      type="number"
                      onChange={(e) => handlePayloadProduct({ price: +e.target.value })}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Harga Jual Mitra"
                    colon={false}
                    name="mitra_price"
                    rules={[
                      { required: true, message: "Harga jual mitra harus diisi!" },
                    ]}
                  >
                    <Input
                      placeholder="Harga Jual mitra"
                      type="number"
                      onChange={(e) => handlePayloadProduct({ mitra_price: +e.target.value })}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Kategori"
                    name="category_id"
                    rules={[
                      { required: true, message: "Pilih kategori!" },
                    ]}
                    colon={false}
                  >
                    <Select
                      showSearch
                      placeholder="Cari Kategori"
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={masterData.categories.map(data => {
                        return { label: data.category, value: data.id }
                      })}
                      onChange={(e) => handlePayloadProduct({ category_id: e, sub_category_id: null })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Sub Kategori"
                    name="sub_category_id"
                    rules={[
                      { required: true, message: "Pilih sub kategori!" },
                    ]}
                    colon={false}
                  >
                    <Select
                      showSearch
                      placeholder="Cari sub kategori"
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={masterData.subCategories.map(data => {
                        return { label: data.sub_category, value: data.id }
                      })}
                      onChange={(e) => handlePayloadProduct({ sub_category_id: e })}
                    />
                  </Form.Item>
                </Col>
                {/* ====================== SIZE PRODUCT ====================== */}
                <Col xs={12} sm={12} md={6}>
                  <Form.Item
                    label="Panjang (cm)"
                    colon={false}
                    name="length"
                    rules={[
                      { required: true, message: "Panjang produk harus diisi!" },
                    ]}
                  >
                    <Input
                      placeholder="Panjang Produk"
                      type="number"
                      onChange={(e) => handlePayloadProduct({ length: +e.target.value })}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Form.Item
                    label="Lebar (cm)"
                    colon={false}
                    name="width"
                    rules={[
                      { required: true, message: "Lebar produk harus diisi!" },
                    ]}
                  >
                    <Input
                      placeholder="Lebar Produk"
                      type="number"
                      onChange={(e) => handlePayloadProduct({ width: +e.target.value })}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Form.Item
                    label="Tinggi (cm)"
                    colon={false}
                    name="height"
                    rules={[
                      { required: true, message: "Tinggi produk harus diisi!" },
                    ]}
                  >
                    <Input
                      placeholder="Tinggi Produk"
                      type="number"
                      onChange={(e) => handlePayloadProduct({ height: +e.target.value })}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Form.Item
                    label="Berat Produk (gram)"
                    colon={false}
                    name="weight"
                    rules={[
                      { required: true, message: "Berat (gram) produk harus diisi!" },
                    ]}
                  >
                    <Input
                      placeholder="Berat Produk"
                      type="number"
                      onChange={(e) => handlePayloadProduct({ weight: +e.target.value })}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>

                <Divider />

                {/* ====================== SKU SECTION ====================== */}
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="SKU"
                    colon={false}
                    name="sku"
                    rules={[
                      { required: true, message: "SKU produk harus diisi!" },
                    ]}
                  >
                    <Input
                      addonBefore={initialSKU || 'KAR'}
                      placeholder="SKU"
                      onChange={(e) => handlePayloadProduct({ sku: e.target.value })}
                      disabled={isDisabledForm}
                      defaultValue={defaultSKU}
                    />
                  </Form.Item>
                </Col>

                <Divider />
                {/* ====================== DISCOUNT SECTION ====================== */}
                <Col span={24}>
                  <Form.Item
                    label="Gunakan Diskon"
                    colon={false}
                    name="is_discount"
                  >
                    <Switch onChange={(e) => handlePayloadProduct({ is_discount: e })}/>
                    {payloadProduct.is_discount ? (
                      <span> Produk ini menggunakan diskon!</span>
                    ) : (
                      <span> Produk ini tidak menggunakan diskon!</span>
                    )}
                  </Form.Item>
                </Col>

                {payloadProduct.is_discount && (
                  <>
                    <Col span={24}>
                      <Form.Item
                        label="Diskon Persentase"
                        colon={false}
                        name="is_discount_persentage"
                      >
                        <Switch onChange={(e) => handlePayloadProduct({ is_discount_persentage: e })}/>
                        {payloadProduct.is_discount_persentage ? (
                          <span> Produk ini menggunakan diskon berdasarkan persentase (%)</span>
                        ) : (
                          <span> Produk ini menggunakan diskon berdasarkan nominal!</span>
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        label={payloadProduct.is_discount_persentage ? 'Persentase Diskon' : 'Nominal Diskon'}
                        colon={false}
                        name="discount"
                        rules={[
                          { required: true, message:payloadProduct.is_discount_persentage ? 'Persentase Diskon Harus Diisi!' : 'Nominal Diskon Harus Diisi!' },
                        ]}
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          placeholder={payloadProduct.is_discount_persentage ? 'Persentase Diskon' : 'Nominal Diskon'}
                          type="number"
                          onChange={(e: any) => {
                            let discountProduct = 0
                            if (payloadProduct.is_discount_persentage) {
                              discountProduct = e > 99 ? 100 : e
                            } else {
                              discountProduct = e
                            }
                            return handlePayloadProduct({ discount: discountProduct })
                          }}
                          disabled={isDisabledForm}
                          value={payloadProduct.discount}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}

                <Divider />

                {/* ====================== SUPPLIER ====================== */}
                <Col span={24}>
                  <Form.Item
                    label="Produk Supplier"
                    colon={false}
                    name="is_supplier"
                  >
                    <Switch onChange={(e) => handlePayloadProduct({ is_supplier: e })}/>
                    {payloadProduct.is_supplier ? (
                      <span> Produk ini berasal dari supplier!</span>
                    ) : (
                      <span> Produk ini diproduksi sendiri!</span>
                    )}
                  </Form.Item>
                </Col>

                {payloadProduct.is_supplier ? (
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      label="Harga Beli Supplier"
                      colon={false}
                      name="hpp"
                      rules={[
                        { required: true, message: "Harga beli supplier harus diisi!" },
                      ]}
                    >
                      <Input
                        placeholder="Harga Beli Supplier"
                        type="number"
                        onChange={(e) => handlePayloadProduct({ hpp: e.target.value })}
                        disabled={isDisabledForm}
                      />
                    </Form.Item>
                  </Col>
                ) : (
                  <>
                    <Col span={12}>
                      <Form.Item>
                        <Select
                          showSearch
                          autoFocus
                          onSearch={(e) => handleSearchMaterials(e)}
                          placeholder="Cari bahan baku"
                          optionFilterProp="children"
                          filterOption={false}
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                          }
                          options={materialLists.map(data => {
                            return { label: data.name, value: JSON.stringify(data) }
                          })}
                          onChange={(e) => handleMaterials(JSON.parse(e), 'material')}
                          value="Cari bahan baku"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item>
                        <Select
                          showSearch
                          autoFocus
                          onSearch={(e) => handleSearchProduct(e)}
                          placeholder="Cari produk"
                          optionFilterProp="children"
                          filterOption={false}
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                          }
                          options={products.map(data => {
                            return { label: data.product.name, value: JSON.stringify(data.product) }
                          })}
                          onChange={(e) => handleMaterials(JSON.parse(e), 'product')}
                          value="Cari produk"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Table
                        className="mt-20"
                        columns={columns}
                        dataSource={productMaterials}
                        tableLayout="auto"
                        scroll={{ x: 500 }}
                        pagination={false}
                      />
                    </Col>
                  </>
                )}

                <Divider />

                {/* ====================== IMAGES ====================== */}

                <Col span={24}>
                  <Form.Item
                    label="Gambar produk"
                    colon={false}
                  >
                    <UploadFileCard
                      fileList={fileList}
                      handleFileList={handleFileList}
                      maxCount={3}
                    />
                  </Form.Item>
                </Col>

                {/* ====================== DESCRIPTION ====================== */}

                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    label="Deskripsi Produk"
                    colon={false}
                    name="description"
                  >
                    <Input.TextArea
                      placeholder="Masukkan deskripsi produk"
                      onChange={(e) => handlePayloadProduct({ description: e.target.value })}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>

                {!isDisabledForm && (
                  <Col span={24} className="text-right" style={{ marginTop: 30 }}>
                    <Form.Item>
                      <Button
                        type="primary"
                        className="px-25"
                        htmlType="submit"
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        SIMPAN
                      </Button>
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Form>
          </div>
        </Card>
      </div>
    </>
  )
}
export default FormView