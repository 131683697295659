//  Core
import { Link, useParams } from "react-router-dom"

// Type Interface
import { interfacePagination, interfaceSummary } from 'interface'
import { interfaceDetail } from "reduxStore/ducks/cashFlow"

// Componnts
import { Button, Row, Col, Table, Card, Select, Input, Typography } from "antd"
import Confirmation from "./components/confirmation"
import CurrencyFormat from "components/reuseable/currencyFormat/currencyFormatView"

// Icons
import { DollarOutlined, SearchOutlined } from "@ant-design/icons"
import CardTitle from "components/reuseable/cardTitle"

const { Option } = Select;
const { Text } = Typography;

const suffix = (
  <SearchOutlined
    style={{
      fontSize: 16,
      color: "#1890ff",
    }}
  />
);

interface interfaceListView {
  data: interfaceDetail[],
  pagination: interfacePagination,
  summary : interfaceSummary,
  handleDeleteConfirm: (payload: any) => void,
  handlePagination: (payload: any) => void,
}

function ListView({
  data,
  pagination,
  summary,
  handleDeleteConfirm,
  handlePagination,
}: interfaceListView) {

  const columns = [
    {
      title: "Type",
      // dataIndex: "type",
      // sorter: (a: any, b: any) => a.name.length - b.name.length,
      // sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      ellipsis: true,
      render: (data: any) => {
        let textType: any = "secondary"
        let type

        if (data.type === 'expend') {
          type = 'Pengeluaran'
          textType = "danger"
        }
        if (data.type === 'income') {
          type = 'Pemasukan'
          textType = "success"
        }
        return <Text type={textType}>{type}</Text>
      }
    },
    {
      title: "Sumber",
      ellipsis: true,
      render: (data: any) => {
        let source = data.source
        if (data.source === 'assets') source = 'Aset'
        if (data.source === 'materials') source = 'Bahan Baku'
        if (data.source === 'employees') source = 'Karyawan'
        return source
      }
    },
    {
      title: "Nama",
      dataIndex: "name",
      ellipsis: true,
    },
    {
      title: "Qty",
      dataIndex: "qty",
      ellipsis: true,
    },
    {
      title: "Harga",
      ellipsis: true,
      render: (data: any) => {
        return <CurrencyFormat value={data.price}/>
      }
    },
    {
      title: "Total Harga",
      ellipsis: true,
      render: (data: any) => {
        return <CurrencyFormat value={data.total_price}/>
      }
    },
    {
      title: "Action",
      // dataIndex: "total_price",
      width: '50px',
      render: (data: any) => {
        let menu
        if (data.source === 'assets') menu = 'asset'
        if (data.source === 'materials') menu = 'material'
        if (data.source === 'employees') menu = 'employee'
        return (
          <>
            {menu && data.id_source && (
              <Link to={`/${data.business_id}/${menu}/view/${data.id_source}`}>
                <Button type="link">
                  Details
                </Button>
              </Link>
            )}
          </>
        )
      },

    },
  ];

  let title = "Cash Flow"

  const { cashflow } = useParams()
  if (cashflow === 'expend') {
    title = 'Pengeluaran'
  }
  if (cashflow === 'income') {
    title = 'Pemasukan'
  }

  const count = [
    {
      title: "Total Pemasukan",
      price: <CurrencyFormat style={{fontSize : 30,color :"Black"}} value={summary.total_income}/>,
      icon: <DollarOutlined
      style={{
        fontSize: 25,
        color: "white",
      }}
    />,
    },
    {
      title: "Total Pengeluaran",
      price: <CurrencyFormat style={{fontSize : 30,color :"Black"}} value={summary.total_expend}/>,
      icon: <DollarOutlined
      style={{
        fontSize: 25,
        color: "white",
      }}
    />,
    }
  ];

  return (
    <>
    <CardTitle count={count} />
      <Confirmation />
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Card
            bodyStyle={{ padding: "16px 0px 0px" }}
            bordered={false}
            className="header-solid mb-24"
            title={[<h5 className="font-semibold">{title}</h5>]}
          >
            <div className="px-25">
              <Row gutter={[24, 0]} className="mb-24">
                <Col span={24} lg={12}>
                  <Select
                    value={pagination.limit}
                    // onChange={this.handleTotalPageChange1}
                    size="large"
                  >
                    <Option value="5">5</Option>
                    <Option value="10">10</Option>
                    <Option value="15">15</Option>
                    <Option value="20">20</Option>
                    <Option value="25">25</Option>
                  </Select>

                  <label className="ml-10">entries per page</label>
                </Col>
                <Col span={24} lg={12} className="text-right">
                  <Input
                    style={{ maxWidth: "200px", borderRadius: "8px" }}
                    placeholder="input search text"
                    size="small"
                    suffix={suffix}
                    // onChange={this.handleFilter} 
                  />
                </Col>
              </Row>

              <Table
                className="mt-20"
                columns={columns}
                dataSource={data}
                tableLayout="auto"
                onChange={(e) => handlePagination(e)}
                scroll={{ x: 500 }}
                pagination={{
                  total: pagination.total,
                  pageSize: pagination.limit,
                  current: pagination.page,
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ListView