// CORE
import React from 'react'

// COMPONENTS
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
} from 'antd'

import UploadFileCard from 'components/reuseable/uploadFile';

import type { UploadFile } from 'antd/es/upload/interface';

interface interfaceFormView {
  handleSubmit: (payload: any) => void,
  price: number,
  total_price: number,
  qty: number,
  name: string | undefined,
  isDisabledForm: boolean,
  type: string | undefined,
  fileList: UploadFile[],
  isLoading?: boolean,
  handlePrice: (payload: number) => void,
  handleTotalPrice: (payload: number) => void,
  handleQty: (payload: number) => void,
  handleName: (payload: string) => void,
  handleFileList: (payload: UploadFile[]) => void,
}

function FormView({
  handleSubmit,
  price,
  total_price,
  qty,
  name,
  isDisabledForm,
  type,
  fileList,
  isLoading,
  handlePrice,
  handleTotalPrice,
  handleQty,
  handleName,
  handleFileList,
}: interfaceFormView) {

  return (
    <>
      <div>
        <Card
          className="header-solid"
          bordered={false}
          title={[
            <React.Fragment key={0}>
              <h3 className="mb-0">
                {type === 'new' ? 'Tambah Asset' : name}
              </h3>
            </React.Fragment>,
          ]}
        >
          <div className="mx-auto mt-24 mb-24" style={{ maxWidth: "800px" }}>
            <Form
              name="basic"
              layout="vertical"
              onFinish={handleSubmit}
              onFinishFailed={() => {}}
              fields={[
                {
                  name: ['name'],
                  value: name
                },
                {
                  name: ['price'],
                  value: price > 0 ? price : undefined
                },
                {
                  name: ['total_price'],
                  value: total_price > 0 ? total_price : undefined
                },
                {
                  name: ['qty'],
                  value: qty > 0 ? qty : undefined
                },
              ]}
            >
              <Row gutter={32}>
                <Col span={12}>
                  <Form.Item
                    label="Nama"
                    name="name"
                    rules={[
                      { required: true, message: "Nama assets harus diisi!" },
                    ]}
                    colon={false}
                  >
                    <Input
                      placeholder="Nama Asset"
                      disabled={isDisabledForm}
                      onChange={(e) => handleName(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Qty"
                    colon={false}
                    name="qty"
                    rules={[
                      { required: true, message: "Qty assets harus diisi!" },
                    ]}
                  >
                    <Input
                      placeholder="Jumlah Assets"
                      type="number"
                      onChange={(e) => handleQty(+e.target.value)}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Harga"
                    colon={false}
                    name="price"
                    rules={[
                      { required: true, message: "Harga assets harus diisi!" },
                    ]}
                  >
                    <Input
                      placeholder="Harga Assets"
                      type="number"
                      onChange={(e) => handlePrice(+e.target.value)}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Total Harga"
                    colon={false}
                    name="total_price"
                    rules={[
                      { required: true, message: "Total Harga assets harus diisi!" },
                    ]}
                  >
                    <Input
                      placeholder="Total Harga Asset"
                      type="number"
                      onChange={(e) => handleTotalPrice(+e.target.value)}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <UploadFileCard fileList={fileList} handleFileList={handleFileList} />
                </Col>

                {!isDisabledForm && (
                  <Col span={24} className="text-right">
                    <Form.Item>
                      <Button
                        type="primary"
                        className="px-25"
                        htmlType="submit"
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        SIMPAN
                      </Button>
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Form>
          </div>
        </Card>
      </div>
    </>
  )
}
export default FormView