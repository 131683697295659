import createReducer from 'utils/createReducer'
import { interfacePagination, interfaceSummary } from 'interface'

export const SUB_CATEGORY_CREATE = 'SUB_CATEGORY_CREATE'
export const SUB_CATEGORY_EDIT = 'SUB_CATEGORY_EDIT'
export const SUB_CATEGORY_REMOVE = 'SUB_CATEGORY_REMOVE'
export const SUB_CATEGORY_DETAILS = 'SUB_CATEGORY_DETAILS'
export const SUB_CATEGORY_LIST = 'SUB_CATEGORY_LIST'

export const SUB_CATEGORY_STATE = 'SUB_CATEGORY_STATE'

export interface interfaceDetail {
  id?: string,
  image?: string,
  category_id?: string,
  sub_category?: string,
  initial?: string,
}

export interface interfaceAssets {
  details: interfaceDetail,
  data: interfaceDetail[],
  pagination: interfacePagination,
  summary : interfaceSummary,
  isLoading: boolean,
  isError: boolean,
  isModalConfirm: boolean,
  errorMessage: string,
}

export const INITIAL_STATE: interfaceAssets = {
  details: {},
  data: [],
  summary : {},
  pagination: {
    total: 0,
    page: 1,
    limit: 20,
  },
  isLoading: false,
  isError: false,
  isModalConfirm: false,
  errorMessage: '',
}

const reducer = createReducer(INITIAL_STATE, {

  [SUB_CATEGORY_CREATE]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [SUB_CATEGORY_EDIT]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [SUB_CATEGORY_REMOVE]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [SUB_CATEGORY_DETAILS]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [SUB_CATEGORY_LIST]: (state: any, payload: any) => ({
    ...state,
    isLoading: true,
    pagination: {
      ...state.pagination,
      page: payload?.page ? payload.page : state.pagination.page,
      limit: payload?.limit ? payload.limit : state.pagination.limit,
    }
  }),

  [SUB_CATEGORY_STATE]: (state: any, payload: any) => ({
    ...state,
    ...payload,
  })
})

export const subCategoryCreate = (payload: any) => ({
  type: SUB_CATEGORY_CREATE,
  payload,
})
export const subCategoryEdit = (payload: any) => ({
  type: SUB_CATEGORY_EDIT,
  payload,
})
export const subCategoryRemove = (payload: any) => ({
  type: SUB_CATEGORY_REMOVE,
  payload,
})
export const subCategoryDetails = (payload: any) => ({
  type: SUB_CATEGORY_DETAILS,
  payload,
})
export const subCategoryList = (payload: any | undefined = null) => ({
  type: SUB_CATEGORY_LIST,
  payload,
})
export const subCategoryState = (payload: any) => ({
  type: SUB_CATEGORY_STATE,
  payload,
})

export default reducer