import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'

import {
  CATEGORY_GET,
  CATEGORY_DELETE,
  CATEGORY_POST,
  CATEGORY_PUT,
} from 'config/endpoints'

import {
  CATEGORY_CREATE,
  CATEGORY_EDIT,
  CATEGORY_REMOVE,
  CATEGORY_DETAILS,
  CATEGORY_LIST,
  categoryState,
  categoryList,
} from 'reduxStore/ducks/masterData/category'

import {
  notificationSet,
} from 'reduxStore/ducks/notifications'

import {
  redirect,
} from 'reduxStore/ducks/redirect'

export function categoryListEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(CATEGORY_LIST),
    mergeMap(() =>
      api({
        endpoint: CATEGORY_GET,
        query: {
          ...state$.value.MasterDataCategory.pagination
        }
      }).pipe(
        mergeMap((response: any) => of(
          categoryState({
            isLoading: false,
            data: response.data.data,
            pagination : response.data.pagination,
            summary : response.data.summary,
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function categoryDetailsEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(CATEGORY_DETAILS),
    mergeMap(({ payload }) =>
      api({
        endpoint: CATEGORY_GET,
        params: [payload]
      }).pipe(
        mergeMap((response: any) => of(
          categoryState({
            isLoading: false,
            details: response.data,
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function categoryEditsEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(CATEGORY_EDIT),
    mergeMap(({ payload }) =>
      api({
        endpoint: CATEGORY_PUT,
        params: [state$.value.MasterDataCategory.details.id],
        body: payload,
      }).pipe(
        mergeMap((response: any) => of(
          categoryState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Berhasil',
            message: `Kategori ${payload.category} berhasil diperbaharui`,
            type: 'success',
          }),
          redirect('/categories')
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function categoryCreateEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(CATEGORY_CREATE),
    mergeMap(({ payload }) =>
      api({
        endpoint: CATEGORY_POST,
        body: payload,
      }).pipe(
        mergeMap(() => of(
          categoryState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Berhasil',
            message: `Kategori ${payload.category} berhasil ditambahkan`,
            type: 'success',
          }),
          redirect('/categories')
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function categoryDeleteEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(CATEGORY_REMOVE),
    mergeMap(({ payload }) =>
      {
      return api({
        endpoint: CATEGORY_DELETE,
        params: [payload],
      }).pipe(
        mergeMap(() => of(
          categoryList(),
          categoryState({
            isLoading: false,
            isModalConfirm: false,
            details: {},
          }),
          notificationSet({
            title: 'Berhasil',
            message: 'Kategori berhasil dihapus',
            type: 'success',
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        ))
      )
      }
    )
  )
}