import React from 'react'
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
} from 'antd'
const { Option } = Select

interface interfaceFormView {
  handleSubmit: (payload: any) => void,
  price: number,
  total_price: number,
  qty: number,
  name: string | undefined,
  isDisabledForm: boolean,
  type: string | undefined,
  unit: string | undefined,
  handlePrice: (payload: number) => void,
  handleTotalPrice: (payload: number) => void,
  handleQty: (payload: number) => void,
  handleName: (payload: string) => void,
  handleUnit: (payload: string) => void,
}

function FormView({
  handleSubmit,
  price,
  total_price,
  qty,
  name,
  isDisabledForm,
  type,
  unit,
  handlePrice,
  handleTotalPrice,
  handleQty,
  handleName,
  handleUnit,
}: interfaceFormView) {
  const title = 'Bahan Baku'
  const smallTitle = 'bahan baku'
  return (
    <>
      <div>
        <Card
          className="header-solid"
          bordered={false}
          title={[
            <React.Fragment key={0}>
              <h3 className="mb-0">
                {type === 'new' ? `${title} Baru` : name}
              </h3>
            </React.Fragment>,
          ]}
        >
          <div className="mx-auto mt-24 mb-24" style={{ maxWidth: "800px" }}>
            <Form
              name="basic"
              layout="vertical"
              onFinish={handleSubmit}
              onFinishFailed={() => {}}
              fields={[
                {
                  name: ['name'],
                  value: name
                },
                {
                  name: ['unit'],
                  value: unit
                },
                {
                  name: ['price'],
                  value: price > 0 ? price : undefined
                },
                {
                  name: ['total_price'],
                  value: total_price > 0 ? total_price : undefined
                },
                {
                  name: ['qty'],
                  value: qty > 0 ? qty : undefined
                },
              ]}
            >
              <Row gutter={32}>
                <Col span={12}>
                  <Form.Item
                    label="Nama"
                    name="name"
                    rules={[
                      { required: true, message: `Nama ${smallTitle} harus diisi!` },
                    ]}
                    colon={false}
                  >
                    <Input
                      placeholder={`Nama ${smallTitle}`}
                      onChange={(e) => handleName(e.target.value)}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Unit"
                    name="unit"
                    rules={[
                      { required: true, message: `Unit ${smallTitle} harus diisi!` },
                    ]}
                    colon={false}
                  >
                    <Select
                      placeholder="Pilih Unit"
                      onChange={(e) => handleUnit(e.target.value)}
                      allowClear
                    >
                      <Option value="Gram">Gram</Option>
                      <Option value="Mililiter">Mililiter</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Qty"
                    colon={false}
                    name="qty"
                    rules={[
                      { required: true, message: `Qty ${smallTitle} harus diisi!` },
                    ]}
                  >
                    <Input
                      placeholder={`Jumlah ${smallTitle}`}
                      type="number"
                      onChange={(e) => handleQty(+e.target.value)}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Harga"
                    colon={false}
                    name="price"
                    rules={[
                      { required: true, message: `Harga ${smallTitle} harus diisi!` },
                    ]}
                  >
                    <Input
                      placeholder={`Harga ${smallTitle}`}
                      type="number"
                      onChange={(e) => handlePrice(+e.target.value)}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Total Harga"
                    colon={false}
                    name="total_price"
                    rules={[
                      { required: true, message: `Total Harga ${smallTitle} harus diisi!` },
                    ]}
                  >
                    <Input
                      placeholder={`Total harga ${smallTitle}`}
                      type="number"
                      onChange={(e) => handleTotalPrice(+e.target.value)}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>

                {!isDisabledForm && (
                  <Col span={24} className="text-right">
                    <Form.Item>
                      <Button
                        type="primary"
                        className="px-25"
                        htmlType="submit"
                      >
                        SIMPAN
                      </Button>
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Form>
          </div>
        </Card>
      </div>
    </>
  )
}
export default FormView