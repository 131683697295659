import { Button, Row, Col, Table, Card, Select, Input } from "antd"
import { Link } from "react-router-dom"
import { SearchOutlined, UserSwitchOutlined } from "@ant-design/icons"

import { interfacePagination, interfaceSummary } from 'interface'
import { interfaceDetail } from "reduxStore/ducks/assets"

import Confirmation from "./components/confirmation"
import CardTitle from "components/reuseable/cardTitle"

const { Option } = Select;

const suffix = (
  <SearchOutlined
    style={{
      fontSize: 16,
      color: "#1890ff",
    }}
  />
);

interface interfaceListView {
  data: interfaceDetail[],
  pagination: interfacePagination,
  summary : interfaceSummary,
  handleDeleteConfirm: (payload: any) => void,
  handlePagination: (payload: any) => void,
}

function ListView({
  data,
  pagination,
  summary,
  handleDeleteConfirm,
  handlePagination,
}: interfaceListView) {

  const columns = [
    {
      title: "Nama",
      dataIndex: "name",
      ellipsis: true,
    },
    {
      title: "No HP",
      dataIndex: "phone",
      ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      ellipsis: true,
    },
    {
      title: "Instagram",
      dataIndex: "instagram",
      ellipsis: true,
    },
    // {
    //   title: "Provinsi",
    //   render: (data: any) => data.province.province_name
    // },
    {
      title: "Kota",
      render: (data: any) => data.city.city_name
    },
    // {
    //   title: "Kecamatan",
    //   render: (data: any) => data.subdistrict.subdistrict_name
    // },
    {
      title: "Action",
      width: '50px',
      render: (data: any) => {
        return (
          <>
            <Link to={`/${data.business_id}/customer/edit/${data.id}`}>
              <Button type="link">
                Ubah
              </Button>
            </Link>
            <Button type="link" danger onClick={() => handleDeleteConfirm(data)}>
              Hapus
            </Button>
          </>
        )
      },
    },
  ];

  const count = [
    {
      title: "Jumlah Pelanggan",
      price: pagination.total,
      icon: <UserSwitchOutlined
      style={{
        fontSize: 25,
        color: "white",
      }}
    />,
    }
  ];

  return (
    <>
      <CardTitle count={count} />
      <Confirmation />
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Card
            bodyStyle={{ padding: "16px 0px 0px" }}
            bordered={false}
            className="header-solid mb-24"
            title={[<h5 className="font-semibold">Daftar Pelanggan</h5>]}
          >
            <div className="px-25">
              <Row gutter={[24, 0]} className="mb-24">
                <Col span={24} lg={12}>
                  <Select
                    value={pagination.limit}
                    // onChange={this.handleTotalPageChange1}
                    size="large"
                  >
                    <Option value="5">5</Option>
                    <Option value="10">10</Option>
                    <Option value="15">15</Option>
                    <Option value="20">20</Option>
                    <Option value="25">25</Option>
                  </Select>

                  <label className="ml-10">entries per page</label>
                </Col>
                <Col span={24} lg={12} className="text-right">
                  <Input
                    style={{ maxWidth: "200px", borderRadius: "8px" }}
                    placeholder="input search text"
                    size="small"
                    suffix={suffix}
                    // onChange={this.handleFilter} 
                  />
                </Col>
              </Row>

              <Table
                className="mt-20"
                columns={columns}
                dataSource={data}
                tableLayout="auto"
                onChange={(e) => handlePagination(e)}
                scroll={{ x: 500 }}
                pagination={{
                  total: pagination.total,
                  pageSize: pagination.limit,
                  current: pagination.page,
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ListView