import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  uploadFile,
  deleteFile,
} from 'utils/uploadFile';

import {
  categoryCreate,
  categoryDetails,
  categoryEdit,
} from 'reduxStore/ducks/masterData/category'
import AddView from './FormView'

import type { UploadFile } from 'antd/es/upload/interface';

function FormContainer() {
  const dispatch = useDispatch()
  const state = useSelector((state: any) => state.MasterDataCategory)
  
  const { details, isLoading } = state
  const { type, id } = useParams()

  const [payload, setPayload] = useState({})
  const [fileList, setFileList] = useState<UploadFile[]>([])

  useEffect(() => {
    if (type !== 'new' && id) {
      dispatch(categoryDetails(id))
    }
  }, [dispatch, type, id])

  useEffect(() => {
    if (type !== 'new' && id) {
      setPayload({
        category: details.category,
        initial: details.initial,
      })
      if (details.image) {
        setFileList([
          {
            uid: '1',
            name: '',
            status: 'done',
            url: details.image,
          }
        ])
      } else {
        setFileList([])
      }
    }
  }, [details, type, id])

  const handlePayload = (data: any) => {
    setPayload({
      ...payload,
      ...data,
    })
  }

  const handleSubmit = async(values: any) => {
    let image: any = null
    if (fileList.length > 0) {
      if (fileList[0].url) {
        image = fileList[0].url
      } else {
        image = await uploadFile(fileList[0].originFileObj, values.name, 'category')
        if (image) {
          image = image?.location
        }
      }
    } else {
      if (details.image) {
        await deleteFile(details.image)
      }
    }
    const payload = {
      ...values,
      image,
    }
    if (type === 'new') {
      dispatch(categoryCreate(payload))
    } else if (type === 'edit') {
      dispatch(categoryEdit(payload))
    }
  }

  const isDisabledForm = type === 'view'

  const props = {
    payload,
    isDisabledForm,
    type,
    fileList,
    isLoading,
    handleSubmit,
    handlePayload,
    handleFileList: setFileList,
  }
  return <AddView {...props} />
}

export default FormContainer