export const status_order = [
  { label: 'Draft', value: 'draft' },
  { label: 'Menunggu Pembayaran', value: 'waiting_payment' },
  { label: 'Di Proses', value: 'process' },
  { label: 'Packing', value: 'packing' },
  { label: 'Pengiriman', value: 'shipping' },
  { label: 'Selesai', value: 'done' },
  { label: 'Reject', value: 'retur' },
]

export const payment_type = [
  { label: 'Cash', value: 'cash' },
  { label: 'Transfer', value: 'transfer' },
  { label: 'Debit', value: 'debit' },
  { label: 'Gopay', value: 'gopay' },
]

export const payment_status = [
  { label: 'Lunas', value: 'paid' },
  { label: 'Belum Lunas', value: 'unpaid' },
]

export const order_type = [
  { label: 'Online', value: 'online' },
  { label: 'Offline', value: 'offline' },
]

export const online_type = [
  { label: 'Live Sale', value: 'live_sale' },
  { label: 'Story', value: 'story' },
]