import CurrencyFormat from "react-currency-format";

interface interfaceCurrencyFormatView {
  value?: string | number;
  prefix?: string;
  displayType?: "text" | "input" | undefined;
  thousandSeparator?: boolean;
  style?: any;
}

function CurrencyFormatView({
  value = 0,
  prefix = "Rp. ",
  displayType = "text",
  thousandSeparator = true,
  style,
}: interfaceCurrencyFormatView) {
  return (
    <CurrencyFormat
      value={+value}
      prefix={prefix}
      displayType={displayType}
      thousandSeparator={thousandSeparator}
      style={style}
    />
  );
}

export default CurrencyFormatView;
