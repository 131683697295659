// CORE
import React from 'react'

// COMPONENTS
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Switch,
} from 'antd'

import { interfaceMasterData } from 'reduxStore/ducks/masterData'

interface interfaceFormView {
  handleSubmit: (payload: any) => void,
  payload: any,
  payloadAccess: any,
  isDisabledForm: boolean,
  type: string | undefined,
  isLoading?: boolean,
  masterData: interfaceMasterData,
  handlePayload: (payload: any) => void,
  handlePayloadAccess: (payload: any) => void,
}

function FormView({
  handleSubmit,
  payload,
  payloadAccess,
  isDisabledForm,
  type,
  isLoading,
  masterData,
  handlePayload,
  handlePayloadAccess,
}: interfaceFormView) {

  return (
    <>
      <div>
        <Card
          className="header-solid"
          bordered={false}
          title={[
            <React.Fragment key={0}>
              <h3 className="mb-0">
                {type === 'new' ? 'Tambah Karyawan' : payload.name}
              </h3>
            </React.Fragment>,
          ]}
        >
          <div className="mx-auto mt-24 mb-24" style={{ maxWidth: "800px" }}>
            <Form
              name="basic"
              layout="vertical"
              onFinish={handleSubmit}
              onFinishFailed={() => {}}
              fields={[
                {
                  name: ['name'],
                  value: payload.name
                },
                {
                  name: ['email'],
                  value: payload.email
                },
                {
                  name: ['phone'],
                  value: payload.phone
                },
                {
                  name: ['password'],
                  value: payload.password
                },
                {
                  name: ['position'],
                  value: payload.position
                },
                {
                  name: ['salary'],
                  value: payload.salary
                },
                {
                  name: ['start_date'],
                  value: payload.start_date
                },
                {
                  name: ['province_id'],
                  value: payload.province_id
                },
                {
                  name: ['city_id'],
                  value: payload.city_id
                },
                {
                  name: ['subdistrict_id'],
                  value: payload.subdistrict_id
                },
                {
                  name: ['postal_code'],
                  value: payload.postal_code
                },
                {
                  name: ['address'],
                  value: payload.address,
                },
                {
                  name: ['summary'],
                  value: payloadAccess.summary,
                },
                {
                  name: ['finance'],
                  value: payloadAccess.finance,
                },
                {
                  name: ['customers'],
                  value: payloadAccess.customers,
                },
                {
                  name: ['product'],
                  value: payloadAccess.product,
                },
                {
                  name: ['production'],
                  value: payloadAccess.production,
                },
                {
                  name: ['order'],
                  value: payloadAccess.order,
                },
                {
                  name: ['assets'],
                  value: payloadAccess.assets,
                },
                {
                  name: ['materials'],
                  value: payloadAccess.materials,
                },
                {
                  name: ['employees'],
                  value: payloadAccess.employees,
                },
              ]}
            >
              <Row gutter={32}>
                <Col span={12}>
                  <Form.Item
                    label="Nama"
                    name="name"
                    rules={[
                      { required: true, message: "Nama karyawan harus diisi!" },
                    ]}
                    colon={false}
                  >
                    <Input
                      placeholder="Nama karyawan"
                      disabled={isDisabledForm}
                      onChange={(e) => handlePayload({ name: e.target.value })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Nomor HP"
                    colon={false}
                    name="phone"
                    rules={[
                      { required: true, message: "Nomor HP karyawan harus diisi!" },
                    ]}
                  >
                    <Input
                      placeholder="Nomor HP karyawan"
                      type="number"
                      onChange={(e) => handlePayload({ phone: e.target.value })}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Email"
                    colon={false}
                    name="email"
                    rules={[
                      { required: true, message: "Email karyawan harus diisi!" },
                    ]}
                  >
                    <Input
                      placeholder="Email karyawan"
                      type="email"
                      onChange={(e) => handlePayload({ email: e.target.value })}
                      disabled={isDisabledForm || type !== 'new'}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Password"
                    colon={false}
                    name="password"
                    rules={[
                      { required: true, message: "Password karyawan harus diisi!" },
                    ]}
                  >
                    <Input.Password
                      placeholder="Password karyawan"
                      type="text"
                      onChange={(e) => handlePayload({ password: e.target.value })}
                      disabled={isDisabledForm || type !== 'new'}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Posisi"
                    colon={false}
                    name="position"
                    rules={[
                      { required: true, message: "Posisi karyawan harus diisi!" },
                    ]}
                  >
                    <Input
                      placeholder="Posisi karyawan"
                      type="text"
                      onChange={(e) => handlePayload({ position: e.target.value })}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Gaji"
                    colon={false}
                    name="salary"
                    rules={[
                      { required: true, message: "Gaji karyawan harus diisi!" },
                    ]}
                  >
                    <Input
                      placeholder="Gaji karyawan"
                      type="number"
                      onChange={(e) => handlePayload({ salary: +e.target.value })}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Provinsi"
                    name="province_id"
                    rules={[
                      { required: true, message: "Pilih provinsi!" },
                    ]}
                    colon={false}
                  >
                    <Select
                      showSearch
                      placeholder="Cari Kategori"
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={masterData.provinces.map(data => {
                        return { label: data.province_name, value: data.province_id }
                      })}
                      onChange={(e) => handlePayload({ province_id: e })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Kota"
                    name="city_id"
                    rules={[
                      { required: true, message: "Pilih kota!" },
                    ]}
                    colon={false}
                  >
                    <Select
                      showSearch
                      placeholder="Cari Kota"
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={masterData.cities.map(data => {
                        return { label: data.city_name, value: data.city_id }
                      })}
                      onChange={(e) => handlePayload({ city_id: e })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Kelurahan"
                    name="subdistrict_id"
                    colon={false}
                  >
                    <Select
                      showSearch
                      placeholder="Cari Kelurahan"
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={masterData.subDistricts.map(data => {
                        return { label: data.subdistrict_name, value: data.subdistrict_id }
                      })}
                      onChange={(e) => handlePayload({ subdistrict_id: e })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Kode Pos"
                    colon={false}
                    name="postal_code"
                  >
                    <Input
                      placeholder="Kode Pos"
                      type="number"
                      onChange={(e) => handlePayload({ postal_code: +e.target.value })}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Alamat"
                    colon={false}
                    name="address"
                    rules={[
                      { required: true, message: "Alamat karyawan harus diisi!" },
                    ]}
                  >
                    <Input
                      placeholder="Alamat karyawan"
                      type="text"
                      onChange={(e) => handlePayload({ address: e.target.value })}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>

                <Divider />
                {/* ====================== DISCOUNT SECTION ====================== */}
                <Col span={24}>
                  <Form.Item
                    label="Summary"
                    colon={false}
                    name="summary"
                  >
                    <Switch checked={payloadAccess.summary} onChange={(e) => handlePayloadAccess({ summary: e })}/>
                    {payloadAccess.summary ? (
                      <span> User dapat mengakses summary!</span>
                    ) : (
                      <span> User tidak dapat mengakses summary!</span>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Keuangan"
                    colon={false}
                    name="finance"
                  >
                    <Switch checked={payloadAccess.finance} onChange={(e) => handlePayloadAccess({ finance: e })}/>
                    {payloadAccess.finance ? (
                      <span> User dapat mengakses keuangan!</span>
                    ) : (
                      <span> User tidak dapat mengakses keuangan!</span>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Pelanggan"
                    colon={false}
                    name="customers"
                  >
                    <Switch checked={payloadAccess.customers} onChange={(e) => handlePayloadAccess({ customers: e })}/>
                    {payloadAccess.customers ? (
                      <span> User dapat mengakses pelanggan!</span>
                    ) : (
                      <span> User tidak dapat mengakses pelanggan!</span>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Produk"
                    colon={false}
                    name="producct"
                  >
                    <Switch checked={payloadAccess.product} onChange={(e) => handlePayloadAccess({ product: e })}/>
                    {payloadAccess.product ? (
                      <span> User dapat mengakses produk!</span>
                    ) : (
                      <span> User tidak dapat mengakses produk!</span>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Produksi"
                    colon={false}
                    name="production"
                  >
                    <Switch checked={payloadAccess.production} onChange={(e) => handlePayloadAccess({ production: e })}/>
                    {payloadAccess.production ? (
                      <span> User dapat mengakses produksi!</span>
                    ) : (
                      <span> User tidak dapat mengakses produksi!</span>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Order"
                    colon={false}
                    name="order"
                  >
                    <Switch checked={payloadAccess.order} onChange={(e) => handlePayloadAccess({ order: e })}/>
                    {payloadAccess.order ? (
                      <span> User dapat mengakses order!</span>
                    ) : (
                      <span> User tidak dapat mengakses order!</span>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Aset"
                    colon={false}
                    name="assets"
                  >
                    <Switch checked={payloadAccess.assets} onChange={(e) => handlePayloadAccess({ assets: e })}/>
                    {payloadAccess.assets ? (
                      <span> User dapat mengakses aset!</span>
                    ) : (
                      <span> User tidak dapat mengakses aset!</span>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Bahan Baku"
                    colon={false}
                    name="materials"
                  >
                    <Switch checked={payloadAccess.materials} onChange={(e) => handlePayloadAccess({ materials: e })}/>
                    {payloadAccess.materials ? (
                      <span> User dapat mengakses bahan baku!</span>
                    ) : (
                      <span> User tidak dapat mengakses bahan baku!</span>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Karyawan"
                    colon={false}
                    name="employees"
                  >
                    <Switch checked={payloadAccess.employees} onChange={(e) => handlePayloadAccess({ employees: e })}/>
                    {payloadAccess.employees ? (
                      <span> User dapat mengakses karyawan!</span>
                    ) : (
                      <span> User tidak dapat mengakses karyawan!</span>
                    )}
                  </Form.Item>
                </Col>

                {!isDisabledForm && (
                  <Col span={24} className="text-right">
                    <Form.Item>
                      <Button
                        type="primary"
                        className="px-25"
                        htmlType="submit"
                        disabled={isLoading}
                        loading={isLoading}
                        onClick={handleSubmit}
                      >
                        SIMPAN
                      </Button>
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Form>
          </div>
        </Card>
      </div>
    </>
  )
}
export default FormView