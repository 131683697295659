import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  productionsList,
} from 'reduxStore/ducks/productions'
import ListView from './ListView'

function ListContainer() {
  const dispatch = useDispatch()

  const state = useSelector((state: any) => state.Productions)

  const {
    data,
    pagination,
    summary
  } = state

  useEffect(() => {
    dispatch(productionsList())
  }, [dispatch])

  const handlePagination = (payload: any) => {
    dispatch(productionsList({
      page: payload.current, 
      limit: payload.pageSize, 
    }))
  }

  const props = {
    data,
    pagination,
    summary,
    handlePagination,
  }

  return <ListView {...props} />
}

export default ListContainer