import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'

import {
  AUTH_LOGIN_POST,
  AUTH_LOGOUT_DELETE,
  AUTH_DETAIL_GET,
  AUTH_UPDATE_PUT,
} from 'config/endpoints'

import {
  AUTH_LOGIN,
  AUTH_DETAIL,
  AUTH_LOGOUT,
  AUTH_UPDATE,
  authDetail,
  authFailed,
  authDetailSuccess,
  authState,
} from 'reduxStore/ducks/auth/index'

import {
  notificationSet,
} from 'reduxStore/ducks/notifications'

import {
  redirect,
} from 'reduxStore/ducks/redirect'

import {
  setToken,
  setMe,
  removeMe,
  removeToken,
} from 'utils/cookies'

export function authLoginEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(AUTH_LOGIN),
    mergeMap(({ payload }) =>
      api({
        endpoint: AUTH_LOGIN_POST,
        body: payload,
      }).pipe(
        mergeMap((response: any) => {
          const token = response.data.accessToken
          setToken(token)
          return of(
            authDetail(),
            redirect(''),
          )
        }),
        catchError((err) => of(
          authFailed('Failed Fetching Data'),
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function authDetailEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(AUTH_DETAIL),
    mergeMap(({ payload }) =>
      api({
        endpoint: AUTH_DETAIL_GET,
        body: payload,
      }).pipe(
        mergeMap((response: any) => {
          setMe(response.data)
          return of(
            authDetailSuccess(response.data),
          )
        }),
        catchError(() => of(authFailed('Failed Fetching Data'))),
      )
    ),
  )
}

export function authLogoutEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(AUTH_LOGOUT),
    mergeMap(() => 
      api({
        endpoint: AUTH_LOGOUT_DELETE,
      }).pipe(
        mergeMap(() => {
          removeMe()
          removeToken()
          return of(
            redirect('/login')
          )
        }),
        catchError(() => of(authFailed('Failed Fetching Data'))),
      )
    ),
  )
}

export function authUpdateEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(AUTH_UPDATE),
    mergeMap(({ payload }) =>
      api({
        endpoint: AUTH_UPDATE_PUT,
        params: [payload.id],
        body: payload,
      }).pipe(
        mergeMap((response: any) => {
          setMe(response.data)
          return of(
            authState({
              isLoading: false,
            }),
            authDetail(),
            notificationSet({
              title: 'Berhasil',
              message: 'Data akun berhasil diperbaharui!',
              type: 'success',
            }),
          )
        }),
        catchError((err) => of(
          authState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}