// CORE
import React from 'react'

// COMPONENTS
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
} from 'antd'

import UploadFileCard from 'components/reuseable/uploadFile';

import type { UploadFile } from 'antd/es/upload/interface';

interface interfaceFormView {
  payload: any,
  categories: any[],
  isDisabledForm: boolean,
  type: string | undefined,
  fileList: UploadFile[],
  isLoading?: boolean,
  handlePayload: (payload: any) => void,
  handleFileList: (payload: UploadFile[]) => void,
  handleSubmit: (payload: any) => void,
}

function FormView({
  payload,
  categories,
  isDisabledForm,
  type,
  fileList,
  isLoading,
  handlePayload,
  handleFileList,
  handleSubmit,
}: interfaceFormView) {

  return (
    <>
      <div>
        <Card
          className="header-solid"
          bordered={false}
          title={[
            <React.Fragment key={0}>
              <h3 className="mb-0">
                {type === 'new' ? 'Sub Kategori Baru' : payload.sub_category}
              </h3>
            </React.Fragment>,
          ]}
        >
          <div className="mx-auto mt-24 mb-24" style={{ maxWidth: "800px" }}>
            <Form
              name="basic"
              layout="vertical"
              onFinish={handleSubmit}
              onFinishFailed={() => {}}
              fields={[
                {
                  name: ['category_id'],
                  value: payload.category_id
                },
                {
                  name: ['sub_category'],
                  value: payload.sub_category
                },
                {
                  name: ['initial'],
                  value: payload.initial
                },
              ]}
            >
              <Row gutter={[24, 0]}>
                <Col span={12}>
                  <Form.Item
                    label="Kategori"
                    name="category_id"
                    rules={[
                      { required: true, message: "Pilih kategori!" },
                    ]}
                    colon={false}
                  >
                    <Select
                      showSearch
                      // style={{ width: 200 }}
                      placeholder="Cari Kategori"
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={categories}
                      onChange={(e) => handlePayload({ category_id: e })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Sub Kategori"
                    name="sub_category"
                    rules={[
                      { required: true, message: "Nama sub kategori harus diisi!" },
                    ]}
                    colon={false}
                  >
                    <Input
                      placeholder="Nama sub kategori"
                      disabled={isDisabledForm}
                      onChange={(e) => handlePayload({ sub_category: e.target.value })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Initial"
                    name="initial"
                    rules={[
                      { required: true, message: "Initial harus diisi!" },
                    ]}
                    colon={false}
                  >
                    <Input
                      placeholder="Inisial Untuk SKU Produk"
                      disabled={isDisabledForm}
                      onChange={(e) => handlePayload({ initial: e.target.value })}
                      maxLength={3}
                    />
                  </Form.Item>
                </Col>
                
                <Col span={12}>
                  <UploadFileCard fileList={fileList} handleFileList={handleFileList} />
                </Col>

                {!isDisabledForm && (
                  <Col span={24} className="text-right">
                    <Form.Item>
                      <Button
                        type="primary"
                        className="px-25"
                        htmlType="submit"
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        SIMPAN
                      </Button>
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Form>
          </div>
        </Card>
      </div>
    </>
  )
}
export default FormView