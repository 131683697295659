import createReducer from 'utils/createReducer'

export const MITRA_LIST = 'MITRA_LIST'
export const MITRA_STATE = 'MITRA_STATE'

export interface interfaceAssets {
  data: any[],
  isLoading: boolean,
  isError: boolean,
  isModalConfirm: boolean,
  errorMessage: string,
}

export const INITIAL_STATE: interfaceAssets = {
  data: [],
  isLoading: false,
  isError: false,
  isModalConfirm: false,
  errorMessage: '',
}

const reducer = createReducer(INITIAL_STATE, {
  [MITRA_STATE]: (state: any, payload: any) => ({
    ...state,
    ...payload,
  })
})

export const mitraList = (payload: any | undefined = null) => ({
  type: MITRA_LIST,
  payload,
})
export const mitraState = (payload: any) => ({
  type: MITRA_STATE,
  payload,
})

export default reducer