import { useDispatch, useSelector } from 'react-redux'
import { customersState, customersRemove } from 'reduxStore/ducks/customers'

import ConfirmationView from './confirmationView'

function ConfirmationContainer() {
  const dispatch = useDispatch()
  const state = useSelector((state: any) => state.Customers)

  const {
    details,
    isLoading,
    isModalConfirm,
  } = state

  const handleCancel = () => {
    dispatch(customersState({
      isModalConfirm: false,
    }))
  }

  const handleConfirm = () => {
    dispatch(customersRemove(details.id))
  }

  const props = {
    details,
    isLoading,
    isModalConfirm,
    handleCancel,
    handleConfirm,
  }
  return <ConfirmationView {...props} />
}

export default ConfirmationContainer