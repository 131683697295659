import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  uploadFile,
  deleteFile,
} from 'utils/uploadFile';

import {
  assetsCreate,
  assetsDetails,
  assetsEdit,
} from 'reduxStore/ducks/assets'
import AddView from './FormView'

import type { UploadFile } from 'antd/es/upload/interface';

function FormContainer() {
  const dispatch = useDispatch()
  const state = useSelector((state: any) => state.Assets)
  
  const { details, isLoading } = state
  const { type, id } = useParams()

  const [price, setPrice] = useState(0)
  const [total_price, setTotalPrice] = useState(0)
  const [qty, setQty] = useState(0)
  const [name, setName] = useState('')
  const [fileList, setFileList] = useState<UploadFile[]>([])

  useEffect(() => {
    if (type !== 'new' && id) {
      dispatch(assetsDetails(id))
    }
  }, [dispatch, type, id])

  useEffect(() => {
    if (type !== 'new' && id) {
      setPrice(details.price)
      setTotalPrice(details.total_price)
      setQty(details.qty)
      setName(details.name)
      if (details.image) {
        setFileList([
          {
            uid: '1',
            name: '',
            status: 'done',
            url: details.image,
          }
        ])
      } else {
        setFileList([])
      }
    }
  }, [details, type, id])

  const handleSubmit = async(values: any) => {
    let image: any = null
    if (fileList.length > 0) {
      if (fileList[0].url) {
        image = fileList[0].url
      } else {
        image = await uploadFile(fileList[0].originFileObj, values.name, 'assets')
        if (image) {
          image = image?.location
        }
      }
    } else {
      console.log('details', details)
      if (details.image) {
        await deleteFile(details.image)
      }
    }
    const payload = {
      ...values,
      price: +values.price,
      total_price: +values.total_price,
      qty: +values.qty,
      image,
    }
    if (type === 'new') {
      dispatch(assetsCreate(payload))
    } else if (type === 'edit') {
      dispatch(assetsEdit(payload))
    }
  }

  const handlePrice = (value: number) => {
    setPrice(value)
    setTotalPrice(qty > 0 ? (value * qty) : 0)
  }

  const handleTotalPrice = (value: number) => {
    setTotalPrice(value)
    setPrice(qty > 0 ? (value / qty) : 0)
  }
  
  const handleQty = (value: number) => {
    setQty(value)

    if (price > 0) {
      setTotalPrice(value * price)
    } else {
      setPrice(total_price / value)
    }
  }

  const isDisabledForm = type === 'view'

  const props = {
    price,
    total_price,
    qty,
    name,
    isDisabledForm,
    type,
    fileList,
    isLoading,
    handleSubmit,
    handlePrice,
    handleTotalPrice,
    handleQty,
    handleName: setName,
    handleFileList: setFileList,
  }
  return <AddView {...props} />
}

export default FormContainer