import createReducer from 'utils/createReducer'
import { interfacePagination } from 'interface'

export const LOG_ACTIVITY_CREATE = 'LOG_ACTIVITY_CREATE'
export const LOG_ACTIVITY_EDIT = 'LOG_ACTIVITY_EDIT'
export const LOG_ACTIVITY_REMOVE = 'LOG_ACTIVITY_REMOVE'
export const LOG_ACTIVITY_DETAILS = 'LOG_ACTIVITY_DETAILS'
export const LOG_ACTIVITY_LIST = 'LOG_ACTIVITY_LIST'

export const LOG_ACTIVITY_STATE = 'LOG_ACTIVITY_STATE'

export interface interfaceDetail {
  name?: string,
  business_id?: string,
  user_id?: string,
  type?: string,
  source?: string,
  id_source?: string,
  id?: string,
  image?: string,
  qty?: number,
  price?: number,
  total_price?: number,
}

export interface interfaceAssets {
  details: interfaceDetail,
  data: interfaceDetail[],
  pagination: interfacePagination,
  isLoading: boolean,
  isError: boolean,
  isModalConfirm: boolean,
  errorMessage: string,
}

export const INITIAL_STATE: interfaceAssets = {
  details: {},
  data: [],
  pagination: {
    total: 0,
    page: 1,
    limit: 20,
  },
  isLoading: false,
  isError: false,
  isModalConfirm: false,
  errorMessage: '',
}

const reducer = createReducer(INITIAL_STATE, {

  [LOG_ACTIVITY_CREATE]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [LOG_ACTIVITY_EDIT]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [LOG_ACTIVITY_REMOVE]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [LOG_ACTIVITY_DETAILS]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [LOG_ACTIVITY_LIST]: (state: any, payload: any) => ({
    ...state,
    isLoading: true,
    pagination: {
      ...state.pagination,
      page: payload?.page ? payload.page : state.pagination.page,
      limit: payload?.limit ? payload.limit : state.pagination.limit,
    }
  }),

  [LOG_ACTIVITY_STATE]: (state: any, payload: any) => ({
    ...state,
    ...payload,
  })
})

export const logActivityCreate = (payload: any) => ({
  type: LOG_ACTIVITY_CREATE,
  payload,
})
export const logActivityEdit = (payload: any) => ({
  type: LOG_ACTIVITY_EDIT,
  payload,
})
export const logActivityRemove = (payload: any) => ({
  type: LOG_ACTIVITY_REMOVE,
  payload,
})
export const logActivityDetails = (payload: any) => ({
  type: LOG_ACTIVITY_DETAILS,
  payload,
})
export const logActivityList = (payload: any | undefined = null) => ({
  type: LOG_ACTIVITY_LIST,
  payload,
})
export const logActivityState = (payload: any) => ({
  type: LOG_ACTIVITY_STATE,
  payload,
})

export default reducer