import {
  Modal
} from 'antd'

import { interfaceDetail } from 'reduxStore/ducks/masterData/subCategory'

interface interfaceConfirmationView {
  details: interfaceDetail,
  isLoading: boolean,
  isModalConfirm: boolean,
  handleConfirm: () => void,
  handleCancel: () => void,
}

function ConfirmationView({
  details,
  isLoading,
  isModalConfirm,
  handleConfirm,
  handleCancel,
}: interfaceConfirmationView) {
  return (
    <Modal
      title="Konfirmasi"
      open={isModalConfirm}
      onOk={handleConfirm}
      confirmLoading={isLoading}
      onCancel={handleCancel}
    >
      <p>Anda yakin ingin menghapus sub kategori <b>{details.sub_category}</b> ?</p>
    </Modal>
  )
}

export default ConfirmationView