import { useEffect } from "react"
import { useSelector } from "react-redux"

import { notification } from "antd"

function Notifications() {
  const notifications = useSelector((state: any) => state.Notifications)
  useEffect(() => {
    if (notifications.title !== '' || notifications.message !== '') {
      const status: 'success' | 'error' | 'info' | 'warning' = notifications.type
      notification[status]({
        message: notifications.title,
        description: notifications.message,
      });
    }
  },[notifications])

  return <></>;
}

export default Notifications