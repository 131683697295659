import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  uploadFile,
  deleteFile,
} from 'utils/uploadFile';

import {
  subCategoryCreate,
  subCategoryDetails,
  subCategoryEdit,
} from 'reduxStore/ducks/masterData/subCategory'


import {
  categoryList,
  categoryState,
} from 'reduxStore/ducks/masterData/category'

import AddView from './FormView'

import type { UploadFile } from 'antd/es/upload/interface';

function FormContainer() {
  const dispatch = useDispatch()
  const category = useSelector((state: any) => state.MasterDataCategory)
  const subCategory = useSelector((state: any) => state.MasterDataSubCategory)
  
  const { details, isLoading } = subCategory
  const { data } = category
  const { type, id } = useParams()

  const [sub_category, setSubCategory] = useState('')
  const [category_id, setCategoryId] = useState('')
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [payload, setPayload] = useState({})

  useEffect(() => {
    dispatch(categoryState({
      pagination: {
        total: 0,
        page: undefined,
        limit: undefined,
      }
    }))
    dispatch(categoryList())
  }, [dispatch])

  useEffect(() => {
    if (type !== 'new' && id) {
      dispatch(subCategoryDetails(id))
    }
  }, [dispatch, type, id])

  useEffect(() => {
    if (type !== 'new' && id) {
      setPayload({
        sub_category: details.sub_category,
        category_id: details.category_id,
        initial: details.initial,
      })
      if (details.image) {
        setFileList([
          {
            uid: '1',
            name: '',
            status: 'done',
            url: details.image,
          }
        ])
      } else {
        setFileList([])
      }
    }
  }, [details, type, id])

  const handlePayload = (data: any) => {
    setPayload({
      ...payload,
      ...data,
    })
  }

  const handleSubmit = async(values: any) => {
    let image: any = null
    if (fileList.length > 0) {
      if (fileList[0].url) {
        image = fileList[0].url
      } else {
        image = await uploadFile(fileList[0].originFileObj, values.name, 'sub-category')
        if (image) {
          image = image?.location
        }
      }
    } else {
      if (details.image) {
        await deleteFile(details.image)
      }
    }
    const payload = {
      ...values,
      image,
    }
    if (type === 'new') {
      dispatch(subCategoryCreate(payload))
    } else if (type === 'edit') {
      dispatch(subCategoryEdit(payload))
    }
  }

  const isDisabledForm = type === 'view'

  const categories = data.map((item: any) => {
    return {
      value: item.id,
      label: item.category,
    }
  })

  const props = {
    payload,
    isDisabledForm,
    type,
    fileList,
    isLoading,
    categories,
    handleSubmit,
    handlePayload,
    handleFileList: setFileList,
  }
  return <AddView {...props} />
}

export default FormContainer