import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  employeesCreate,
  employeesDetails,
  employeesEdit,
} from 'reduxStore/ducks/employees'

import {
  provincesList,
  citiesList,
  subDistrictsList,
} from 'reduxStore/ducks/masterData'

import AddView from './FormView'

function FormContainer() {
  const dispatch = useDispatch()
  const state = useSelector((state: any) => state.Employees)
  const masterData = useSelector((state: any) => state.MasterData)
  
  const { details, isLoading } = state
  const { type, id } = useParams()

  const [payload, setPayload] = useState({})
  const [payloadAccess, setPayloadAccess] = useState({
    summary: false,
    finance: false,
    customers: false,
    product: false,
    production: false,
    order: false,
    assets: false,
    materials: false,
    employees: false,
  })

  useEffect(() => {
    dispatch(provincesList())
  }, [dispatch])

  useEffect(() => {
    if (type !== 'new' && id) {
      dispatch(employeesDetails(id))
    }
  }, [dispatch, type, id])

  useEffect(() => {
    if (type !== 'new' && id) {
      setPayload({
        name: details.name,
        email: details?.employeesUser?.email,
        phone: details.phone,
        position: details.position,
        salary: details.salary,
        province_id: details.province_id,
        city_id: details.city_id,
        subdistrict_id: details.subdistrict_id,
        postal_code: details.postal_code,
        address: details.address,
      })
      if (details.employeesUserAccess) {
        setPayloadAccess({
          summary: details.employeesUserAccess.summary,
          finance: details.employeesUserAccess.finance,
          customers: details.employeesUserAccess.customers,
          product: details.employeesUserAccess.product,
          production: details.employeesUserAccess.production,
          order: details.employeesUserAccess.order,
          assets: details.employeesUserAccess.assets,
          materials: details.employeesUserAccess.materials,
          employees: details.employeesUserAccess.employees,
        })
      }
      if (details.province_id) {
        dispatch(citiesList(details.province_id))
      }
      if (details.city_id) {
        dispatch(subDistrictsList(details.city_id))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details, type, id])

  const handleSubmit = async(values: any) => {
    const payloadBody = {
      ...payload,
      access: payloadAccess,
    }
    if (type === 'new') {
      dispatch(employeesCreate(payloadBody))
    } else if (type === 'edit') {
      dispatch(employeesEdit(payloadBody))
    }
  }
  
  const isDisabledForm = type === 'view'

  const handlePayload = (data: any) => {
    if (data.province_id) {
      dispatch(citiesList(data.province_id))
    }
    if (data.city_id) {
      dispatch(subDistrictsList(data.city_id))
    }
    setPayload({
      ...payload,
      ...data,
    })
  }

  const handlePayloadAccess = (data: any) => {
    setPayloadAccess({
      ...payloadAccess,
      ...data,
    })
  }

  const props = {
    payload,
    payloadAccess,
    isDisabledForm,
    type,
    isLoading,
    masterData,
    handleSubmit,
    handlePayload,
    handlePayloadAccess,
  }
  return <AddView {...props} />
}

export default FormContainer