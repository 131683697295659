import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'

import {
  SUB_CATEGORY_GET,
  SUB_CATEGORY_DELETE,
  SUB_CATEGORY_POST,
  SUB_CATEGORY_PUT,
} from 'config/endpoints'

import {
  SUB_CATEGORY_CREATE,
  SUB_CATEGORY_EDIT,
  SUB_CATEGORY_REMOVE,
  SUB_CATEGORY_DETAILS,
  SUB_CATEGORY_LIST,
  subCategoryState,
  subCategoryList,
} from 'reduxStore/ducks/masterData/subCategory/index'

import {
  notificationSet,
} from 'reduxStore/ducks/notifications'

import {
  redirect,
} from 'reduxStore/ducks/redirect'

export function subCategoryListEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(SUB_CATEGORY_LIST),
    mergeMap(() =>
      api({
        endpoint: SUB_CATEGORY_GET,
        query: {
          ...state$.value.MasterDataSubCategory.pagination
        }
      }).pipe(
        mergeMap((response: any) => of(
          subCategoryState({
            isLoading: false,
            data: response.data.data,
            pagination: response.data.pagination,
            summary : response.data.summary
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function subCategoryDetailsEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(SUB_CATEGORY_DETAILS),
    mergeMap(({ payload }) =>
      api({
        endpoint: SUB_CATEGORY_GET,
        params: [payload]
      }).pipe(
        mergeMap((response: any) => of(
          subCategoryState({
            isLoading: false,
            details: response.data,
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function subCategoryEditsEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(SUB_CATEGORY_EDIT),
    mergeMap(({ payload }) =>
      api({
        endpoint: SUB_CATEGORY_PUT,
        params: [state$.value.MasterDataSubCategory.details.id],
        body: payload,
      }).pipe(
        mergeMap((response: any) => of(
          subCategoryState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Berhasil',
            message: `Sub kategori ${payload.sub_category} berhasil diperbaharui`,
            type: 'success',
          }),
          redirect('/sub-categories')
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function subCategoryCreateEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(SUB_CATEGORY_CREATE),
    mergeMap(({ payload }) =>
      api({
        endpoint: SUB_CATEGORY_POST,
        body: payload,
      }).pipe(
        mergeMap(() => of(
          subCategoryState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Berhasil',
            message: `Sub kategori ${payload.sub_category} berhasil ditambahkan`,
            type: 'success',
          }),
          redirect('/sub-categories')
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function subCategoryDeleteEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(SUB_CATEGORY_REMOVE),
    mergeMap(({ payload }) =>
      {
      return api({
        endpoint: SUB_CATEGORY_DELETE,
        params: [payload],
      }).pipe(
        mergeMap(() => of(
          subCategoryList(),
          subCategoryState({
            isLoading: false,
            isModalConfirm: false,
            details: {},
          }),
          notificationSet({
            title: 'Berhasil',
            message: 'Sub kategori berhasil dihapus',
            type: 'success',
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        ))
      )
      }
    )
  )
}