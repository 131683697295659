import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  unitCreate,
  unitDetails,
  unitEdit,
} from 'reduxStore/ducks/masterData/unit'
import AddView from './FormView'


function FormContainer() {
  const dispatch = useDispatch()
  const state = useSelector((state: any) => state.MasterDataUnit)
  
  const { details, isLoading } = state
  const { type, id } = useParams()

  const [unit, setUnit] = useState('')

  useEffect(() => {
    if (type !== 'new' && id) {
      dispatch(unitDetails(id))
    }
  }, [dispatch, type, id])

  useEffect(() => {
    if (type !== 'new' && id) {
      setUnit(details.unit)
    }
  }, [details, type, id])

  const handleSubmit = async(values: any) => {
    const payload = {
      ...values,
    }
    if (type === 'new') {
      dispatch(unitCreate(payload))
    } else if (type === 'edit') {
      dispatch(unitEdit(payload))
    }
  }

  const isDisabledForm = type === 'view'

  const props = {
    unit,
    isDisabledForm,
    type,
    isLoading,
    handleSubmit,
    handleUnit: setUnit,
  }
  return <AddView {...props} />
}

export default FormContainer