import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  interfaceDetail,
  productsList,
  productsState,
} from 'reduxStore/ducks/products'
import ListView from './ListView'

function ListContainer() {
  const dispatch = useDispatch()

  const state = useSelector((state: any) => state.Products)

  const {
    data,
    pagination,
    summary
  } = state

  useEffect(() => {
    dispatch(productsList())
  }, [dispatch])

  const handleDeleteConfirm = (payload: interfaceDetail) => {
    dispatch(productsState({
      isModalConfirm: true,
    }))
    handleSelected(payload)
  }

  const handleSelected = (payload: interfaceDetail) => {
    dispatch(productsState({
      details: payload,
    }))
  }

  const handlePagination = (payload: any) => {
    dispatch(productsList({
      page: payload.current, 
      limit: payload.pageSize, 
    }))
  }

  const props = {
    data,
    pagination,
    summary,
    handleDeleteConfirm,
    handlePagination,
  }

  return <ListView {...props} />
}

export default ListContainer