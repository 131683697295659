import {
  Layout,
  Button,
  Typography,
  Card,
  Form,
  Input,
} from "antd";
import { getBranchName } from "utils/cookies"

const { Title } = Typography;
const { Footer, Content } = Layout;

interface InterfaceLoginView {
  handleLogin: (payload: any) => void,
}

function LoginView({
  handleLogin,
}: InterfaceLoginView) {
  return (
    <>
      <div className="layout-default ant-layout layout-sign-up">

        <Content className="p-0">
          <div className="sign-up-header">
            <div className="content">
              <Title style={{ textTransform: 'capitalize' }}>{getBranchName()}</Title>
              <p className="text-lg">
                Login to manage the business.
              </p>
            </div>
          </div>

          <Card
            className="card-signup header-solid h-full ant-card pt-0"
            title={<h5>LOGIN</h5>}
            bordered={false}
          >
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={handleLogin}
              onFinishFailed={() => {}}
              layout="vertical"
              className="row-col"
            >
              <Form.Item
                className="username"
                name="email"
                label="Email"
                rules={[
                  { type: "email" },
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input placeholder="email" />
              </Form.Item>
              <Form.Item
                className="username"
                name="password"
                label="Password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <Form.Item>
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                >
                  LOGIN
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Content>
        <Footer>
          <p className="copyright">
            {" "}
            Copyright © 2024 <a href="#pablo">Karplanter</a>.{" "}
          </p>
        </Footer>
      </div>
    </>
  )
}

export default LoginView