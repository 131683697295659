import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
// import {
//   uploadFile,
//   deleteFile,
// } from 'utils/uploadFile';

import {
  getMe,
} from 'utils/cookies'

import {
  authUpdate,
} from 'reduxStore/ducks/auth'
import AddView from './FormView'

import type { UploadFile } from 'antd/es/upload/interface';

function FormContainer() {
  const dispatch = useDispatch()
  const state = useSelector((state: any) => state.Assets)
  
  const { details, isLoading } = state
  const { type, id } = useParams()
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [payload, setPayload] = useState({})

  useEffect(() => {
    const account = getMe()
    setPayload({
      ...payload,
      id: account.id,
      name: account.name,
      email: account.email,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, getMe())

  useEffect(() => {
    if (type !== 'new' && id) {
      if (details.image) {
        setFileList([
          {
            uid: '1',
            name: '',
            status: 'done',
            url: details.image,
          }
        ])
      } else {
        setFileList([])
      }
    }
  }, [details, type, id])

  const handleSubmit = async(values: any) => {
    // let image: any = null
    // if (fileList.length > 0) {
    //   if (fileList[0].url) {
    //     image = fileList[0].url
    //   } else {
    //     image = await uploadFile(fileList[0].originFileObj, values.name, 'assets')
    //     if (image) {
    //       image = image?.location
    //     }
    //   }
    // } else {
    //   if (details.image) {
    //     await deleteFile(details.image)
    //   }
    // }
    const newPayload = {
      ...payload,
      // image,
    }
    dispatch(authUpdate(newPayload))
  }

  const handlePayload = (data: any) => {
    setPayload({
      ...payload,
      ...data,
    })
  }

  const props = {
    payload,
    fileList,
    isLoading,
    handleSubmit,
    handleFileList: setFileList,
    handlePayload,
  }
  return <AddView {...props} />
}

export default FormContainer