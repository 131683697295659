import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'

import configureStore from 'reduxStore'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

import "antd/dist/antd.min.css"
import "./assets/styles/main.css"
import "./assets/styles/responsive.css"

import Notifications from 'components/reuseable/notifications'
// import Redirect from 'components/reuseable/redirectRedux'

const initialState = {}
const store = configureStore(initialState)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <Notifications />
      {/* <Redirect /> */}
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
