import createReducer from 'utils/createReducer'

export const PROVINCES_LIST = 'PROVINCES_LIST'
export const CITIES_LIST = 'CITIES_LIST'
export const SUB_DISTRICTS_LIST = 'SUB_DISTRICTS_LIST'
export const CATEGORIES_LIST = 'CATEGORIES_LIST'
export const SUB_CATEGORIES_LIST = 'SUB_CATEGORIES_LIST'
export const UNITS_LIST = 'UNITS_LIST'

export const MASTERDATA_STATE = 'MASTERDATA_STATE'

interface category {
  id: string,
  image: string,
  category: string,
  initial: string,
}

export interface interfaceMasterData {
  provinces: {
    province_id: number,
    province_name: string,
  }[],
  cities: {
    city_id: number,
    province_id: number,
    city_name: string,
    postal_code: string,
  }[],
  subDistricts: {
    subdistrict_id: number,
    city_id: number,
    subdistrict_name: string,
  }[],
  categories: category[],
  subCategories: {
    id: string,
    category_id: string,
    image: string,
    sub_category: string,
    initial: string,
    category: category,
  }[],
  isLoading: boolean,
  isError: boolean,
  isModalConfirm: boolean,
  errorMessage: string,
}

export const INITIAL_STATE: interfaceMasterData = {
  provinces: [],
  cities: [],
  subDistricts: [],
  categories: [],
  subCategories: [],
  isLoading: false,
  isError: false,
  isModalConfirm: false,
  errorMessage: '',
}

const reducer = createReducer(INITIAL_STATE, {
  [MASTERDATA_STATE]: (state: any, payload: any) => ({
    ...state,
    ...payload,
  })
})

export const provincesList = (payload: any | undefined = null) => ({
  type: PROVINCES_LIST,
  payload,
})
export const citiesList = (payload: any | undefined = null) => ({
  type: CITIES_LIST,
  payload,
})
export const subDistrictsList = (payload: any | undefined = null) => ({
  type: SUB_DISTRICTS_LIST,
  payload,
})
export const categoriesList = (payload: any | undefined = null) => ({
  type: CATEGORIES_LIST,
  payload,
})
export const subCategoriesList = (payload: any | undefined = null) => ({
  type: SUB_CATEGORIES_LIST,
  payload,
})
export const unitsList = (payload: any | undefined = null) => ({
  type: UNITS_LIST,
  payload,
})
export const masterDataState = (payload: any) => ({
  type: MASTERDATA_STATE,
  payload,
})

export default reducer