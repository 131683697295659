import createReducer from 'utils/createReducer'
import { interfacePagination, interfaceSummary } from 'interface'

export const ASSETS_CREATE = 'ASSETS_CREATE'
export const ASSETS_EDIT = 'ASSETS_EDIT'
export const ASSETS_REMOVE = 'ASSETS_REMOVE'
export const ASSETS_DETAILS = 'ASSETS_DETAILS'
export const ASSETS_LIST = 'ASSETS_LIST'

export const ASSETS_STATE = 'ASSETS_STATE'

export interface interfaceDetail {
  name?: string,
  id?: string,
  image?: string,
  business_id?: string,
  qty?: number,
  price?: number,
  total_price?: number,
}

export interface interfaceAssets {
  details: interfaceDetail,
  data: interfaceDetail[],
  pagination: interfacePagination,
  summary : interfaceSummary,
  isLoading: boolean,
  isError: boolean,
  isModalConfirm: boolean,
  errorMessage: string,
}

export const INITIAL_STATE: interfaceAssets = {
  details: {},
  data: [],
  summary : {},
  pagination: {
    total: 0,
    page: 1,
    limit: 20,
  },
  isLoading: false,
  isError: false,
  isModalConfirm: false,
  errorMessage: '',
}

const reducer = createReducer(INITIAL_STATE, {

  [ASSETS_CREATE]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [ASSETS_EDIT]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [ASSETS_REMOVE]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [ASSETS_DETAILS]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [ASSETS_LIST]: (state: any, payload: any) => ({
    ...state,
    isLoading: true,
    pagination: {
      ...state.pagination,
      page: payload?.page ? payload.page : state.pagination.page,
      limit: payload?.limit ? payload.limit : state.pagination.limit,
    }
  }),

  [ASSETS_STATE]: (state: any, payload: any) => ({
    ...state,
    ...payload,
  })
})

export const assetsCreate = (payload: any) => ({
  type: ASSETS_CREATE,
  payload,
})
export const assetsEdit = (payload: any) => ({
  type: ASSETS_EDIT,
  payload,
})
export const assetsRemove = (payload: any) => ({
  type: ASSETS_REMOVE,
  payload,
})
export const assetsDetails = (payload: any) => ({
  type: ASSETS_DETAILS,
  payload,
})
export const assetsList = (payload: any | undefined = null) => ({
  type: ASSETS_LIST,
  payload,
})
export const assetsState = (payload: any) => ({
  type: ASSETS_STATE,
  payload,
})

export default reducer