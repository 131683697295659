// CORE
import React from 'react'
import { Link } from "react-router-dom";

// COMPONENTS
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Table,
  Typography,
} from 'antd'

import { DeleteFilled } from '@ant-design/icons'

import CurrencyFormat from "components/reuseable/currencyFormat/currencyFormatView"

import UploadFileCard from 'components/reuseable/uploadFile';
import type { UploadFile } from 'antd/es/upload/interface';

import {
  order_type,
  // status_order,
  payment_type,
  // payment_status,
  online_type,
} from 'constants/orders'

const { Text } = Typography

interface interfaceFormView {
  details: any,
  payload: any,
  payloadProcess?: any,
  isDisabledForm: boolean,
  type: string | undefined,
  fileList: UploadFile[],
  isLoading?: boolean,
  customer: any,
  mitra: any,
  customerLists: any[],
  mitraLists: any[],
  products: any[],
  productOrders: any[],
  orderCustomer: boolean,
  handleSubmit: (payload: any) => void,
  handlePayload: (payload: any) => void,
  handlePayloadProcess: (payload: any) => void,
  handleProducts: (payload: any, type: string) => void,
  handleProductQty: (index: string, value: number) => void,
  handleProductDelete: (payload: string) => void,
  handleFileList: (payload: UploadFile[]) => void,
  handleProcessOrder: () => void,
  handleSearchCustomer: (payload: string) => void,
  handleSearchProduct: (payload: string) => void,
  handleOrderFrom: (payload: boolean) => void,
}

function FormView({
  details,
  payload,
  payloadProcess,
  isDisabledForm,
  type,
  fileList,
  isLoading,
  customer,
  mitra,
  customerLists,
  mitraLists,
  products,
  productOrders,
  orderCustomer,
  handleSubmit,
  handlePayload,
  handlePayloadProcess,
  handleProducts,
  handleProductQty,
  handleProductDelete,
  handleFileList,
  handleProcessOrder,
  handleSearchCustomer,
  handleSearchProduct,
  handleOrderFrom,
}: interfaceFormView) {

  const columns = [
    {
      title: "Produk",
      dataIndex: "name",
      ellipsis: true,
    },
    {
      title: "unit",
      render: (data: any) => {
        if (data.product_id) return 'Pcs'
        return data.unit
      }
    },
    {
      title: "Harga",
      render: (data: any) => {
        return <div style={{ padding: 16}}>
          <div>
            <CurrencyFormat value={data.final_price}/>{` `}
            {data.is_discount && (
              <del style={{ color: '#8c8c8c' }}><CurrencyFormat value={data.price}/></del>
            )}
          </div>
        </div>
      }
    },
    {
      title: "qty",
      render: (data: any) => {
        return (
          <>
            {(type === 'new' || details.status === 'draft') ? (
              <InputNumber
                placeholder="Jumlah Bahan"
                type="number"
                onChange={(e) => handleProductQty(data.product_id, +e)}
                disabled={isDisabledForm}
                value={data.qty}
              />
            ) : (
              data.qty
            )}
          </>
        )
      }
    },
    {
      title: "Discount",
      render: (data: any) => {
        if (data.is_discount && data.is_discount_persentage)  {
          return `${data.discount} %`
        }
        if (data.is_discount)  {
          return <CurrencyFormat value={data.discount}/>
        }
        return '-'
      }
    },
    {
      title: "Total",
      render: (data: any) => {
        return <CurrencyFormat value={data.total_price}/>
      }
    },
    {
      title: "",
      width: '5px',
      render: (data: any) => {
        return (
          <>
            {(type === 'new' || details.status === 'draft') && (
              <Button
                type="link"
                danger
                onClick={() => handleProductDelete(data.product_id || data.materials_id)}
                icon={<DeleteFilled />}
                size="large"
              />
            )}
          </>
        )
      },
    },
  ];

  return (
    <>
      <div>
        <Card
          className="header-solid"
          bordered={false}
          title={[
            <React.Fragment key={0}>
              <Row gutter={[{ xs: 24, md: 24 }, 0]} justify="space-between">
                <Col>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 15,
                  }}>
                    <h3 className="mb-0">
                      Order 
                    </h3>
                    {type !== 'new' && (
                      <>
                        {details.status === 'draft' && <Text>Draft</Text>}
                        {details.status === 'waiting_payment' && <Text>Menunggu Pembayaran</Text>}
                        {details.status === 'process' && <Text>Di Proses</Text>}
                        {details.status === 'packing' && <Text>Packing</Text>}
                        {details.status === 'shipping' && <Text>Shipping</Text>}
                        {details.status === 'done' && <Text type="success" strong>Selesai</Text>}
                      </>
                    )}
                  </div>
                </Col>
                <Col>
                  {type !== 'new' && details.business_id && (
                    <Link to={`/${details.business_id}/order/invoice/${details.id}`} target="_blank">
                      <Button
                        type="primary"
                        className="px-25 btn-print"
                      >
                        Invoice
                      </Button>
                    </Link>
                  )}
                </Col>
              </Row>
            </React.Fragment>,
          ]}
        >
          <div className="mx-auto mt-24 mb-24 ml-24 ml-10 mr-10">
            <Form
              name="basic"
              layout="vertical"
              onFinish={handleSubmit}
              onFinishFailed={() => {}}
              fields={[
                {
                  name: ['customer_id'],
                  value: payload.customer_id
                },
                {
                  name: ['mitra_id'],
                  value: payload.mitra_id,
                },
                {
                  name: ['description'],
                  value: payload.description
                },
                {
                  name: ['order_type'],
                  value: payload.order_type
                },
                {
                  name: ['payment_type'],
                  value: payload.payment_type,
                },
                {
                  name: 'online_type',
                  value: payload.online_type,
                }
              ]}
            >
              {(type === 'new' || details.status === 'draft') && (
                <Col span={24}>
                  <Form.Item
                    label="Asal Order"
                    colon={false}
                  >
                    <Switch checked={orderCustomer} onChange={(e) => handleOrderFrom(!orderCustomer)}/>
                    {orderCustomer ? (
                      <span> Order dari pelanggan</span>
                    ) : (
                      <span> Order dari mitra / cabang bisnis</span>
                    )}
                  </Form.Item>
                </Col>
              )}
              {orderCustomer ? (
                <>
                  {(type === 'new' || details.status === 'draft') && (
                    <Row gutter={[{ xs: 24, md: 24 }, 0]}>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item
                          label="Pelanggan"
                          name="customer_id"
                          rules={[
                            { required: true, message: "Pilih pelanggan sebelum order!" },
                          ]}
                          colon={false}
                        >
                          <Select
                            showSearch
                            autoFocus
                            onSearch={(e) => handleSearchCustomer(e)}
                            placeholder="Cari pelanggan"
                            optionFilterProp="children"
                            filterOption={false}
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={customerLists.map(data => {
                              return { label: data.name, value: data.id }
                            })}
                            onChange={(e) => handlePayload({ customer_id: e})}
                            value={payload.customer_id}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </>
              ) : (
                <>
                  {(type === 'new' || details.status === 'draft') && (
                    <Row gutter={[{ xs: 24, md: 24 }, 0]}>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item
                          label="Mitra / Cabang Bisnis"
                          name="mitra_id"
                          rules={[
                            { required: true, message: "Pilih mitra / cabang bisnis sebelum order!" },
                          ]}
                          colon={false}
                        >
                          <Select
                            showSearch
                            autoFocus
                            onSearch={(e) => handleSearchCustomer(e)}
                            placeholder="Cari Mitra / Cabang Bisnis"
                            optionFilterProp="children"
                            filterOption={false}
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={mitraLists && mitraLists.map(data => {
                              return { label: data.name, value: data.business_id }
                            })}
                            onChange={(e) => handlePayload({ mitra_id: e})}
                            value={payload.mitra_id}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </>
              )}

              {/* ====================== INFO CUSTOMER ====================== */}
              {customer && (
                <Row gutter={[{ xs: 24, md: 24 }, 18]}>
                <Divider />
                  {/* ========== Nama Pelanggan ========== */}
                  <Col xs={24} sm={24} md={12}>
                    <Row>
                      <Col span={12}>
                        <strong>Nama Pelanggan</strong>
                      </Col>
                      <Col span={12}>
                        {customer.name}
                      </Col>
                    </Row>
                  </Col>
                  {/* ========== Phone NuMber ========== */}
                  <Col xs={24} sm={24} md={12}>
                    <Row>
                      <Col span={12}>
                        <strong>Nomor Handphone</strong>
                      </Col>
                      <Col span={12}>
                        {customer.phone}
                      </Col>
                    </Row>
                  </Col>
                  {/* ========== Email ========== */}
                  <Col xs={24} sm={24} md={12}>
                    <Row>
                      <Col span={12}>
                        <strong>Email</strong>
                      </Col>
                      <Col span={12}>
                        {customer.email}
                      </Col>
                    </Row>
                  </Col>
                  {/* ========== Social Media ========== */}
                  <Col xs={24} sm={24} md={12}>
                    <Row>
                      <Col span={12}>
                        <strong>Social Media</strong>
                      </Col>
                      <Col span={12}>
                        {customer.instagram}
                      </Col>
                    </Row>
                  </Col>
                  {/* ========== Province ========== */}
                  <Col xs={24} sm={24} md={12}>
                    <Row>
                      <Col span={12}>
                        <strong>Provinsi</strong>
                      </Col>
                      <Col span={12}>
                        {customer.province?.province_name}
                      </Col>
                    </Row>
                  </Col>
                  {/* ========== District ========== */}
                  <Col xs={24} sm={24} md={12}>
                    <Row>
                      <Col span={12}>
                        <strong>Kota/Kabupaten</strong>
                      </Col>
                      <Col span={12}>
                        {customer.city?.city_name}
                      </Col>
                    </Row>
                  </Col>
                  {/* ========== SubDistrict ========== */}
                  <Col xs={24} sm={24} md={12}>
                    <Row>
                      <Col span={12}>
                        <strong>Kecamatan</strong>
                      </Col>
                      <Col span={12}>
                        {customer.subdistrict?.subdistrict_name}
                      </Col>
                    </Row>
                  </Col>
                  {/* ========== Postal Code ========== */}
                  <Col xs={24} sm={24} md={12}>
                    <Row>
                      <Col span={12}>
                        <strong>Kode Pos</strong>
                      </Col>
                      <Col span={12}>
                        {customer.city?.postal_code}
                      </Col>
                    </Row>
                  </Col>
                  {/* ========== Alamat lengkap ========== */}
                  <Col xs={24} sm={24} md={12}>
                    <Row>
                      <Col span={12}>
                        <strong>Alamat Lengkap</strong>
                      </Col>
                      <Col span={12}>
                        {customer.address}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}

              {/* ====================== INFO MITRA ====================== */}
              {mitra && (
                <Row gutter={[{ xs: 24, md: 24 }, 18]}>
                <Divider />
                  {/* ========== Nama Pelanggan ========== */}
                  <Col xs={24} sm={24} md={12}>
                    <Row>
                      <Col span={12}>
                        <strong>Nama Mitra / Cabang Bisnis</strong>
                      </Col>
                      <Col span={12}>
                        {mitra.name}
                      </Col>
                    </Row>
                  </Col>
                  {/* ========== Tipe ========== */}
                  <Col xs={24} sm={24} md={12}>
                    <Row>
                      <Col span={12}>
                        <strong>Tipe</strong>
                      </Col>
                      <Col span={12}>
                        {mitra?.is_investor ? 'Mitra' : 'Cabang Bisnis'}
                      </Col>
                    </Row>
                  </Col>
                  {/* ========== Email ========== */}
                  <Col xs={24} sm={24} md={12}>
                    <Row>
                      <Col span={12}>
                        <strong>Jenis Industri</strong>
                      </Col>
                      <Col span={12}>
                        {mitra.type}
                      </Col>
                    </Row>
                  </Col>
                  {/* ========== Province ========== */}
                  {/* <Col xs={24} sm={24} md={12}>
                    <Row>
                      <Col span={12}>
                        <strong>Provinsi</strong>
                      </Col>
                      <Col span={12}>
                        {mitra.province?.province_name}
                      </Col>
                    </Row>
                  </Col> */}
                  {/* ========== District ========== */}
                  {/* <Col xs={24} sm={24} md={12}>
                    <Row>
                      <Col span={12}>
                        <strong>Kota/Kabupaten</strong>
                      </Col>
                      <Col span={12}>
                        {mitra.city?.city_name}
                      </Col>
                    </Row>
                  </Col> */}
                  {/* ========== SubDistrict ========== */}
                  {/* <Col xs={24} sm={24} md={12}>
                    <Row>
                      <Col span={12}>
                        <strong>Kecamatan</strong>
                      </Col>
                      <Col span={12}>
                        {mitra.subdistrict?.subdistrict_name}
                      </Col>
                    </Row>
                  </Col> */}
                  {/* ========== Postal Code ========== */}
                  {/* <Col xs={24} sm={24} md={12}>
                    <Row>
                      <Col span={12}>
                        <strong>Kode Pos</strong>
                      </Col>
                      <Col span={12}>
                        {mitra.postal_code}
                      </Col>
                    </Row>
                  </Col> */}
                  {/* ========== Alamat lengkap ========== */}
                  <Col xs={24} sm={24} md={12}>
                    <Row>
                      <Col span={12}>
                        <strong>Alamat Lengkap</strong>
                      </Col>
                      <Col span={12}>
                        {mitra.address}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}

              {/* ====================== PRODUK ====================== */}

              <Divider />
              <Row gutter={[{ xs: 24, md: 24 }, 0]}>
                <Col span={12}>
                </Col>
                <Col span={12}>
                  {(type === 'new' || details.status === 'draft') && (
                    <Form.Item>
                      <Select
                        showSearch
                        autoFocus
                        onSearch={(e) => handleSearchProduct(e)}
                        placeholder="Cari produk"
                        optionFilterProp="children"
                        filterOption={false}
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={products.map(data => {
                          return { label: data.product.name, value: JSON.stringify(data.product) }
                        })}
                        onChange={(e) => handleProducts(JSON.parse(e), 'product')}
                        value="Cari produk"
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col span={24}>
                  <Table
                    className="mt-20"
                    columns={columns}
                    dataSource={productOrders}
                    tableLayout="auto"
                    scroll={{ x: 500 }}
                    pagination={false}
                    summary={() => (
                      <Table.Summary>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} />
                          <Table.Summary.Cell index={1} />
                          <Table.Summary.Cell index={2} />
                          <Table.Summary.Cell index={3} />
                          <Table.Summary.Cell index={4}><strong>Total Products</strong></Table.Summary.Cell>
                          <Table.Summary.Cell index={5}>
                            <CurrencyFormat value={payload.grand_total - payload.packing_price - payload.shipping_price}/>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={6} />
                        </Table.Summary.Row>

                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} />
                          <Table.Summary.Cell index={1} />
                          <Table.Summary.Cell index={2} />
                          <Table.Summary.Cell index={3} />
                          <Table.Summary.Cell index={4}><strong>Packing</strong></Table.Summary.Cell>
                          <Table.Summary.Cell index={5}>
                            {(type === 'new' || details.status === 'draft') ? (
                              <InputNumber
                                placeholder="Biaya Packing"
                                type="number"
                                onChange={(e) => handlePayload({ packing_price: +e })}
                                disabled={isDisabledForm}
                                value={payload.packing_price}
                              />
                            ) : (
                              <CurrencyFormat value={payload.packing_price}/>
                            )}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={6} />
                        </Table.Summary.Row>

                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} />
                          <Table.Summary.Cell index={1} />
                          <Table.Summary.Cell index={2} />
                          <Table.Summary.Cell index={3} />
                          <Table.Summary.Cell index={4}><strong>Ongkir</strong></Table.Summary.Cell>
                          <Table.Summary.Cell index={5}>
                            {(type === 'new' || details.status === 'draft') ? (
                              <InputNumber
                                placeholder="Biaya Ongkir"
                                type="number"
                                onChange={(e) => handlePayload({ shipping_price: +e })}
                                disabled={isDisabledForm}
                                value={payload.shipping_price}
                              />
                            ) : (
                              <CurrencyFormat value={payload.shipping_price}/>
                            )}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={6} />
                        </Table.Summary.Row>

                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} />
                          <Table.Summary.Cell index={1} />
                          <Table.Summary.Cell index={2} />
                          <Table.Summary.Cell index={3} />
                          <Table.Summary.Cell index={4}><strong style={{ color: '#16753b' }}>Grand Total</strong></Table.Summary.Cell>
                          <Table.Summary.Cell index={5}><strong style={{ color: '#16753b' }}><CurrencyFormat value={payload.grand_total}/></strong></Table.Summary.Cell>
                          <Table.Summary.Cell index={6} />
                        </Table.Summary.Row>
                      </Table.Summary>
                    )}
                  />
                </Col>
              </Row>

              <Divider/>
              {(type === 'new' || details.status === 'draft') ? (
                ///////////////////////////////////////////////////////////////////////// NEW && DRAFT CONDITION
                <Row gutter={[{ xs: 24, md: 24 }, 0]}>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      label="Tipe Order"
                      name="order_type"
                      colon={false}
                    >
                      <Select
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={order_type}
                        onChange={(e) => handlePayload({ order_type: e})}
                        value={payload.order_type}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      label="Tipe Pembayaran"
                      name="payment_type"
                      colon={false}
                    >
                      <Select
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={payment_type}
                        onChange={(e) => handlePayload({ payment_type: e})}
                        value={payload.payment_type}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={24} />

                  {payload.order_type === 'online' && (
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        label="Asal Pemesanan"
                        name="online_type"
                        colon={false}
                      >
                        <Select
                          optionFilterProp="children"
                          filterOption={(input, option) => (option?.label ?? '').includes(input)}
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                          }
                          options={online_type}
                          onChange={(e) => handlePayload({ online_type: e})}
                          value={payload.online_type}
                        />
                      </Form.Item>
                    </Col>
                  )}

                  <Col xs={24} sm={24} md={24}>
                    <Form.Item
                      label="Catatan Order"
                      colon={false}
                      name="description"
                    >
                      <Input.TextArea
                        placeholder="Masukkan catatan order"
                        onChange={(e) => handlePayload({ description: e.target.value })}
                        disabled={isDisabledForm}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                ///////////////////////////////////////////////////////////////////////// PROCESS CONDITION
                <Row gutter={[{ xs: 24, md: 24 }, 18]}>
                  <Col xs={24} sm={24} md={12}>
                    <Row>
                      <Col span={12}>
                        <strong>Tipe Order</strong>
                      </Col>
                      <Col span={12} style={{ textTransform: 'capitalize' }}>
                        {details.order_type}
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <Row>
                      <Col span={12}>
                        <strong>Tipe Pembayaran</strong>
                      </Col>
                      <Col span={12} style={{ textTransform: 'capitalize' }}>
                        {details.payment_type}
                      </Col>
                    </Row>
                  </Col>
                  {details.order_type === 'online' && (
                    <Col xs={24} sm={24} md={12}>
                      <Row>
                        <Col span={12}>
                          <strong>Asal Pemesanan</strong>
                        </Col>
                        <Col span={12} style={{ textTransform: 'capitalize' }}>
                          {details.online_type.replace('_', ' ')}
                        </Col>
                      </Row>
                    </Col>
                  )}
                  <Col xs={24} sm={24} md={12}>
                    <Row>
                      <Col span={12}>
                        <strong>Catatan Order</strong>
                      </Col>
                      <Col span={12} style={{ textTransform: 'capitalize' }}>
                        {details.description || '-'}
                      </Col>
                    </Row>
                  </Col>
                  {(
                    details.status === 'done' ||
                    details.status === 'retur'
                  ) && (
                    <>
                      <Col xs={24} sm={24} md={12}>
                        <Row>
                          <Col span={12}>
                            <strong>Pengiriman</strong>
                          </Col>
                          <Col span={12} style={{ textTransform: 'capitalize' }}>
                            {details.shipping}
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <Row>
                          <Col span={12}>
                            <strong>No Resi</strong>
                          </Col>
                          <Col span={12} style={{ textTransform: 'capitalize' }}>
                            {details.no_resi}
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <Row>
                          <Col span={12}>
                            <strong>Biaya Pengiriman</strong>
                          </Col>
                          <Col span={12} style={{ textTransform: 'capitalize' }}>
                            <CurrencyFormat value={details.shipping_final_price} />
                          </Col>
                        </Row>
                      </Col>
                    </>
                  )}
                  <Col span={24}/>
                  {(
                    details.status === 'process' ||
                    details.status === 'packing' ||
                    details.status === 'shipping' ||
                    details.status === 'done' ||
                    details.status === 'retur'
                  ) && (
                    <Col xs={24} sm={24} md={12}>
                      <Row>
                        <Col span={12}>
                          <strong>Bukti Pembayaran</strong>
                        </Col>
                        <Col span={12} style={{ textTransform: 'capitalize' }}>
                          {details.image_payment && (
                            <UploadFileCard
                              fileList={[{ uid: details.image_payment, name: 'Bukti Pembayaran', url: details.image_payment }]}
                              handleFileList={handleFileList}
                              maxCount={1}
                              disabled
                            />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {(
                    details.status === 'shipping' ||
                    details.status === 'done' ||
                    details.status === 'retur'
                  ) && (
                    <Col xs={24} sm={24} md={12}>
                      <Row>
                        <Col span={12}>
                          <strong>Bukti Packing</strong>
                        </Col>
                        <Col span={12} style={{ textTransform: 'capitalize' }}>
                          {details.image_packing && (
                            <UploadFileCard
                              fileList={[{ uid: details.image_packing, name: 'Bukti Packing', url: details.image_packing }]}
                              handleFileList={handleFileList}
                              maxCount={1}
                              disabled
                            />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {(
                    details.status === 'done' ||
                    details.status === 'retur'
                  ) && (
                    <>
                      <Col xs={24} sm={24} md={12}>
                        <Row>
                          <Col span={12}>
                            <strong>Bukti Pengiriman</strong>
                          </Col>
                          <Col span={12} style={{ textTransform: 'capitalize' }}>
                            {details.image_shipping && (
                              <UploadFileCard
                                fileList={[{ uid: details.image_shipping, name: 'Bukti Biaya Pengiriman', url: details.image_shipping }]}
                                handleFileList={handleFileList}
                                maxCount={1}
                                disabled
                              />
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </>
                  )}
                </Row>
              )}

              {(type !== 'new' && details.status !== 'draft' && details.status !== 'done') && (
                <>
                  <Divider />
                  <Row gutter={[{ xs: 24, md: 24 }, 18]}>
                    {(details.status === 'waiting_payment' || details.status === 'packing' || details.status === 'shipping') && (
                      <Col span={24}>
                        <Form.Item
                          label={(
                            <>
                              {details.status === 'waiting_payment' && 'Bukti Pembayaran'}
                              {details.status === 'packing' && 'Bukti Packing'}
                              {details.status === 'shipping' && 'Bukti Biaya Pengiriman'}
                            </>
                          )}
                          colon={false}
                        >
                          <UploadFileCard
                            fileList={fileList}
                            handleFileList={handleFileList}
                            maxCount={1}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {(details.status === 'shipping') && (
                      <>
                        <Col span={12}>
                          <Form.Item label="Pengiriman">
                            <Input
                              value={payloadProcess?.shipping}
                              placeholder="Contoh JNE, JNT, Lion Parcel"
                              onChange={(e) => handlePayloadProcess({ shipping: e.target.value })}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="No Resi / Tracking No">
                            <Input
                              value={payloadProcess?.no_resi}
                              placeholder="No Resi / Tracking No"
                              onChange={(e) => handlePayloadProcess({ no_resi: e.target.value })}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="Biaya Pengiriman">
                            <Input
                              value={payloadProcess?.shipping_final_price}
                              placeholder="Biaya Pengiriman"
                              type="number"
                              onChange={(e) => handlePayloadProcess({ shipping_final_price: +e.target.value })}
                            />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </Row>
                </>
              )}

              <Row gutter={[{ xs: 24, md: 24 }, 18]}>
                {!isDisabledForm && (
                  <Col span={24} className="text-right" style={{ marginTop: 30 }}>
                    <Form.Item>
                      {(type === 'new' || details.status === 'draft') && (
                        <Button
                          type="primary"
                          className="px-25"
                          htmlType="submit"
                          disabled={isLoading}
                          loading={isLoading}
                        >
                          SIMPAN
                        </Button>
                      )}
                      {type !== 'new' && details.status !== 'done' && (
                        <Button
                          type={details.status === 'draft' ? 'default' : 'primary'}
                          className="px-25 ml-10"
                          disabled={isLoading}
                          loading={isLoading}
                          onClick={handleProcessOrder}
                        >
                          {details.status === 'draft' && 'LANJUT PEMBAYARAN'}
                          {details.status === 'waiting_payment' && 'PROSES ORDER'}
                          {details.status === 'process' && 'SIAP PACKING'}
                          {details.status === 'packing' && 'SIAP DIKIRIM'}
                          {details.status === 'shipping' && 'KIRIM'}
                        </Button>
                      )}
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Form>
          </div>
        </Card>
      </div>
    </>
  )
}
export default FormView