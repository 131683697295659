import { Card, Col, Row, Typography } from "antd";

interface typeCount {
    title: string;
    price: any;
    icon: any;
  }

interface interfaceCardTitle {
    count: typeCount[]
}
const CardTitle = ({
    count,
}: interfaceCardTitle) => {
  const { Title } = Typography;
  
  return (
    <div>
      <Row className="rowgap-vbox" gutter={[24, 0]}>
        {count.map((c:any, index:any) => (
          <Col
            key={index}
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="mb-24"
          >
            <Card bordered={false} className="criclebox">
              <div className="number">
                <Row align="middle" gutter={[24, 0]}>
                  <Col xs={18}>
                    <span>{c.title}</span>
                    <Title level={3}>
                      {c.price} <small className={c.bnb}>{c.persent}</small>
                    </Title>
                  </Col>
                  <Col xs={6}>
                    <div className="icon-box">{c.icon}</div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default CardTitle;
