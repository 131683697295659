import Cookies from "js-cookie"

export const setBranchId = (value: string) => Cookies.set('branchId', value)
export const getBranchId = () => Cookies.get('branchId')
export const removeBranch = () => Cookies.remove('branchId')
export const getBranchName = () => {
  const branchId = getBranchId()
  let branchName = branchId ? branchId.replace("-", " ") : "Branch Karsaraya"
  return branchName
}

export const setToken = (value: string) => Cookies.set(`${getBranchId}-token`, `Bearer ${value}`)
export const getToken = () => Cookies.get(`${getBranchId}-token`)
export const removeToken = () => Cookies.remove(`${getBranchId}-token`)

export const setMe = (value: string) => {
  const payload: string = JSON.stringify(value)
  Cookies.set(`${getBranchId}-me`, payload)
  if (getMeReload() !== true) {
    Cookies.set(`${getBranchId}-me-reload`, 'true')
    window.location.reload()
  }
}
export const getMeReload = () => {
  const payload: any = Cookies.get(`${getBranchId}-me-reload`)
  if (payload !== "undefined" && payload !== undefined) {
    return JSON.parse(payload)
  } else {
    return false
  }
}
export const getMe = () => {
  const payload: any = Cookies.get(`${getBranchId}-me`)
  if (payload !== "undefined" && payload !== undefined) {
    return JSON.parse(payload)
  } else {
    return {}
  }
}
export const removeMe = () => {
  Cookies.remove(`${getBranchId}-me`)
  Cookies.remove(`${getBranchId}-me-reload`)
}