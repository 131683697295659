import createReducer from 'utils/createReducer'
import { interfacePagination, interfaceSummary } from 'interface'

export const CUSTOMERS_CREATE = 'CUSTOMERS_CREATE'
export const CUSTOMERS_EDIT = 'CUSTOMERS_EDIT'
export const CUSTOMERS_REMOVE = 'CUSTOMERS_REMOVE'
export const CUSTOMERS_DETAILS = 'CUSTOMERS_DETAILS'
export const CUSTOMERS_LIST = 'CUSTOMERS_LIST'

export const CUSTOMERS_STATE = 'CUSTOMERS_STATE'

export interface interfaceDetail {
  id?: string,
  business_id?: string,
  name?: string,
  phone?: string,
  instagram?: string,
  province_id?: number,
  city_id?: number,
  subdistrict_id?: number,
  address?: string,
  balance?: number,
}

export interface interfaceAssets {
  details: interfaceDetail,
  data: interfaceDetail[],
  pagination: interfacePagination,
  summary : interfaceSummary,
  isLoading: boolean,
  isError: boolean,
  isModalConfirm: boolean,
  errorMessage: string,
}

export const INITIAL_STATE: interfaceAssets = {
  details: {},
  data: [],
  summary : {},
  pagination: {
    total: 0,
    page: 1,
    limit: 20,
  },
  isLoading: false,
  isError: false,
  isModalConfirm: false,
  errorMessage: '',
}

const reducer = createReducer(INITIAL_STATE, {

  [CUSTOMERS_CREATE]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [CUSTOMERS_EDIT]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [CUSTOMERS_REMOVE]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [CUSTOMERS_DETAILS]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [CUSTOMERS_LIST]: (state: any, payload: any) => ({
    ...state,
    isLoading: true,
    pagination: {
      ...state.pagination,
      page: payload?.page ? payload.page : state.pagination.page,
      limit: payload?.limit ? payload.limit : state.pagination.limit,
    }
  }),

  [CUSTOMERS_STATE]: (state: any, payload: any) => ({
    ...state,
    ...payload,
  })
})

export const customersCreate = (payload: any) => ({
  type: CUSTOMERS_CREATE,
  payload,
})
export const customersEdit = (payload: any) => ({
  type: CUSTOMERS_EDIT,
  payload,
})
export const customersRemove = (payload: any) => ({
  type: CUSTOMERS_REMOVE,
  payload,
})
export const customersDetails = (payload: any) => ({
  type: CUSTOMERS_DETAILS,
  payload,
})
export const customersList = (payload: any | undefined = null) => ({
  type: CUSTOMERS_LIST,
  payload,
})
export const customersState = (payload: any) => ({
  type: CUSTOMERS_STATE,
  payload,
})

export default reducer