import createReducer from 'utils/createReducer'
import { interfacePagination, interfaceSummary } from 'interface'

export const UNIT_CREATE = 'UNIT_CREATE'
export const UNIT_EDIT = 'UNIT_EDIT'
export const UNIT_REMOVE = 'UNIT_REMOVE'
export const UNIT_DETAILS = 'UNIT_DETAILS'
export const UNIT_LIST = 'UNIT_LIST'

export const UNIT_STATE = 'UNIT_STATE'

export interface interfaceDetail {
  id?: string,
  image?: string,
  unit?: string,
}

export interface interfaceAssets {
  details: interfaceDetail,
  data: interfaceDetail[],
  pagination: interfacePagination,
  summary : interfaceSummary,
  isLoading: boolean,
  isError: boolean,
  isModalConfirm: boolean,
  errorMessage: string,
}

export const INITIAL_STATE: interfaceAssets = {
  details: {},
  data: [],
  summary : {},
  pagination: {
    total: 0,
    page: 1,
    limit: 20,
  },
  isLoading: false,
  isError: false,
  isModalConfirm: false,
  errorMessage: '',
}

const reducer = createReducer(INITIAL_STATE, {

  [UNIT_CREATE]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [UNIT_EDIT]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [UNIT_REMOVE]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [UNIT_DETAILS]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [UNIT_LIST]: (state: any, payload: any) => ({
    ...state,
    isLoading: true,
    pagination: {
      ...state.pagination,
      page: payload?.page ? payload.page : state.pagination.page,
      limit: payload?.limit ? payload.limit : state.pagination.limit,
    }
  }),

  [UNIT_STATE]: (state: any, payload: any) => ({
    ...state,
    ...payload,
  })
})

export const unitCreate = (payload: any) => ({
  type: UNIT_CREATE,
  payload,
})
export const unitEdit = (payload: any) => ({
  type: UNIT_EDIT,
  payload,
})
export const unitRemove = (payload: any) => ({
  type: UNIT_REMOVE,
  payload,
})
export const unitDetails = (payload: any) => ({
  type: UNIT_DETAILS,
  payload,
})
export const unitList = (payload: any | undefined = null) => ({
  type: UNIT_LIST,
  payload,
})
export const unitState = (payload: any) => ({
  type: UNIT_STATE,
  payload,
})

export default reducer