import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'

import {
  CUSTOMERS_GET,
  CUSTOMERS_DELETE,
  CUSTOMERS_POST,
  CUSTOMERS_PUT,
} from 'config/endpoints'

import {
  CUSTOMERS_CREATE,
  CUSTOMERS_EDIT,
  CUSTOMERS_REMOVE,
  CUSTOMERS_DETAILS,
  CUSTOMERS_LIST,
  customersState,
  customersList,
} from 'reduxStore/ducks/customers/index'

import {
  notificationSet,
} from 'reduxStore/ducks/notifications'

import {
  redirect,
} from 'reduxStore/ducks/redirect'

export function customersListEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(CUSTOMERS_LIST),
    mergeMap(({ payload }) =>
      api({
        endpoint: CUSTOMERS_GET,
        query: {
          ...state$.value.Customers.pagination,
          ...payload,
        }
      }).pipe(
        mergeMap((response: any) => of(
          customersState({
            isLoading: false,
            data: response.data.data,
            pagination: response.data.pagination,
            summary : response.data.summary
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function customersDetailsEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(CUSTOMERS_DETAILS),
    mergeMap(({ payload }) =>
      api({
        endpoint: CUSTOMERS_GET,
        params: [payload]
      }).pipe(
        mergeMap((response: any) => of(
          customersState({
            isLoading: false,
            details: response.data,
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function customersEditsEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(CUSTOMERS_EDIT),
    mergeMap(({ payload }) =>
      api({
        endpoint: CUSTOMERS_PUT,
        params: [state$.value.Customers.details.id],
        body: payload,
      }).pipe(
        mergeMap((response: any) => of(
          customersState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Berhasil',
            message: `Asset ${payload.name} berhasil diperbaharui`,
            type: 'success',
          }),
          redirect('/customers')
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function customersCreateEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(CUSTOMERS_CREATE),
    mergeMap(({ payload }) =>
      api({
        endpoint: CUSTOMERS_POST,
        body: payload,
      }).pipe(
        mergeMap(() => of(
          customersState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Berhasil',
            message: 'Asset baru berhasil ditambahkan',
            type: 'success',
          }),
          redirect('/customers')
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function customersDeleteEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(CUSTOMERS_REMOVE),
    mergeMap(({ payload }) =>
      {
      return api({
        endpoint: CUSTOMERS_DELETE,
        params: [payload],
      }).pipe(
        mergeMap(() => of(
          customersList(),
          customersState({
            isLoading: false,
            isModalConfirm: false,
            details: {},
          }),
          notificationSet({
            title: 'Berhasil',
            message: 'Asset berhasil dihapus',
            type: 'success',
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        ))
      )
      }
    )
  )
}