/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from "../../../images/logo-ct-black.png";
import { getBranchName } from "utils/cookies";

const navLogo = (props) => {
  return (
    <>
      <div className=" brand">
        <img src={logo} alt="" />
        <span className="b-title" style={{ textTransform: 'capitalize' }}>{getBranchName()}</span>
      </div>
    </>
  );
};

export default navLogo;
