import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'

import {
  ASSETS_GET,
  ASSETS_DELETE,
  ASSETS_POST,
  ASSETS_PUT,
} from 'config/endpoints'

import {
  ASSETS_CREATE,
  ASSETS_EDIT,
  ASSETS_REMOVE,
  ASSETS_DETAILS,
  ASSETS_LIST,
  assetsState,
  assetsList,
} from 'reduxStore/ducks/assets/index'

import {
  notificationSet,
} from 'reduxStore/ducks/notifications'

import {
  redirect,
} from 'reduxStore/ducks/redirect'

export function assetsListEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(ASSETS_LIST),
    mergeMap(() =>
      api({
        endpoint: ASSETS_GET,
        query: {
          ...state$.value.Assets.pagination
        }
      }).pipe(
        mergeMap((response: any) => of(
          assetsState({
            isLoading: false,
            data: response.data.data,
            pagination: response.data.pagination,
            summary : response.data.summary
          })
        )),
        catchError((err) => of(
          assetsState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function assetsDetailsEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(ASSETS_DETAILS),
    mergeMap(({ payload }) =>
      api({
        endpoint: ASSETS_GET,
        params: [payload]
      }).pipe(
        mergeMap((response: any) => of(
          assetsState({
            isLoading: false,
            details: response.data,
          })
        )),
        catchError((err) => of(
          assetsState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function assetsEditsEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(ASSETS_EDIT),
    mergeMap(({ payload }) =>
      api({
        endpoint: ASSETS_PUT,
        params: [state$.value.Assets.details.id],
        body: payload,
      }).pipe(
        mergeMap((response: any) => of(
          assetsState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Berhasil',
            message: `Asset ${payload.name} berhasil diperbaharui`,
            type: 'success',
          }),
          redirect('/assets')
        )),
        catchError((err) => of(
          assetsState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function assetsCreateEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(ASSETS_CREATE),
    mergeMap(({ payload }) =>
      api({
        endpoint: ASSETS_POST,
        body: payload,
      }).pipe(
        mergeMap(() => of(
          assetsState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Berhasil',
            message: 'Asset baru berhasil ditambahkan',
            type: 'success',
          }),
          redirect('/assets')
        )),
        catchError((err) => of(
          assetsState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function assetsDeleteEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(ASSETS_REMOVE),
    mergeMap(({ payload }) =>
      {
      return api({
        endpoint: ASSETS_DELETE,
        params: [payload],
      }).pipe(
        mergeMap(() => of(
          assetsList(),
          assetsState({
            isLoading: false,
            isModalConfirm: false,
            details: {},
          }),
          notificationSet({
            title: 'Berhasil',
            message: 'Asset berhasil dihapus',
            type: 'success',
          })
        )),
        catchError((err) => of(
          assetsState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        ))
      )
      }
    )
  )
}