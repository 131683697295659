import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  uploadFile,
  deleteFile,
} from 'utils/uploadFile';

import {
  productsCreate,
  productsDetails,
  productsEdit,
  productsState,
} from 'reduxStore/ducks/products'

import {
  categoriesList,
  subCategoriesList,
} from 'reduxStore/ducks/masterData'
import { materialsList } from 'reduxStore/ducks/materials'
import { productsList } from 'reduxStore/ducks/products'

import AddView from './FormView'

import type { UploadFile } from 'antd/es/upload/interface';

const defaultPayloadProduct = {
  name:'',
  sku:'',
  category_id: null,
  sub_category_id: null,
  price: null,
  mitra_price: null,
  is_discount: false,
  discount: 0,
  is_discount_persentage: false,
  is_supplier: false,
  hpp: 0,
  image: '',
  length: null,
  width: null,
  height: null,
  weight: null,
  description: null,
}

const defaultPayloadStock = {
  price: null,
  discount: 0,
  is_discount: false,
  is_discount_persentage: false,
  hpp: 0,
}

function FormContainer() {
  const dispatch = useDispatch()
  const state = useSelector((state: any) => state.Products)
  const masterData = useSelector((state: any) => state.MasterData)
  const products = useSelector((state: any) => state.Products.data)
  const materials = useSelector((state: any) => state.Materials.data)
  
  const { details, isLoading }: any = state
  const { type, id } = useParams()

  const [payloadProduct, setPayloadProduct] = useState(defaultPayloadProduct)
  const [payloadStock, setPayloadStock] = useState(defaultPayloadStock)
  const [productMaterials, setProductMaterials] = useState<any[]>([])
  const [materialLists, setMaterialLists] = useState([])
  // const [productLists, setProductLists] = useState([])

  const [initialCategory, setInitialCategory] = useState('')
  const [initialSubCategory, setInitialSubCategory] = useState('')

  const [fileList, setFileList] = useState<UploadFile[]>([])

  useEffect(() => {
    dispatch(categoriesList())
    dispatch(materialsList())
    dispatch(productsList())
  }, [dispatch])

  useEffect(() => {
    let newMaterialLists: any = []
    materials.forEach((material: any) => {
      let isExist = false
      productMaterials.forEach((data: any) => {
        if (material.id === data.materials_id) {
          isExist = true
        }
      })
      if (!isExist) {
        newMaterialLists.push(material)
      }
    })
    setMaterialLists(newMaterialLists)
  }, [materials, productMaterials])

  useEffect(() => {
    if (type !== 'new' && id) {
      dispatch(productsDetails(id))
    }
  }, [dispatch, type, id])

  useEffect(() => {
    setPayloadStock({
      price: payloadProduct.price,
      discount: payloadProduct.discount,
      is_discount: payloadProduct.is_discount,
      is_discount_persentage: payloadProduct.is_discount_persentage,
      hpp: payloadProduct.hpp,
    })

    const getCategory = masterData.categories.filter((data: any) => data.id === payloadProduct.category_id)
    const getSubCategory = masterData.subCategories.filter((data: any) => data.id === payloadProduct.sub_category_id)
    if (getCategory.length > 0 ) {
      setInitialCategory(getCategory[0].initial)
    }
    if (getSubCategory.length > 0 ) {
      setInitialSubCategory(getSubCategory[0].initial)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payloadProduct])

  useEffect(() => {
    if (details.product.category_id) {
      dispatch(subCategoriesList(details.product.category_id))
    }
    if (type !== 'new' && id) {
      const str = details.product.sku
      setInitialCategory(str.substring(0, 2))
      setInitialSubCategory(str.substring(2, 5))
      setPayloadProduct({
        name: details.product.name,
        sku: str.substring(5, 100),
        category_id: details.product.category_id,
        sub_category_id: details.product.sub_category_id,
        price: details.product.price,
        mitra_price: details.product.mitra_price,
        is_discount: details.product.is_discount,
        discount: details.product.discount,
        is_discount_persentage: details.product.is_discount_persentage,
        is_supplier: details.product.is_supplier,
        hpp: details.product.hpp,
        image: details.product.image,
        length: details.product.length,
        width: details.product.width,
        height: details.product.height,
        weight: details.product.weight,
        description: details.product.description,
      })

      if (details.images) {
        const images = details.images.map((data: any) => {
          return {
            uid: data.id,
            name: data.id,
            status: 'done',
            url: data.image,
          }
        })
        setFileList(images)
      } else {
        setFileList([])
      }

      if (details.materials) {
        const materials = details.materials.map((data: any) => {
          return {
            name: data.materials_product_id ? data.material_product.name : data.material.name,
            unit: data.material.unit,
            qty: data.qty,
            materials_product_id: data.materials_product_id,
            materials_id: data.materials_id,
          }
        })
        setProductMaterials(materials)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details, type, id])

  const handleSubmit = async(values: any) => {
    dispatch(productsState({
      isLoading: true,
    }))
    await handleUploadImages()
      .then(images => {
        let valuePayloadProduct = values
        const payload = {
          product: {
            ...valuePayloadProduct,
            sku: `${initialCategory}${initialSubCategory}${valuePayloadProduct.sku}`
          },
          materials: productMaterials,
          images,
          stock: payloadStock,
        }
        if (type === 'new') {
          dispatch(productsCreate(payload))
        } else if (type === 'edit') {
          dispatch(productsEdit(payload))
        }
      })
  }
  
  const isDisabledForm = type === 'view'

  const handlePayloadProduct = (data: any) => {
    if (data.category_id) {
      dispatch(subCategoriesList(data.category_id))
    }
    setPayloadProduct({
      ...payloadProduct,
      ...data,
    })
  }

  const handleMaterials = (payload: any, type: string) => {
    let newProductMaterials = [...productMaterials]
    newProductMaterials.push({
      name: payload.name,
      unit: payload.unit,
      qty: 0,
      product_id: type === 'product' ? payload.id : undefined,
      materials_id: type === 'material' ? payload.id : undefined,
    })

    setProductMaterials(newProductMaterials)
  }

  const handleMaterialQty = (id: string, value: number) => {
    let newProductMaterials = [...productMaterials]
    newProductMaterials = newProductMaterials.map(data => {
      if (data.product_id === id || data.materials_id === id) {
        return {
          ...data,
          qty: value,
        }
      }
      return data
    })
    setProductMaterials(newProductMaterials)
  }

  const handleMaterialDelete = (id: string) => {
    let newProductMaterials: any = []
    productMaterials.forEach(data => {
      if (data.product_id !== id && data.materials_id !== id) {
        newProductMaterials.push(data)
      }
    })
    setProductMaterials(newProductMaterials)
  }

  const handleUploadImages = async() => {
    let images: any[] = []
    if (fileList.length > 0) {
      let index = 0
      for(const data of fileList) {
        if (data.url) {
          images.push(data.url)
        } else {
          await uploadFile(data.originFileObj, `${payloadProduct.name}-${index}`, 'products')
            .then(resultImage => {
              images.push(resultImage?.location)
            })
        }
        index++
      }
    }
    let deletedImage: any = []
    details.images.forEach((data: any) => {
      let isDeleted = true
      fileList.forEach((file) => {
        if (file.url === data.image) {
          isDeleted = false
        }
      })
      if (isDeleted) {
        deletedImage.push(data.image)
      }
    })
    deletedImage.forEach(async(data: string) => {
      await deleteFile(data)
    })
    return images
  }

  const handleSearchProduct = (keyword: string) => {
    dispatch(productsList({ keyword }))
  }

  const handleSearchMaterials = (keyword: string) => {
    dispatch(materialsList({ keyword }))
  }

  const props = {
    payloadProduct,
    isDisabledForm,
    type,
    fileList,
    isLoading,
    masterData,
    products,
    materialLists,
    productMaterials,
    initialSKU: `${initialCategory}${initialSubCategory}`,
    handleSubmit,
    handlePayloadProduct,
    handleMaterials,
    handleMaterialQty,
    handleMaterialDelete,
    handleFileList: setFileList,
    handleSearchProduct,
    handleSearchMaterials,
  }
  return <AddView {...props} />
}

export default FormContainer