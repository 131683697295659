import { Modal, Upload } from 'antd'

// ICONS
import { PlusOutlined } from '@ant-design/icons';

// TYPE / INTERFACE
import type { UploadFile } from 'antd/es/upload/interface';

interface interfaceUploadFileView {
  previewOpen: boolean,
  previewImage: string,
  previewTitle: string,
  fileList: UploadFile[],
  handlePreview: (file: UploadFile) => void,
  handleChange: any,
  handleCancel: () => void,
  maxCount?: number,
  disabled?: boolean,
}

function UploadFileView({
  previewOpen,
  previewImage,
  previewTitle,
  fileList,
  handlePreview,
  handleChange,
  handleCancel,
  maxCount = 1,
  disabled = false,
}: interfaceUploadFileView) {
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        maxCount={maxCount}
        disabled={disabled}
      >
        {uploadButton}
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  )
}

export default UploadFileView