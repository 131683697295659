import { useParams } from "react-router-dom"
import { setBranchId } from "utils/cookies"

import Redirect from "components/reuseable/redirectRedux"

function Middleware({
  children
}: any) {
  const { branchId }: any = useParams()
  setBranchId(branchId)
  return (
    <>
      <Redirect />
      {children}
    </> 
  )
}

export default Middleware