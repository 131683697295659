import createReducer from 'utils/createReducer'
import { interfacePagination, interfaceSummary } from 'interface'

import {
  product,
  image,
  stock,
  material,
} from './types'

export const PRODUCTS_CREATE = 'PRODUCTS_CREATE'
export const PRODUCTS_EDIT = 'PRODUCTS_EDIT'
export const PRODUCTS_REMOVE = 'PRODUCTS_REMOVE'
export const PRODUCTS_DETAILS = 'PRODUCTS_DETAILS'
export const PRODUCTS_LIST = 'PRODUCTS_LIST'

export const PRODUCTS_STATE = 'PRODUCTS_STATE'

export interface interfaceDetail {
  product?: product,
  images?: image[],
  stock?: stock,
  materials?: material[],
}

export interface interfaceProducts {
  details: interfaceDetail,
  data: any[],
  pagination: interfacePagination,
  summary : interfaceSummary,
  isLoading: boolean,
  isError: boolean,
  isModalConfirm: boolean,
  errorMessage: string,
}

export const INITIAL_STATE: interfaceProducts = {
  details: {
    product: {
      name:'',
      sku:'',
      category_id: null,
      sub_category_id: null,
      price: null,
      mitra_price: null,
      is_discount: false,
      discount: 0,
      is_discount_persentage: false,
      is_supplier: false,
      hpp: 0,
      image: '',
      length: null,
      width: null,
      height: null,
      weight: null,
      description: null,
    },
    stock: {
      price: null,
      discount: 0,
      is_discount: false,
      is_discount_persentage: false,
      hpp: 0,
    },
    images: [],
    materials: [],
  },
  data: [],
  summary : {},
  pagination: {
    total: 0,
    page: 1,
    limit: 20,
  },
  isLoading: false,
  isError: false,
  isModalConfirm: false,
  errorMessage: '',
}

const reducer = createReducer(INITIAL_STATE, {

  [PRODUCTS_CREATE]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [PRODUCTS_EDIT]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [PRODUCTS_REMOVE]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [PRODUCTS_DETAILS]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [PRODUCTS_LIST]: (state: any, payload: any) => ({
    ...state,
    isLoading: true,
    pagination: {
      ...state.pagination,
      page: payload?.page ? payload.page : state.pagination.page,
      limit: payload?.limit ? payload.limit : state.pagination.limit,
    }
  }),

  [PRODUCTS_STATE]: (state: any, payload: any) => ({
    ...state,
    ...payload,
  })
})

export const productsCreate = (payload: any) => ({
  type: PRODUCTS_CREATE,
  payload,
})
export const productsEdit = (payload: any) => ({
  type: PRODUCTS_EDIT,
  payload,
})
export const productsRemove = (payload: any) => ({
  type: PRODUCTS_REMOVE,
  payload,
})
export const productsDetails = (payload: any) => ({
  type: PRODUCTS_DETAILS,
  payload,
})
export const productsList = (payload: any | undefined = null) => ({
  type: PRODUCTS_LIST,
  payload,
})
export const productsState = (payload: any) => ({
  type: PRODUCTS_STATE,
  payload,
})

export default reducer