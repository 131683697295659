import createReducer from 'utils/createReducer'

export const COMPANY_SUMMARY = 'COMPANY_SUMMARY'
export const COMPANY_STATE = 'COMPANY_STATE'

export interface interfaceAssets {
  data: any,
  isLoading: boolean,
  isError: boolean,
  isModalConfirm: boolean,
  errorMessage: string,
}

export const INITIAL_STATE: interfaceAssets = {
  data: {},
  isLoading: false,
  isError: false,
  isModalConfirm: false,
  errorMessage: '',
}

const reducer = createReducer(INITIAL_STATE, {
  [COMPANY_STATE]: (state: any, payload: any) => ({
    ...state,
    ...payload,
  })
})

export const companySummary = (payload: any | undefined = null) => ({
  type: COMPANY_SUMMARY,
  payload,
})
export const companyState = (payload: any) => ({
  type: COMPANY_STATE,
  payload,
})

export default reducer