import createReducer from 'utils/createReducer'

// LOGIN SUBMIT
export const REDIRECT_SET = 'REDIRECT_SET'

export interface Redirect {
  to: string | null,
}

export const INITIAL_STATE: Redirect = {
  to: null,
}

const reducer = createReducer(INITIAL_STATE, {
  [REDIRECT_SET]: (state: any, payload: string) => ({
    ...state,
    to: payload,
  }),
})

export const redirect = (payload: string | null) => ({
  type: REDIRECT_SET,
  payload,
})

export default reducer