import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'

import {
  MATERIALS_GET,
  MATERIALS_DELETE,
  MATERIALS_POST,
  MATERIALS_PUT,
} from 'config/endpoints'

import {
  MATERIALS_CREATE,
  MATERIALS_EDIT,
  MATERIALS_REMOVE,
  MATERIALS_DETAILS,
  MATERIALS_LIST,
  materialsState,
  materialsList,
} from 'reduxStore/ducks/materials/index'

import {
  notificationSet,
} from 'reduxStore/ducks/notifications'

import {
  redirect,
} from 'reduxStore/ducks/redirect'

const title = 'Bahan Baku'

export function materialsListEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(MATERIALS_LIST),
    mergeMap(({ payload }) =>
      api({
        endpoint: MATERIALS_GET,
        query: {
          ...state$.value.Materials.pagination,
          ...payload,
        }
      }).pipe(
        mergeMap((response: any) => of(
          materialsState({
            isLoading: false,
            data: response.data.data,
            pagination: response.data.pagination,
            summary : response.data.summary
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function materialsDetailsEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(MATERIALS_DETAILS),
    mergeMap(({ payload }) =>
      api({
        endpoint: MATERIALS_GET,
        params: [payload]
      }).pipe(
        mergeMap((response: any) => of(
          materialsState({
            isLoading: false,
            details: response.data,
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function materialsEditsEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(MATERIALS_EDIT),
    mergeMap(({ payload }) =>
      api({
        endpoint: MATERIALS_PUT,
        params: [state$.value.Materials.details.id],
        body: payload,
      }).pipe(
        mergeMap((response: any) => of(
          materialsState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Berhasil',
            message: `${title} ${payload.name} berhasil diperbaharui`,
            type: 'success',
          }),
          redirect('/materials')
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function materialsCreateEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(MATERIALS_CREATE),
    mergeMap(({ payload }) =>
      api({
        endpoint: MATERIALS_POST,
        body: payload,
      }).pipe(
        mergeMap(() => of(
          materialsState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Berhasil',
            message: `${title} baru berhasil ditambahkan`,
            type: 'success',
          }),
          redirect('/materials')
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function materialsDeleteEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(MATERIALS_REMOVE),
    mergeMap(({ payload }) =>
      {
      return api({
        endpoint: MATERIALS_DELETE,
        params: [payload],
      }).pipe(
        mergeMap(() => of(
          materialsList(),
          materialsState({
            isLoading: false,
            isModalConfirm: false,
            details: {},
          }),
          notificationSet({
            title: 'Berhasil',
            message: `${title} berhasil dihapus`,
            type: 'success',
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        ))
      )
      }
    )
  )
}