/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from "react";
import { Layout, Row, Col } from "antd";
const { Footer } = Layout;
export default class FooterNEw extends Component {
  render() {
    return (
      <>
        <Footer style={{ background: "#fafafa" }}>
          <Row className="just">
            <Col xs={24} md={12} lg={12}>
              <div className="copyright">
                © 2024, by Karplanter
              </div>
            </Col>
          </Row>
        </Footer>
      </>
    );
  }
}
