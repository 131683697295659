import createReducer from 'utils/createReducer'
import { interfacePagination,interfaceSummary } from 'interface'

export const MATERIALS_CREATE = 'MATERIALS_CREATE'
export const MATERIALS_EDIT = 'MATERIALS_EDIT'
export const MATERIALS_REMOVE = 'MATERIALS_REMOVE'
export const MATERIALS_DETAILS = 'MATERIALS_DETAILS'
export const MATERIALS_LIST = 'MATERIALS_LIST'

export const MATERIALS_STATE = 'MATERIALS_STATE'

export interface interfaceDetail {
  name?: string,
  id?: string,
  image?: string,
  business_id?: string,
  qty?: number,
  price?: number,
  total_price?: number,
}

export interface interfaceAssets {
  details: interfaceDetail,
  data: interfaceDetail[],
  summary : interfaceSummary,
  pagination: interfacePagination,
  isLoading: boolean,
  isError: boolean,
  isModalConfirm: boolean,
  errorMessage: string,
}

export const INITIAL_STATE: interfaceAssets = {
  details: {},
  data: [],
  summary : {},
  pagination: {
    total: 0,
    page: 1,
    limit: 20,
  },
  isLoading: false,
  isError: false,
  isModalConfirm: false,
  errorMessage: '',
}

const reducer = createReducer(INITIAL_STATE, {

  [MATERIALS_CREATE]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [MATERIALS_EDIT]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [MATERIALS_REMOVE]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [MATERIALS_DETAILS]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [MATERIALS_LIST]: (state: any, payload: any) => ({
    ...state,
    isLoading: true,
    pagination: {
      ...state.pagination,
      page: payload?.page ? payload.page : state.pagination.page,
      limit: payload?.limit ? payload.limit : state.pagination.limit,
    }
  }),

  [MATERIALS_STATE]: (state: any, payload: any) => ({
    ...state,
    ...payload,
  })
})

export const materialsCreate = (payload: any) => ({
  type: MATERIALS_CREATE,
  payload,
})
export const materialsEdit = (payload: any) => ({
  type: MATERIALS_EDIT,
  payload,
})
export const materialsRemove = (payload: any) => ({
  type: MATERIALS_REMOVE,
  payload,
})
export const materialsDetails = (payload: any) => ({
  type: MATERIALS_DETAILS,
  payload,
})
export const materialsList = (payload: any | undefined = null) => ({
  type: MATERIALS_LIST,
  payload,
})
export const materialsState = (payload: any) => ({
  type: MATERIALS_STATE,
  payload,
})

export default reducer