// CORE
import React from 'react'

// COMPONENTS
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
} from 'antd'

// import UploadFileCard from 'components/reuseable/uploadFile';

import type { UploadFile } from 'antd/es/upload/interface';

interface interfaceFormView {
  payload: any,
  fileList: UploadFile[],
  isLoading?: boolean,
  handleFileList: (payload: UploadFile[]) => void,
  handleSubmit: (payload: any) => void,
  handlePayload: (payload: any) => void,
}

function FormView({
  payload,
  fileList,
  isLoading,
  handleFileList,
  handleSubmit,
  handlePayload,
}: interfaceFormView) {

  return (
    <>
      <div>
        <Card
          className="header-solid"
          bordered={false}
          title={[
            <React.Fragment key={0}>
              <h3 className="mb-0">
                
              </h3>
            </React.Fragment>,
          ]}
        >
          <div className="mx-auto mt-24 mb-24" style={{ maxWidth: "800px" }}>
            <Form
              name="basic"
              layout="vertical"
              onFinish={handleSubmit}
              onFinishFailed={() => {}}
              fields={[
                {
                  name: ['name'],
                  value: payload.name
                },
                {
                  name: ['email'],
                  value: payload.email
                },
              ]}
            >
              <Row gutter={32}>
                {/* <Col span={24}>
                  <Form.Item
                    label="Foto Profile"
                  >
                    <UploadFileCard fileList={fileList} handleFileList={handleFileList} maxCount={1}/>
                  </Form.Item>
                </Col> */}
                <Col span={12}>
                  <Form.Item
                    label="Nama"
                    name="name"
                    rules={[
                      { required: true, message: "Nama assets harus diisi!" },
                    ]}
                    colon={false}
                  >
                    <Input
                      placeholder="Nama Lengkap"
                      onChange={(e) => handlePayload({ name: e.target.value })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      { required: true, message: "Email harus diisi!" },
                    ]}
                    colon={false}
                  >
                    <Input
                      placeholder="Email"
                      onChange={(e) => handlePayload({ name: e.target.value })}
                      disabled
                    />
                  </Form.Item>
                </Col>

                <Divider />
                <Col span={12}>
                  <Form.Item
                    label="Ganti Password"
                    name="password"
                    colon={false}
                  >
                    <Input.Password
                      placeholder="Ganti Password"
                      onChange={(e) => handlePayload({ password: e.target.value })}
                    />
                  </Form.Item>
                </Col>
                
                <Col span={24} className="text-right">
                  <Form.Item>
                    <Button
                      type="primary"
                      className="px-25"
                      htmlType="submit"
                      disabled={isLoading}
                      loading={isLoading}
                    >
                      SIMPAN
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Card>
      </div>
    </>
  )
}
export default FormView