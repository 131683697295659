import { Row, Col, Table, Card, Select, Input } from "antd"
import { SearchOutlined,DollarOutlined } from "@ant-design/icons"

import { interfacePagination } from 'interface'
import { product } from "reduxStore/ducks/products/types"

import CardTitle from "components/reuseable/cardTitle"

const { Option } = Select;

const suffix = (
  <SearchOutlined
    style={{
      fontSize: 16,
      color: "#1890ff",
    }}
  />
);

interface interfaceListView {
  data: product[],
  pagination: interfacePagination,
  handlePagination: (payload: any) => void,
}

function ListView({
  data,
  pagination,
  handlePagination,
}: interfaceListView) {

  const columns = [
    {
      title: "Image",
      ellipsis: true,
      width: '125px',
      render: (data: any) => {
        if (data.image !== null && data.image !== '' && data.image !== undefined) {
          return <img src={data.image} alt={data.name} style={{ width: 75, height: 75 }} />
        } else {
          return ""
        }
      }
    },
    {
      title: "Produk",
      render: (data: any) => {
        console.log('data.production_product.name', data.production_product.name)
        return data.production_product.name
      }
    },
    {
      title: "Jumlah Produksi",
      dataIndex: "qty",
      ellipsis: true,
      align: "center" as "center",
    },
    {
      title: "Berhasil",
      dataIndex: "success_qty",
      ellipsis: true,
      align: "center" as "center",
    },
    {
      title: "Gagal",
      dataIndex: "failed_qty",
      ellipsis: true,
      align: "center" as "center",
    },
  ];

  const count = [
    {
      title: "Jumlah Produksi",
      price: pagination.total,
      icon: <DollarOutlined
      style={{
        fontSize: 25,
        color: "white",
      }}
    />,
    },
  ];

  return (
    <>
      <CardTitle count={count} />
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Card
            bodyStyle={{ padding: "16px 0px 0px" }}
            bordered={false}
            className="header-solid mb-24"
            title={[<h5 className="font-semibold">Daftar Produksi</h5>]}
          >
            <div className="px-25">
              <Row gutter={[24, 0]} className="mb-24">
                <Col span={24} lg={12}>
                  <Select
                    value={pagination.limit}
                    // onChange={this.handleTotalPageChange1}
                    size="large"
                  >
                    <Option value="5">5</Option>
                    <Option value="10">10</Option>
                    <Option value="15">15</Option>
                    <Option value="20">20</Option>
                    <Option value="25">25</Option>
                  </Select>

                  <label className="ml-10">entries per page</label>
                </Col>
                <Col span={24} lg={12} className="text-right">
                  <Input
                    style={{ maxWidth: "200px", borderRadius: "8px" }}
                    placeholder="input search text"
                    size="small"
                    suffix={suffix}
                    // onChange={this.handleFilter} 
                  />
                </Col>
              </Row>

              <Table
                className="mt-20"
                columns={columns}
                dataSource={data}
                tableLayout="auto"
                onChange={(e) => handlePagination(e)}
                scroll={{ x: 500 }}
                pagination={{
                  total: pagination.total,
                  pageSize: pagination.limit,
                  current: pagination.page,
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ListView