// CORE
import React from 'react'

// COMPONENTS
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
} from 'antd'

import UploadFileCard from 'components/reuseable/uploadFile';

import type { UploadFile } from 'antd/es/upload/interface';
import type { product } from 'reduxStore/ducks/products/types'

interface interfaceFormView {
  payload: any,
  fileList: UploadFile[],
  isLoading: boolean,
  listProducts: product[],
  handlePayload: (payload: any) => void,
  handleSubmit: (payload: any) => void,
  handleFileList: (payload: UploadFile[]) => void,
  handleSearchProduct: (payload: string) => void,
}

function FormView({
  payload,
  fileList,
  isLoading,
  listProducts,
  handlePayload,
  handleSubmit,
  handleFileList,
  handleSearchProduct,
}: interfaceFormView) {

  return (
    <>
      <div>
        <Card
          className="header-solid"
          bordered={false}
          title={[
            <React.Fragment key={0}>
              <h3 className="mb-0">
                Produksi Produk
              </h3>
            </React.Fragment>,
          ]}
        >
          <div className="mx-auto mt-24 mb-24" style={{ maxWidth: "800px" }}>
            <Form
              name="basic"
              layout="vertical"
              onFinish={handleSubmit}
              onFinishFailed={() => {}}
              fields={[
                {
                  name: ['name'],
                  value: payload.name
                },
                {
                  name: ['success_qty'],
                  value: payload.success_qty
                },
                {
                  name: ['failed_qty'],
                  value: payload.failed_qty
                },
                {
                  name: ['description'],
                  value: payload.description,
                },
              ]}
            >
              <Row gutter={32}>
                <Col span={12}>
                  <Form.Item
                    label="Pilih produk untuk diproduksi"
                    name="product_id"
                    rules={[
                      { required: true, message: "Pilih produk yang ingin diproduksi!" },
                    ]}
                    colon={false}
                  >
                    <Select
                      showSearch
                      autoFocus
                      onSearch={(e) => handleSearchProduct(e)}
                      placeholder="Cari Produk"
                      optionFilterProp="children"
                      filterOption={false}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={listProducts.map(data => {
                        return { label: data.name, value: data.id }
                      })}
                      onChange={(e) => handlePayload({ product_id: e })}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={12}>
                  <Form.Item
                    label="Jumlah Berhasil Produksi"
                    name="success_qty"
                    rules={[
                      { required: true, message: "Jumlah berhasil produksi harus diisi!" },
                    ]}
                    colon={false}
                  >
                    <Input
                      placeholder="Jumlah Berhasil Produksi"
                      type="number"
                      onChange={(e) => handlePayload({ success_qty: +e.target.value })}
                    />
                  </Form.Item>
                </Col>
                
                <Col span={12}>
                  <Form.Item
                    label="Jumlah Gagal Produksi"
                    name="failed_qty"
                    rules={[
                      { required: true, message: "Jumlah gagal produksi harus diisi!" },
                    ]}
                    colon={false}
                  >
                    <Input
                      placeholder="Jumlah Gagal Produksi"
                      type="number"
                      onChange={(e) => handlePayload({ failed_qty: +e.target.value })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <UploadFileCard fileList={fileList} handleFileList={handleFileList} />
                </Col>

                <Divider /> 

                {/* ====================== DESCRIPTION ====================== */}

                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    label="Deskripsi Produksi"
                    colon={false}
                    name="description"
                  >
                    <Input.TextArea
                      placeholder="Masukkan deskripsi produksi"
                      onChange={(e) => handlePayload({ description: e.target.value })}
                    />
                  </Form.Item>
                </Col>

                <Col span={24} className="text-right">
                  <Form.Item>
                    <Button
                      type="primary"
                      className="px-25"
                      htmlType="submit"
                      disabled={isLoading}
                      loading={isLoading}
                    >
                      SIMPAN
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Card>
      </div>
    </>
  )
}
export default FormView