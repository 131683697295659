import {
  createBrowserRouter,
} from 'react-router-dom';

import Main from 'components/layout/main'

import SignIn from 'pages/auth/signIn'
import Login from 'pages/auth/login'
import Dashboard from 'pages/dashboard'


// Account
import AccountEdit from 'pages/account/form'

// Assets
import AssetsNew from 'pages/assets/form'
import AssetsList from 'pages/assets/list'

// Materials
import MaterialsForm from 'pages/materials/form'
import MaterialsList from 'pages/materials/list'

// Log Activity
import LogActivityList from 'pages/logActivity/list'

// Cash Flow
import CashFlowList from 'pages/cashFlow/list'
import CashFlowForm from 'pages/cashFlow/form'

// Middleware
import Authentication from './Authentication'
import Middleware from './Middleware';

// Customer
import CustomerList from 'pages/customers/list'
import CustomerForm from 'pages/customers/form'

// Employee
import EmployeeList from 'pages/employees/list'
import EmployeeForm from 'pages/employees/form'

// Products
import ProductsList from 'pages/products/list'
import ProductsForm from 'pages/products/form'

// Productions
import ProductionsList from 'pages/productions/list'
import ProductionsForm from 'pages/productions/form'

// Orders
import OrdersList from 'pages/orders/list'
import OrdersForm from 'pages/orders/form'
import OrdersInvoice from 'pages/orders/invoice'


// Master Data
import CategoryList from 'pages/masterData/category/list'
import CategoryForm from 'pages/masterData/category/form'
import SubCategoryList from 'pages/masterData/subCategory/list'
import SubCategoryForm from 'pages/masterData/subCategory/form'
import UnitList from 'pages/masterData/unit/list'
import UnitForm from 'pages/masterData/unit/form'
const router = [
  {
    path: '/',
    element: Dashboard,
    layout: Main,
    authentication: true,
  },
  {
    path: '/login',
    element: Login,
  },
  {
    path: '/sign',
    element: SignIn,
  },
  ///////////// ============================= ACCOUNT
  {
    path: '/account',
    element: AccountEdit,
    layout: Main,
    authentication: true,
  },
  ///////////// ============================= ASSETS
  {
    path: '/assets',
    element: AssetsList,
    layout: Main,
    authentication: true,
  },
  {
    path: '/asset/:type',
    element: AssetsNew,
    layout: Main,
    authentication: true,
  },
  {
    path: '/asset/:type/:id',
    element: AssetsNew,
    layout: Main,
    authentication: true,
  },
  ///////////// ============================= MATERIALS
  {
    path: '/materials',
    element: MaterialsList,
    layout: Main,
    authentication: true,
  },
  {
    path: '/material/:type',
    element: MaterialsForm,
    layout: Main,
    authentication: true,
  },
  {
    path: '/material/:type/:id',
    element: MaterialsForm,
    layout: Main,
    authentication: true,
  },
  ///////////// ============================= ACTIVITY
  {
    path: '/activities',
    element: LogActivityList,
    layout: Main,
    authentication: true,
  },
  ///////////// ============================= CASHFLOW
  {
    path: '/cashflows',
    element: CashFlowList,
    layout: Main,
    authentication: true,
  },
  {
    path: '/cashflow/:cashflow/list',
    element: CashFlowList,
    layout: Main,
    authentication: true,
  },
  {
    path: '/cashflow/:cashflow/:type',
    element: CashFlowForm,
    layout: Main,
    authentication: true,
  },
  //////// =========== CUSTOMER
  {
    path: '/customers',
    element: CustomerList,
    layout: Main,
    authentication: true,
  },
  {
    path: '/customer/:type',
    element: CustomerForm,
    layout: Main,
    authentication: true,
  },
  {
    path: '/customer/:type/:id',
    element: CustomerForm,
    layout: Main,
    authentication: true,
  },
  //////// =========== EMPLOYEES
  {
    path: '/employees',
    element: EmployeeList,
    layout: Main,
    authentication: true,
  },
  {
    path: '/employee/:type',
    element: EmployeeForm,
    layout: Main,
    authentication: true,
  },
  {
    path: '/employee/:type/:id',
    element: EmployeeForm,
    layout: Main,
    authentication: true,
  },
  //////// =========== PRODUCTS
  {
    path: '/products',
    element: ProductsList,
    layout: Main,
    authentication: true,
  },
  {
    path: '/product/:type',
    element: ProductsForm,
    layout: Main,
    authentication: true,
  },
  {
    path: '/product/:type/:id',
    element: ProductsForm,
    layout: Main,
    authentication: true,
  },
  //////// =========== PRODUCTIONS
  {
    path: '/productions',
    element: ProductionsList,
    layout: Main,
    authentication: true,
  },
  {
    path: '/production/:type',
    element: ProductionsForm,
    layout: Main,
    authentication: true,
  },
  //////// =========== ORDERS
  {
    path: '/orders',
    element: OrdersList,
    layout: Main,
    authentication: true,
  },
  {
    path: '/order/:type',
    element: OrdersForm,
    layout: Main,
    authentication: true,
  },
  {
    path: '/order/:type/:id',
    element: OrdersForm,
    layout: Main,
    authentication: true,
  },
  {
    path: '/order/invoice/:id',
    element: OrdersInvoice,
    authentication: true,
  },

  ///////////// ============================= MASTER DATA - CATEGORY
  {
    path: '/categories',
    element: CategoryList,
    layout: Main,
    authentication: true,
  },
  {
    path: '/category/:type',
    element: CategoryForm,
    layout: Main,
    authentication: true,
  },
  {
    path: '/category/:type/:id',
    element: CategoryForm,
    layout: Main,
    authentication: true,
  },
  ///////////// ============================= MASTER DATA - SUB CATEGORY
  {
    path: '/sub-categories',
    element: SubCategoryList,
    layout: Main,
    authentication: true,
  },
  {
    path: '/sub-category/:type',
    element: SubCategoryForm,
    layout: Main,
    authentication: true,
  },
  {
    path: '/sub-category/:type/:id',
    element: SubCategoryForm,
    layout: Main,
    authentication: true,
  },
  ///////////// ============================= MASTER DATA - UNIT
  {
    path: '/units',
    element: UnitList,
    layout: Main,
    authentication: true,
  },
  {
    path: '/unit/:type',
    element: UnitForm,
    layout: Main,
    authentication: true,
  },
  {
    path: '/unit/:type/:id',
    element: UnitForm,
    layout: Main,
    authentication: true,
  },
];

let routerList: any = [];


router.map((item: any, index) => {
  let currentRouter: any = {
    path: `/:branchId${item.path}`,
    element: <Middleware key={index}><item.element /></Middleware>
  }
  if (item.layout) {
    currentRouter = {
      ...currentRouter,
      element: <item.layout>{currentRouter.element}</item.layout>
    }
  }
  if (item.authentication) {
    currentRouter = {
      ...currentRouter,
      element: <Authentication>{currentRouter.element}</Authentication>
    }
  }
  return routerList.push(currentRouter)
})

const handleRouter = createBrowserRouter(routerList);

export default handleRouter