import createReducer from 'utils/createReducer'

export const AUTH_STATE = 'AUTH_STATE'
// LOGIN SUBMIT
export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_FAILED = 'AUTH_FAILED'

// GET DETAIL USER BY TOKEN
export const AUTH_DETAIL = 'AUTH_DETAIL'
export const AUTH_DETAIL_SUCCESS = 'AUTH_DETAIL_SUCCESS'

// LOGOUT
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

// UPDATE
export const AUTH_UPDATE = 'AUTH_UPDATE'

export interface interfaceAuthDetail {
  name?: string,
  id?: string,
  image?: string,
}

export interface interfacePokemon {
  authDetail: interfaceAuthDetail,
  isLoading: boolean,
  isError: boolean,
  errorMessage: string,
}

export const INITIAL_STATE: interfacePokemon = {
  authDetail: {
    name: "",
    id: "",
    image: "",
  },
  isLoading: false,
  isError: false,
  errorMessage: '',
}

const reducer = createReducer(INITIAL_STATE, {

  // LOGIN SUBMIT
  [AUTH_LOGIN]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [AUTH_FAILED]: (state: any, payload: string) => ({
    ...state,
    isLoading: false,
    isError: true,
    errorMessage: payload,
  }),

  // GET DETAIL USER BY TOKEN
  [AUTH_DETAIL]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [AUTH_DETAIL_SUCCESS]: (state: any, payload: interfaceAuthDetail) => ({
    ...state,
    isLoading: false,
    isError: false,
    authDetail: payload,
  }),

  // LOGOUT
  [AUTH_LOGOUT]: (state: any) => ({
    ...INITIAL_STATE,
  }),

  [AUTH_STATE]: (state: any, payload: any) => ({
    ...state,
    ...payload,
  })
})

export const authState = (payload: any) => ({
  type: AUTH_STATE,
  payload,
})
// LOGIN SUBMIT
export const authLogin = (payload: any) => ({
  type: AUTH_LOGIN,
  payload,
})
export const authFailed = (payload: string) => ({
  type: AUTH_FAILED,
  payload,
})

// GET DETAIL USER BY TOKEN
export const authDetail = () => ({
  type: AUTH_DETAIL,
})
export const authDetailSuccess = (payload: any) => ({
  type: AUTH_DETAIL_SUCCESS,
  payload,
})

// LOGOUT
export const authLogout = () => ({ type: AUTH_LOGOUT })

// UPDATE USERS

export const authUpdate = (payload: any) => ({
  type: AUTH_UPDATE,
  payload,
})

export default reducer