import createReducer from 'utils/createReducer'
import { interfacePagination, interfaceSummary } from 'interface'

export const CASH_FLOW_CREATE = 'CASH_FLOW_CREATE'
export const CASH_FLOW_EDIT = 'CASH_FLOW_EDIT'
export const CASH_FLOW_REMOVE = 'CASH_FLOW_REMOVE'
export const CASH_FLOW_DETAILS = 'CASH_FLOW_DETAILS'
export const CASH_FLOW_LIST = 'CASH_FLOW_LIST'

export const CASH_FLOW_STATE = 'CASH_FLOW_STATE'

export interface interfaceDetail {
  name?: string,
  business_id?: string,
  user_id?: string,
  type?: string,
  source?: string,
  id_source?: string,
  id?: string,
  image?: string,
  qty?: number,
  price?: number,
  total_price?: number,
}

export interface interfaceAssets {
  details: interfaceDetail,
  data: interfaceDetail[],
  pagination: interfacePagination,
  summary : interfaceSummary,
  isLoading: boolean,
  isError: boolean,
  isModalConfirm: boolean,
  errorMessage: string,
}

export const INITIAL_STATE: interfaceAssets = {
  details: {},
  data: [],
  summary : {},
  pagination: {
    total: 0,
    page: 1,
    limit: 20,
  },
  isLoading: false,
  isError: false,
  isModalConfirm: false,
  errorMessage: '',
}

const reducer = createReducer(INITIAL_STATE, {

  [CASH_FLOW_CREATE]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [CASH_FLOW_EDIT]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [CASH_FLOW_REMOVE]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [CASH_FLOW_DETAILS]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [CASH_FLOW_LIST]: (state: any, payload: any) => ({
    ...state,
    isLoading: true,
    pagination: {
      ...state.pagination,
      page: payload?.page ? payload.page : state.pagination.page,
      limit: payload?.limit ? payload.limit : state.pagination.limit,
    }
  }),

  [CASH_FLOW_STATE]: (state: any, payload: any) => ({
    ...state,
    ...payload,
  })
})

export const cashFlowCreate = (payload: any) => ({
  type: CASH_FLOW_CREATE,
  payload,
})
export const cashFlowEdit = (payload: any) => ({
  type: CASH_FLOW_EDIT,
  payload,
})
export const cashFlowRemove = (payload: any) => ({
  type: CASH_FLOW_REMOVE,
  payload,
})
export const cashFlowDetails = (payload: any) => ({
  type: CASH_FLOW_DETAILS,
  payload,
})
export const cashFlowList = (payload: any | undefined = null) => ({
  type: CASH_FLOW_LIST,
  payload,
})
export const cashFlowState = (payload: any) => ({
  type: CASH_FLOW_STATE,
  payload,
})

export default reducer