import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  interfaceDetail,
  materialsList,
  materialsState,
} from 'reduxStore/ducks/materials'
import ListView from './ListView'

function ListContainer() {
  const dispatch = useDispatch()

  const state = useSelector((state: any) => state.Materials)

  const {
    data,
    pagination,
    summary
  } = state

  useEffect(() => {
    dispatch(materialsList())
  }, [dispatch])

  const handleDeleteConfirm = (payload: interfaceDetail) => {
    dispatch(materialsState({
      isModalConfirm: true,
    }))
    handleSelected(payload)
  }

  const handleSelected = (payload: interfaceDetail) => {
    dispatch(materialsState({
      details: payload,
    }))
  }

  const handlePagination = (payload: any) => {
    dispatch(materialsList({
      page: payload.current, 
      limit: payload.pageSize, 
    }))
  }

  const props = {
    data,
    pagination,
    summary,
    handleDeleteConfirm,
    handlePagination,
  }

  return <ListView {...props} />
}

export default ListContainer