import createReducer from 'utils/createReducer'
import { interfacePagination, interfaceSummary } from 'interface'

export const EMPLOYEES_CREATE = 'EMPLOYEES_CREATE'
export const EMPLOYEES_EDIT = 'EMPLOYEES_EDIT'
export const EMPLOYEES_REMOVE = 'EMPLOYEES_REMOVE'
export const EMPLOYEES_DETAILS = 'EMPLOYEES_DETAILS'
export const EMPLOYEES_LIST = 'EMPLOYEES_LIST'

export const EMPLOYEES_STATE = 'EMPLOYEES_STATE'

export interface interfaceDetail {
  id?: string,
  business_id?: string,
  name?: string,
  phone?: string,
  instagram?: string,
  province_id?: number,
  city_id?: number,
  subdistrict_id?: number,
  address?: string,
  balance?: number,
}

export interface interfaceAssets {
  details: interfaceDetail,
  data: interfaceDetail[],
  pagination: interfacePagination,
  summary : interfaceSummary,
  isLoading: boolean,
  isError: boolean,
  isModalConfirm: boolean,
  errorMessage: string,
}

export const INITIAL_STATE: interfaceAssets = {
  details: {},
  data: [],
  summary : {},
  pagination: {
    total: 0,
    page: 1,
    limit: 20,
  },
  isLoading: false,
  isError: false,
  isModalConfirm: false,
  errorMessage: '',
}

const reducer = createReducer(INITIAL_STATE, {

  [EMPLOYEES_CREATE]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [EMPLOYEES_EDIT]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [EMPLOYEES_REMOVE]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [EMPLOYEES_DETAILS]: (state: any) => ({
    ...state,
    isLoading: true,
  }),
  [EMPLOYEES_LIST]: (state: any, payload: any) => ({
    ...state,
    isLoading: true,
    pagination: {
      ...state.pagination,
      page: payload?.page ? payload.page : state.pagination.page,
      limit: payload?.limit ? payload.limit : state.pagination.limit,
    }
  }),

  [EMPLOYEES_STATE]: (state: any, payload: any) => ({
    ...state,
    ...payload,
  })
})

export const employeesCreate = (payload: any) => ({
  type: EMPLOYEES_CREATE,
  payload,
})
export const employeesEdit = (payload: any) => ({
  type: EMPLOYEES_EDIT,
  payload,
})
export const employeesRemove = (payload: any) => ({
  type: EMPLOYEES_REMOVE,
  payload,
})
export const employeesDetails = (payload: any) => ({
  type: EMPLOYEES_DETAILS,
  payload,
})
export const employeesList = (payload: any | undefined = null) => ({
  type: EMPLOYEES_LIST,
  payload,
})
export const employeesState = (payload: any) => ({
  type: EMPLOYEES_STATE,
  payload,
})

export default reducer