import Main from "./main"
import { useLocation } from "react-router";

function MainLayout({
  children,
}) {
  let location = useLocation()

  return <Main location={location}>{children}</Main>
}

export default MainLayout