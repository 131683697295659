import { useDispatch, useSelector } from 'react-redux'
import { materialsState, materialsRemove } from 'reduxStore/ducks/materials'

import ConfirmationView from './confirmationView'

function ConfirmationContainer() {
  const dispatch = useDispatch()
  const state = useSelector((state: any) => state.Materials)

  const {
    details,
    isLoading,
    isModalConfirm,
  } = state

  const handleCancel = () => {
    dispatch(materialsState({
      isModalConfirm: false,
    }))
  }

  const handleConfirm = () => {
    dispatch(materialsRemove(details.id))
  }

  const props = {
    details,
    isLoading,
    isModalConfirm,
    handleCancel,
    handleConfirm,
  }
  return <ConfirmationView {...props} />
}

export default ConfirmationContainer