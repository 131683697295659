import moment from "moment"
import { Badge, Button, Row, Col, Table, Card, Select, Input } from "antd"
import { Link } from "react-router-dom"
import {
  SearchOutlined,
  TagOutlined,
  EyeFilled,
} from "@ant-design/icons"

import { interfacePagination, interfaceSummary } from 'interface'

import Confirmation from "./components/confirmation"
import CardTitle from "components/reuseable/cardTitle"

const { Option } = Select;

const suffix = (
  <SearchOutlined
    style={{
      fontSize: 16,
      color: "#1890ff",
    }}
  />
);

interface interfaceListView {
  data: any[],
  pagination: interfacePagination,
  summary : interfaceSummary,
  handleDeleteConfirm: (payload: any) => void,
  handlePagination: (payload: any) => void,
}

function ListView({
  data,
  pagination,
  summary,
  handleDeleteConfirm,
  handlePagination,
}: interfaceListView) {

  const columns = [
    {
      title: "No Invoice",
      render: (data: any) => data.invoice_no
    },
    {
      title: "Pelanggan / Mitra",
      render: (data: any) => {
        if (data.order_customer) {
          return data.order_customer.name
        } else {
          return data.order_mitra.name
        }
      }
    },
    {
      title: "Tipe",
      render: (data: any) => data.order_type
    },
    {
      title: "Status Order",
      render: (data: any) => {
        if (data.status === 'draft') { return <Badge count="Draft" style={{ background: '#edc500', color: 'white', fontWeight: 600 }} /> }
        if (data.status === 'waiting_payment') { return <Badge count="Menunggu Pembayaran" style={{ background: '#ac0d3c', color: 'white', fontWeight: 600 }} /> }
        if (data.status === 'process') { return <Badge count="Di Proses" style={{ background: '#072498', color: 'white', fontWeight: 600 }} /> }
        if (data.status === 'packing') { return <Badge count="Packing" style={{ background: '#75450f', color: 'white', fontWeight: 600 }} /> }
        if (data.status === 'shipping') { return <Badge count="Pengiriman" style={{ background: '#0f7518', color: 'white', fontWeight: 600 }} /> }
        if (data.status === 'done') { return <Badge count="Selesai" style={{ background: '#1990ff', color: 'white', fontWeight: 600 }} /> }
        if (data.status === 'retur') { return <Badge count="Retur" style={{ background: 'red', color: 'white', fontWeight: 600 }} /> }
      }
    },
    {
      title: "Tanggal Order",
      ellipsis: true,
      render: (data: any) => {
        return moment(data.updatedAt).format("DD MMM YYYY - hh:mm");
      },
    },
    {
      title: "",
      width: '30px',
      render: (data: any) => {
        return (
          <>
            <Link to={`/${data.business_id}/order/edit/${data.id}`}>
              <Button type="link">
                <EyeFilled className="fz-18"/>
              </Button>
            </Link>
            {/* <Button type="link" danger onClick={() => handleDeleteConfirm(data)}>
              <DeleteFilled className="fz-18"/>
            </Button> */}
          </>
        )
      },
    },
  ];

  const count = [
    {
      title: "Jumlah Order",
      price: pagination.total,
      icon: <TagOutlined
      style={{
        fontSize: 25,
        color: "white",
      }}
    />,
    }
  ];

  return (
    <>
      <CardTitle count={count} />
      <Confirmation />
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Card
            bodyStyle={{ padding: "16px 0px 0px" }}
            bordered={false}
            className="header-solid mb-24"
            title={[<h5 className="font-semibold">Daftar Order</h5>]}
          >
            <div className="px-25">
              <Row gutter={[24, 0]} className="mb-24">
                <Col span={24} lg={12}>
                  <Select
                    value={pagination.limit}
                    // onChange={this.handleTotalPageChange1}
                    size="large"
                  >
                    <Option value="5">5</Option>
                    <Option value="10">10</Option>
                    <Option value="15">15</Option>
                    <Option value="20">20</Option>
                    <Option value="25">25</Option>
                  </Select>

                  <label className="ml-10">entries per page</label>
                </Col>
                <Col span={24} lg={12} className="text-right">
                  <Input
                    style={{ maxWidth: "200px", borderRadius: "8px" }}
                    placeholder="input search text"
                    size="small"
                    suffix={suffix}
                    // onChange={this.handleFilter} 
                  />
                </Col>
              </Row>

              <Table
                className="mt-20"
                columns={columns}
                dataSource={data}
                tableLayout="auto"
                onChange={(e) => handlePagination(e)}
                scroll={{ x: 500 }}
                pagination={{
                  total: pagination.total,
                  pageSize: pagination.limit,
                  current: pagination.page,
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ListView