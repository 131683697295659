import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'

import {
  EMPLOYEES_GET,
  EMPLOYEES_DELETE,
  EMPLOYEES_POST,
  EMPLOYEES_PUT,
} from 'config/endpoints'

import {
  EMPLOYEES_CREATE,
  EMPLOYEES_EDIT,
  EMPLOYEES_REMOVE,
  EMPLOYEES_DETAILS,
  EMPLOYEES_LIST,
  employeesState,
  employeesList,
} from 'reduxStore/ducks/employees/index'

import {
  notificationSet,
} from 'reduxStore/ducks/notifications'

import {
  redirect,
} from 'reduxStore/ducks/redirect'

export function employeesListEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(EMPLOYEES_LIST),
    mergeMap(({ payload }) =>
      api({
        endpoint: EMPLOYEES_GET,
        query: {
          ...state$.value.Employees.pagination,
          ...payload,
        }
      }).pipe(
        mergeMap((response: any) => of(
          employeesState({
            isLoading: false,
            data: response.data.data,
            pagination: response.data.pagination,
            summary : response.data.summary
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function employeesDetailsEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(EMPLOYEES_DETAILS),
    mergeMap(({ payload }) =>
      api({
        endpoint: EMPLOYEES_GET,
        params: [payload]
      }).pipe(
        mergeMap((response: any) => of(
          employeesState({
            isLoading: false,
            details: response.data,
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function employeesEditsEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(EMPLOYEES_EDIT),
    mergeMap(({ payload }) =>
      api({
        endpoint: EMPLOYEES_PUT,
        params: [state$.value.Employees.details.user_id],
        body: payload,
      }).pipe(
        mergeMap((response: any) => of(
          employeesState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Berhasil',
            message: `Karyawan ${payload.name} berhasil diperbaharui`,
            type: 'success',
          }),
          redirect('/employees')
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function employeesCreateEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(EMPLOYEES_CREATE),
    mergeMap(({ payload }) =>
      api({
        endpoint: EMPLOYEES_POST,
        body: payload,
      }).pipe(
        mergeMap(() => of(
          employeesState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Berhasil',
            message: 'Karyawan baru berhasil ditambahkan',
            type: 'success',
          }),
          redirect('/employees')
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
          employeesState({
            isLoading: false,
          })
        )),
      )
    ),
  )
}

export function employeesDeleteEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(EMPLOYEES_REMOVE),
    mergeMap(({ payload }) =>
      {
      return api({
        endpoint: EMPLOYEES_DELETE,
        params: [payload],
      }).pipe(
        mergeMap(() => of(
          employeesList(),
          employeesState({
            isLoading: false,
            isModalConfirm: false,
            details: {},
          }),
          notificationSet({
            title: 'Berhasil',
            message: 'Karyawan berhasil dihapus',
            type: 'success',
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        ))
      )
      }
    )
  )
}