import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'

import {
  UNIT_GET,
  UNIT_DELETE,
  UNIT_POST,
  UNIT_PUT,
} from 'config/endpoints'

import {
  UNIT_CREATE,
  UNIT_EDIT,
  UNIT_REMOVE,
  UNIT_DETAILS,
  UNIT_LIST,
  unitState,
  unitList,
} from 'reduxStore/ducks/masterData/unit/index'

import {
  notificationSet,
} from 'reduxStore/ducks/notifications'

import {
  redirect,
} from 'reduxStore/ducks/redirect'

export function unitListEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(UNIT_LIST),
    mergeMap(() =>
      api({
        endpoint: UNIT_GET,
        query: {
          ...state$.value.MasterDataUnit.pagination
        }
      }).pipe(
        mergeMap((response: any) => of(
          unitState({
            isLoading: false,
            data: response.data.data,
            pagination: response.data.pagination,
            summary : response.data.summary
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function unitDetailsEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(UNIT_DETAILS),
    mergeMap(({ payload }) =>
      api({
        endpoint: UNIT_GET,
        params: [payload]
      }).pipe(
        mergeMap((response: any) => of(
          unitState({
            isLoading: false,
            details: response.data,
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function unitEditsEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(UNIT_EDIT),
    mergeMap(({ payload }) =>
      api({
        endpoint: UNIT_PUT,
        params: [state$.value.MasterDataUnit.details.id],
        body: payload,
      }).pipe(
        mergeMap((response: any) => of(
          unitState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Berhasil',
            message: `Unit ${payload.unit} berhasil diperbaharui`,
            type: 'success',
          }),
          redirect('/units')
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function unitCreateEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(UNIT_CREATE),
    mergeMap(({ payload }) =>
      api({
        endpoint: UNIT_POST,
        body: payload,
      }).pipe(
        mergeMap(() => of(
          unitState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Berhasil',
            message: `Unit ${payload.unit} berhasil ditambahkan`,
            type: 'success',
          }),
          redirect('/units')
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function unitDeleteEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(UNIT_REMOVE),
    mergeMap(({ payload }) =>
      {
      return api({
        endpoint: UNIT_DELETE,
        params: [payload],
      }).pipe(
        mergeMap(() => of(
          unitList(),
          unitState({
            isLoading: false,
            isModalConfirm: false,
            details: {},
          }),
          notificationSet({
            title: 'Berhasil',
            message: 'Unit berhasil dihapus',
            type: 'success',
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        ))
      )
      }
    )
  )
}