
import { useDispatch } from "react-redux"
import { authLogin } from "reduxStore/ducks/auth" 
import LoginView from "./LoginView"

function LoginContainer() {
  const dispatch = useDispatch()

  const handleLogin = (values: any) => {
    dispatch(authLogin(values))
  }

  const props = {
    handleLogin
  }

  return <LoginView {...props} />
}

export default LoginContainer
