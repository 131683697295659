import {
  AppstoreFilled,
  DashboardFilled,
  UserOutlined,
  FieldTimeOutlined,
  LineChartOutlined,
  TagOutlined,
  UsergroupAddOutlined,
  ExperimentOutlined,
  DiffOutlined,
} from "@ant-design/icons"
import { getMe } from "./cookies";

const account = getMe()

const menuItems = {
  items: [
    {
      id: "null0",
      title: "",
      className: "d-none",
      type: "group",
      isShow: true,
      children: [
        {
          id: "das",
          title: <span className="label">Dashboard</span>,
          type: "item",
          icon: (isSelected: boolean, color: string) => (
            <DashboardFilled
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          isShow: true,
          url: "/",
        },
      ],
    },
    /////========================================================================================================== GROWTH
    {
      id: "growth",
      title: "GROWTH",
      type: "group",
      isShow: account.isOwner || account?.access?.finance || account?.access?.customers ||  account?.access?.product || account?.access?.production || account?.access?.order,
      children: [
        /////================================================================== CASHFLOW
        {
          id: "cashflow",
          title: <span className="label">Keuangan</span>,
          type: "collapse",
          icon: (isSelected: boolean, color: string) => (
            <LineChartOutlined
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          isShow: account.isOwner || account?.access?.finance,
          children: [
            {
              id: "cashflows",
              title: "Cashflow",
              type: "item",
              url: '/cashflows',
            },
            {
              id: "income",
              title: "Pemasukan",
              type: "collapse",
              isShow: true,
              children: [
                {
                  id: "income-list",
                  title: "Daftar Pemasukan",
                  type: "item",
                  url: '/cashflow/income/list',
                },
                {
                  id: "income-new",
                  title: "Tambah Pemasukan",
                  type: "item",
                  url: '/cashflow/income/new',
                },
              ],
            },
            {
              id: "expend",
              title: "Pengeluaran",
              type: "collapse",
              isShow: true,
              children: [
                {
                  id: "expend-list",
                  title: "Daftar Pengeluaran",
                  type: "item",
                  url: '/cashflow/expend/list',
                },
                {
                  id: "expend-new",
                  title: "Tambah Pengeluaran",
                  type: "item",
                  url: '/cashflow/expend/new',
                },
              ],
            },
          ]
        },
        /////================================================================== CUSTOMER
        {
          id: "customer",
          title: <span className="label">Pelanggan</span>,
          type: "collapse",
          icon: (isSelected: boolean, color: string) => (
            <UsergroupAddOutlined
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          isShow: account.isOwner || account?.access?.customers,
          children: [
            {
              id: "customers-list",
              title: "Daftar Pelanggan",
              type: "item",
              url: '/customers',
            },
            {
              id: "customers-new",
              title: "Tambah Pelanggan",
              type: "item",
              url: '/customer/new',
            },
          ]
        },
        /////================================================================== PRODUK
        {
          id: "products",
          title: <span className="label">Produk</span>,
          type: "collapse",
          icon: (isSelected: boolean, color: string) => (
            <TagOutlined
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          isShow: account.isOwner || account?.access?.product,
          children: [
            {
              id: "products-list",
              title: "Daftar Produk",
              type: "item",
              url: '/products',
            },
            {
              id: "products-new",
              title: "Tambah Produk",
              type: "item",
              url: '/product/new',
            },
          ]
        },
        /////================================================================== PRODUCTION
        {
          id: "productions",
          title: <span className="label">Produksi</span>,
          type: "collapse",
          icon: (isSelected: boolean, color: string) => (
            <ExperimentOutlined
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          isShow: account.isOwner || account?.access?.production,
          children: [
            {
              id: "productions-list",
              title: "Daftar Produksi",
              type: "item",
              url: '/productions',
            },
            {
              id: "productions-new",
              title: "Produksi Produk",
              type: "item",
              url: '/production/new',
            },
          ]
        },
        /////================================================================== ORDER
        {
          id: "orders",
          title: <span className="label">Order</span>,
          type: "collapse",
          icon: (isSelected: boolean, color: string) => (
            <DiffOutlined
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          isShow: account.isOwner || account?.access?.order,
          children: [
            {
              id: "orders-list",
              title: "Daftar Order",
              type: "item",
              url: '/orders',
            },
            {
              id: "order-new",
              title: "Buat Order",
              type: "item",
              url: '/order/new',
            },
          ]
        },
      ],
    },
    /////========================================================================================================== INVENTORY
    {
      id: "resource",
      title: "RESOURCE",
      type: "group",
      isShow: account.isOwner || account?.access?.assets || account?.access?.materials || account?.access?.employees,
      children: [
        /////================================================================== ASSETS
        {
          id: "assets",
          title: <span className="label">Assets</span>,
          type: "collapse",
          icon: (isSelected: boolean, color: string) => (
            <AppstoreFilled
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          isShow: account.isOwner || account?.access?.assets,
          children: [
            {
              id: "assets-list",
              title: "Daftar Asset",
              type: "item",
              url: '/assets',
            },
            {
              id: "assets-new",
              title: "Asset Baru",
              type: "item",
              url: '/asset/new',
            },
          ]
        },
        /////================================================================== MATERIIAL
        {
          id: "materials",
          title: <span className="label">Bahan Baku</span>,
          type: "collapse",
          icon: (isSelected: boolean, color: string) => (
            <AppstoreFilled
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          isShow: account.isOwner || account?.access?.materials,
          children: [
            {
              id: "materials-list",
              title: "Daftar Bahan Baku",
              type: "item",
              url: '/materials',
            },
            {
              id: "materials-new",
              title: "Bahan Baku Baru",
              type: "item",
              url: '/material/new',
            },
          ]
        },
        /////================================================================== KARYAWAN
        {
          id: "employee",
          title: <span className="label">Karyawan</span>,
          type: "collapse",
          icon: (isSelected: boolean, color: string) => (
            <UserOutlined
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          isShow: account.isOwner || account?.access?.employees,
          children: [
            {
              id: "employees-list",
              title: "Daftar Karyawan",
              type: "item",
              url: '/employees',
            },
            {
              id: "employee-new",
              title: "Tambah Karyawan",
              type: "item",
              url: '/employee/new',
            },
          ]
        },
      ],
    },
    /////========================================================================================================== AKTIVITAS
    {
      id: "activity",
      title: "ACTIVITY",
      type: "group",
      isShow: true,
      children: [
        /////================================================================== AKTIVITAS LOG
        {
          id: "activity-log",
          title: <span className="label">Aktivitas Log</span>,
          type: "collapse",
          icon: (isSelected: boolean, color: string) => (
            <AppstoreFilled
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          isShow: true,
          children: [
            {
              id: "activity-list",
              title: "Daftar Aktivitas",
              type: "item",
              url: '/activities',
            },
            // {
            //   id: "activity-new",
            //   title: "Aktivitas Baru",
            //   type: "item",
            //   url: '/activity/new',
            // },
          ]
        },
        /////================================================================== KEHADIRAN
        // {
        //   id: "attendance",
        //   title: <span className="label">Kehadiran</span>,
        //   type: "item",
        //   icon: (isSelected: boolean, color: string) => (
        //     <FieldTimeOutlined
        //       className="icon"
        //       style={{
        //         background: isSelected ? color : "",
        //       }}
        //     />
        //   ),
        //   isShow: true,
        //   url: '/attendance',
        // },
      ],
    },
    /////========================================================================================================== MASTER DATA
    {
      id: "master-data",
      title: "MASTER DATA",
      isShow: true,
      type: "group",
      children: [
        /////================================================================== CATEGORY
        {
          id: "master-data-category",
          title: <span className="label">Kategori</span>,
          type: "collapse",
          isShow: true,
          icon: (isSelected: boolean, color: string) => (
            <AppstoreFilled
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          children: [
            {
              id: "master-data-category-list",
              title: "Daftar Kategori",
              type: "item",
              url: '/categories',
            },
            {
              id: "master-data-category-new",
              title: "Kategori Baru",
              type: "item",
              url: '/category/new',
            },
          ]
        },
        /////================================================================== SUB CATEGORY
        {
          id: "master-data-sub-category",
          title: <span className="label">Sub Kategori</span>,
          type: "collapse",
          isShow: true,
          icon: (isSelected: boolean, color: string) => (
            <AppstoreFilled
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          children: [
            {
              id: "master-data-sub-category-list",
              title: "Daftar Sub Kategori",
              type: "item",
              url: '/sub-categories',
            },
            {
              id: "master-data-sub-category-new",
              title: "Sub Kategori Baru",
              type: "item",
              url: '/sub-category/new',
            },
          ]
        },
        /////================================================================== UNIT
        {
          id: "master-data-unit",
          title: <span className="label">Unit</span>,
          type: "collapse",
          isShow: true,
          icon: (isSelected: boolean, color: string) => (
            <AppstoreFilled
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          children: [
            {
              id: "master-data-unit-list",
              title: "Daftar Unit",
              type: "item",
              url: '/units',
            },
            {
              id: "master-data-unit-new",
              title: "Unit Baru",
              type: "item",
              url: '/unit/new',
            },
          ]
        },
      ],
    },
    /////========================================================================================================== AKUN
    {
      id: "account",
      title: "AKUN",
      type: "group",
      isShow: true,
      children: [
        /////================================================================== EDIT AKUN
        {
          id: "account-edit",
          title: <span className="label">Edit Akun</span>,
          type: "item",
          icon: (isSelected: boolean, color: string) => (
            <UserOutlined
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          isShow: true,
          url: '/account',
        },
      ],
    },
  ],
};

export default menuItems;
