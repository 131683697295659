import { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { Typography } from "antd";
const { Title, Paragraph } = Typography;
export default class Echart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Sales",
          data: [850, 600, 500, 620, 900, 500, 900, 630, 900],
          color: "#fff",
        },
      ],

      options: {
        chart: {
          type: "bar",
          width: "100%",
          height: "auto",

          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "40%",
            borderRadius: 6,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },

        xaxis: {
          categories: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
          labels: {
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 160,

            style: {
              fontSize: "14px",
              fontWeight: 600,
              colors: [
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
              ],
            },
          },
        },
        yaxis: {
          labels: {
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              fontSize: "14px",
              fontWeight: 600,
              colors: [
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
              ],
            },
          },
        },

        grid: {
          show: true,
          borderColor: "rgba(255, 255, 255, .2)",

          strokeDashArray: 6,
          position: "back",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          row: {
            colors: undefined,
            opacity: 0.5,
          },
          column: {
            colors: undefined,
            opacity: 0.5,
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },

        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val + " thousands";
            },
          },
        },
      },
    };
  }
  render() {
    return (
      <>
        <div id="chart">
          <ReactApexChart
            className="bar-chart"
            options={this.state.options}
            series={this.state.series}
            type="bar"
            height={220}
          />
        </div>
        <div className="chart-vistior">
          <Title level={5}>Active Users</Title>
          <Paragraph className="lastweek">
            than last week <span className="bnb2">+23%</span>
          </Paragraph>
          {/* <p>
            We have created multiple options for you to put together and
            customise into pixel perfect pages.
          </p>
          <Row gutter>
            {visitor.map((v, index) => (
              <Col xs={6} xl={6} sm={6} md={6} key={index}>
                <div className="chart-visitor-count">
                  <div className="ant-icon-user ">
                    {v.icon}

                    <p>{v.user}</p>
                  </div>
                  <h4 className="h4">{v.Title}</h4>
                  {v.progress}
                </div>
              </Col>
            ))}
          </Row> */}
        </div>
      </>
    );
  }
}
