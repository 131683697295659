// CORE
import React from 'react'

// COMPONENTS
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
} from 'antd'

import { interfaceMasterData } from 'reduxStore/ducks/masterData'

interface interfaceFormView {
  handleSubmit: (payload: any) => void,
  payload: any,
  isDisabledForm: boolean,
  type: string | undefined,
  isLoading?: boolean,
  masterData: interfaceMasterData,
  handlePayload: (payload: any) => void,
}

function FormView({
  handleSubmit,
  payload,
  isDisabledForm,
  type,
  isLoading,
  masterData,
  handlePayload,
}: interfaceFormView) {

  return (
    <>
      <div>
        <Card
          className="header-solid"
          bordered={false}
          title={[
            <React.Fragment key={0}>
              <h3 className="mb-0">
                {type === 'new' ? 'Tambah Pelanggan' : payload.name}
              </h3>
            </React.Fragment>,
          ]}
        >
          <div className="mx-auto mt-24 mb-24" style={{ maxWidth: "800px" }}>
            <Form
              name="basic"
              layout="vertical"
              onFinish={handleSubmit}
              onFinishFailed={() => {}}
              fields={[
                {
                  name: ['name'],
                  value: payload.name
                },
                {
                  name: ['email'],
                  value: payload.email
                },
                {
                  name: ['phone'],
                  value: payload.phone
                },
                {
                  name: ['instagram'],
                  value: payload.instagram
                },
                {
                  name: ['province_id'],
                  value: payload.province_id
                },
                {
                  name: ['city_id'],
                  value: payload.city_id
                },
                {
                  name: ['subdistrict_id'],
                  value: payload.subdistrict_id
                },
                {
                  name: ['address'],
                  value: payload.address
                },
              ]}
            >
              <Row gutter={32}>
                <Col span={12}>
                  <Form.Item
                    label="Nama"
                    name="name"
                    rules={[
                      { required: true, message: "Nama pelanggan harus diisi!" },
                    ]}
                    colon={false}
                  >
                    <Input
                      placeholder="Nama pelanggan"
                      disabled={isDisabledForm}
                      onChange={(e) => handlePayload({ name: e.target.value })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Nomor HP"
                    colon={false}
                    name="phone"
                    rules={[
                      { required: true, message: "Nomor HP pelanggan harus diisi!" },
                    ]}
                  >
                    <Input
                      placeholder="Nomor HP pelanggan"
                      type="number"
                      onChange={(e) => handlePayload({ phone: e.target.value })}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Email"
                    colon={false}
                    name="email"
                    rules={[
                      { required: false, message: "Email pelanggan harus diisi!" },
                    ]}
                  >
                    <Input
                      placeholder="Email pelanggan"
                      type="email"
                      onChange={(e) => handlePayload({ email: e.target.value })}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Instagram"
                    colon={false}
                    name="instagram"
                    rules={[
                      { required: false, message: "Instagram pelanggan harus diisi!" },
                    ]}
                  >
                    <Input
                      placeholder="Instagram pelanggan"
                      type="text"
                      onChange={(e) => handlePayload({ instagram: e.target.value })}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Provinsi"
                    name="province_id"
                    rules={[
                      { required: true, message: "Pilih provinsi!" },
                    ]}
                    colon={false}
                  >
                    <Select
                      showSearch
                      placeholder="Cari Kategori"
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={masterData.provinces.map(data => {
                        return { label: data.province_name, value: data.province_id }
                      })}
                      onChange={(e) => handlePayload({ province_id: e })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Kota"
                    name="city_id"
                    rules={[
                      { required: true, message: "Pilih kota!" },
                    ]}
                    colon={false}
                  >
                    <Select
                      showSearch
                      placeholder="Cari Kota"
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={masterData.cities.map(data => {
                        return { label: data.city_name, value: data.city_id }
                      })}
                      onChange={(e) => handlePayload({ city_id: e })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Kelurahan"
                    name="subdistrict_id"
                    colon={false}
                  >
                    <Select
                      showSearch
                      placeholder="Cari Kelurahan"
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={masterData.subDistricts.map(data => {
                        return { label: data.subdistrict_name, value: data.subdistrict_id }
                      })}
                      onChange={(e) => handlePayload({ subdistrict_id: e })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Alamat"
                    colon={false}
                    name="address"
                    rules={[
                      { required: true, message: "Alamat pelanggan harus diisi!" },
                    ]}
                  >
                    <Input
                      placeholder="Alamat pelanggan"
                      type="text"
                      onChange={(e) => handlePayload({ address: e.target.value })}
                      disabled={isDisabledForm}
                    />
                  </Form.Item>
                </Col>
                

                {!isDisabledForm && (
                  <Col span={24} className="text-right">
                    <Form.Item>
                      <Button
                        type="primary"
                        className="px-25"
                        htmlType="submit"
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        SIMPAN
                      </Button>
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Form>
          </div>
        </Card>
      </div>
    </>
  )
}
export default FormView