import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'

import {
  PRODUCTS_GET,
  PRODUCTS_DELETE,
  PRODUCTS_POST,
  PRODUCTS_PUT,
} from 'config/endpoints'

import {
  PRODUCTS_CREATE,
  PRODUCTS_EDIT,
  PRODUCTS_REMOVE,
  PRODUCTS_DETAILS,
  PRODUCTS_LIST,
  productsState,
  productsList,
} from 'reduxStore/ducks/products/index'

import {
  notificationSet,
} from 'reduxStore/ducks/notifications'

import {
  redirect,
} from 'reduxStore/ducks/redirect'

export function productsListEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(PRODUCTS_LIST),
    mergeMap(({ payload }) =>
      api({
        endpoint: PRODUCTS_GET,
        query: {
          ...state$.value.Products.pagination,
          ...payload,
        }
      }).pipe(
        mergeMap((response: any) => of(
          productsState({
            isLoading: false,
            data: response.data.data,
            pagination: response.data.pagination,
            summary : response.data.summary
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function productsDetailsEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(PRODUCTS_DETAILS),
    mergeMap(({ payload }) =>
      api({
        endpoint: PRODUCTS_GET,
        params: [payload]
      }).pipe(
        mergeMap((response: any) => of(
          productsState({
            isLoading: false,
            details: response.data,
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function productsEditsEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(PRODUCTS_EDIT),
    mergeMap(({ payload }) =>
      api({
        endpoint: PRODUCTS_PUT,
        params: [state$.value.Products.details.productStock.id],
        body: payload,
      }).pipe(
        mergeMap((response: any) => of(
          productsState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Berhasil',
            message: `Produk ${payload.product.name} berhasil diperbaharui`,
            type: 'success',
          }),
          redirect('/products')
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function productsCreateEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(PRODUCTS_CREATE),
    mergeMap(({ payload }) =>
      api({
        endpoint: PRODUCTS_POST,
        body: payload,
      }).pipe(
        mergeMap(() => of(
          productsState({
            isLoading: false,
          }),
          notificationSet({
            title: 'Berhasil',
            message: `Produk ${payload.product.name} berhasil ditambahkan`,
            type: 'success',
          }),
          redirect('/products')
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        )),
      )
    ),
  )
}

export function productsDeleteEpic(action$: any, state$: any, { api }: any) {
  return action$.pipe(
    ofType(PRODUCTS_REMOVE),
    mergeMap(({ payload }) =>
      {
      return api({
        endpoint: PRODUCTS_DELETE,
        params: [payload],
      }).pipe(
        mergeMap(() => of(
          productsList(),
          productsState({
            isLoading: false,
            isModalConfirm: false,
            details: {},
          }),
          notificationSet({
            title: 'Berhasil',
            message: 'Produk berhasil dihapus',
            type: 'success',
          })
        )),
        catchError((err) => of(
          notificationSet({
            title: 'Error',
            message: err.message,
            type: 'error',
          }),
        ))
      )
      }
    )
  )
}