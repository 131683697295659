import { ajax } from 'rxjs/ajax'
import { map, catchError } from 'rxjs/operators'
import { throwError } from 'rxjs'

import { getToken, getBranchId } from 'utils/cookies'

interface interfaceQuery {
  offset?: number,
  limit?: number,
}

interface interfaceApi {
  endpoint: string[],
  query?: interfaceQuery,
  params?: string[],
  body?: any,
}

function generateUrl(urlString: string, params: string[] = [], query?: interfaceQuery | any, host?: string) {
  let endpoint = urlString
  if (params.length > 0) {
    params.map((param: string) => endpoint = `${endpoint}/${param}`)
  }
  if (query) {
    const queryParam = new URLSearchParams()
    Object.keys(query).forEach((key: any) => {
      query[key] && queryParam.append(key, query[key])
    })
    endpoint = `${endpoint}?${queryParam.toString()}`
  }

  return `${host}/${endpoint}`
}

export default function Api(options: interfaceApi) {
  const {
    endpoint = ['get', ''],
    query,
    params,
    body,
  }: interfaceApi = options
  const [method, path] = endpoint
  const apiHost = process.env.REACT_APP_API_HOST
  const url = generateUrl(path, params, query, apiHost)

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': getToken(),
    'Id-App': getBranchId()
  }

  return ajax({
    method,
    url,
    headers,
    body,
  }).pipe(
    map(res => res.response || {}),
    catchError((error) =>  {
      console.log('error', error)
      if (path !== 'login' && error.status === 401) {
        return window.location.href = `/${getBranchId()}/login`
      }
      return throwError(error.response)
    }),
  )
}