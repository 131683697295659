import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  interfaceDetail,
  cashFlowList,
  cashFlowState,
} from 'reduxStore/ducks/cashFlow'
import ListView from './ListView'

function ListContainer() {
  const dispatch = useDispatch()
  const { cashflow } = useParams()

  const state = useSelector((state: any) => state.CashFlow)
  console.log('state', state)

  const {
    data,
    pagination,
    summary
  } = state

  useEffect(() => {
    dispatch(cashFlowList({ type: cashflow }))
  }, [dispatch, cashflow])

  const handleDeleteConfirm = (payload: interfaceDetail) => {
    dispatch(cashFlowState({
      isModalConfirm: true,
    }))
    handleSelected(payload)
  }

  const handleSelected = (payload: interfaceDetail) => {
    dispatch(cashFlowState({
      details: payload,
    }))
  }

  const handlePagination = (payload: any) => {
    dispatch(cashFlowList({
      page: payload.current, 
      limit: payload.pageSize, 
    }))
  }

  const props = {
    data,
    pagination,
    summary,
    handleDeleteConfirm,
    handlePagination,
  }

  return <ListView {...props} />
}

export default ListContainer